import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {reviewActions} from "../../../_actions/review.actions"
import {ERROR} from "../../../_helper";
import Spinner from 'react-bootstrap/Spinner';
import Pagination from "react-js-pagination";
import {Dropdown} from "react-bootstrap";
import {deleteReviews, getAllREviews} from "../../../_actions/review.actions";
import * as shamsi from 'shamsi-date-converter';
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import {useTranslation} from "react-i18next";

const ReviewTable = ({review, loading, error}) => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [reviewTotal, setReviewTotal] = useState(1);
    const [reviewData, setReviewData] = useState([]);

    const [type, setTypes] = useState("");
    const [sort, setSort] = useState("");


    const dispatch = useDispatch();


    const {t} = useTranslation();

    const handlePageChange = async (pageNumber) => {
        setPage(pageNumber);
        const page = pageNumber;
        dispatch(
            reviewActions.getReviewPage(page)
        )
            .then((r) => {
                if (r.status === 200) {
                    window.scroll(0, 0)
                    setReviewData(r)
                    setReviewTotal(r.data?.meta?.total);
                }
            })
            .catch((ex) =>
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                })
            );
    }


    let isValidREVIEW = ValidPermissions(PermissionConst.REVIEW_LIST)


    useEffect(() => {
        if (isValidREVIEW) {
            dispatch(
                reviewActions.getReviewPage(page)
            )
                .then((r) => {
                    if (r.status === 200) {
                        setReviewData(r)
                        setReviewTotal(r.data?.meta?.total);
                    }
                })
                .catch((ex) =>
                    ERROR(ex.response?.data?.message || ex.message, {
                        error: ex,
                    })
                );
        }

    }, [page]);

    const handleDelete = (id) => {
        dispatch(deleteReviews(id))
        dispatch(getAllREviews())
    }

    let dir = localStorage.getItem("Layout");
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <div className={`d-flex justify-content-between align-items-center w-100`}>
                        {!loading && <h4 className="card-title">{t('REVIEW_KEY')}</h4>}
                        {loading && <div className={"text-center"}><Spinner animation="grow" variant="success"/>
                            <Spinner animation="grow" variant="danger"/>
                            <Spinner animation="grow" variant="warning"/>
                            <Spinner animation="grow" variant="info"/>
                            <Spinner animation="grow" variant="light"/>
                            <Spinner animation="grow" variant="dark"/></div>}

                    </div>
                </div>
                <div className="card-body">
                    <div className="w-100 table-responsive">
                        <div id="patientTable_basic_table" className="dataTables_wrapper">
                            <table
                                id="example5"
                                className=" dataTable no-footer w-100  table-striped  table-hover "
                                style={{minWidth: 845}}
                                role="grid"
                                aria-describedby="example5_info"
                            >
                                <thead>
                                <tr role="row">
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient ID: activate to sort column ascending"
                                    >
                                        {t('Title_KEY')}

                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient ID: activate to sort column ascending"
                                    >
                                        {t('STATUS_KEY')}

                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient ID: activate to sort column ascending"
                                    >
                                        {t('DATE_CREATE_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Doctor Assgined: activate to sort column ascending"
                                    >
                                        {t('EMAIL_KEY')}
                                    </th>

                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Disease: activate to sort column ascending"
                                    >
                                        {t('PHONE_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example7"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Disease: activate to sort column ascending"
                                    >
                                        {t('ACTIONS_KEY')}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {review?.data?.map((review) => (
                                    <>
                                        <tr key={review.id} role="row" className="odd">
                                            <td className={'text-center'}>{review?.title}</td>
                                            <td className={'text-center'}>
                                                {review?.status === "0" ? (
                                                    <>
                                               <span className="badge light badge-danger ">
                                                <i className="fa fa-circle text-danger mr-1"/>
                                                            <span className={'mr-2'}>
                                                           {t('UNPUBLISH_KEY')}
                                                            </span>
                                                </span>
                                                    </>
                                                ) : review?.status === "1" ? (
                                                    <>
                                                        <span className="badge light badge-success ">
                                                          <i className="fa fa-circle text-success mr-1"/>
                                                            <span className={'mr-2'}>
                                                                {t('PUBLISH_KEY')}
                                                            </span>
                                                           </span>
                                                    </>
                                                ) : null}
                                            </td>
                                            <td className={'text-center'}>{shamsi.gregorianToJalali(review?.created_at).join("/")}</td>
                                            <td className={'text-center'}>{review?.user?.email}</td>
                                            <td className={'text-center'}>{review?.user?.mobile}</td>
                                            <td className={'text-center'}>
                                                <Dropdown className="dropdown ml-auto ">
                                                    <Dropdown.Toggle
                                                        variant=""
                                                        className="btn-link i-false"
                                                        data-toggle="dropdown"
                                                    >
                                                        <svg
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                strokeWidth={1}
                                                                fill="none"
                                                                fillRule="evenodd"
                                                            >
                                                                <rect x={0} y={0} width={24} height={24}/>
                                                                <circle fill="#000000" cx={5} cy={12} r={2}/>
                                                                <circle fill="#000000" cx={12} cy={12} r={2}/>
                                                                <circle fill="#000000" cx={19} cy={12} r={2}/>
                                                            </g>
                                                        </svg>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                        <Dropdown.Item
                                                            className={'d-flex align-items-center justify-content-center'}
                                                            href={`review/${review.id}`}
                                                        >
                                                            <i className={dir === "rtl" ? "fa fa-info text-secondary fa-2x ml-3" : "fa fa-info text-secondary fa-2x mr-3"}/>
                                                            {t('DETAILS_KEY')}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            href={`review-edit/${review.id}`}
                                                            className={'d-flex align-items-center justify-content-center'}
                                                        >
                                                            <i className={dir === "rtl" ? "fa fa-edit text-warning fa-2x ml-4" : "fa fa-edit text-warning fa-2x mr-4"}/>
                                                            {t('EDIT_KEY')}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => handleDelete(review.id)}
                                                            className={'d-flex align-items-center justify-content-center'}
                                                        >
                                                            <i className={dir === "rtl" ? "fa fa-trash text-danger fa-2x ml-3" : "fa fa-trash text-danger fa-2x mr-3"}/>
                                                            {t('DELETE_KEY')}
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    </>
                                ))}
                                </tbody>
                            </table>
                            <div
                                className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                                <div className="dataTables_info">
                                    {/*   */}
                                </div>
                                <div
                                    className="dataTables_paginate paging_simple_numbers"
                                    id="example5_paginate"
                                >
                                    <div className="d-inline-block pb-3 ltr_direction">
                                        <Pagination
                                            activePage={page}
                                            itemsCountPerPage={review?.meta?.per_page}
                                            totalItemsCount={reviewTotal}
                                            pageRangeDisplayed={10}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ReviewTable;
