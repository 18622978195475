import axios from "axios";
import i18n from "i18next";

const baseURL = process.env.REACT_APP_BASE_URL;

let initialToken = localStorage.getItem("token");

export const _http = (
    url,
    { params, data, method, headers = {}, token, body, ...res }
) => {
  return axios({
    baseURL,
    url,
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      'Accept-Language': i18n?.language,
      ...headers,
    },
    params,
    data,
    body,
    method,
    ...res,
  })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        // if (e?.response?.status === 401 && typeof window !== "undefined") {
        //   localStorage.removeItem("token");
        //   return (window.location.href = "/page-login");
        // }
        // if (e?.response?.status === 403 && typeof window !== "undefined") {
        //   localStorage.removeItem("token");
        //   return (window.location.href = "/page-login");
        // }
        return e;
      });
};
