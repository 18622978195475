import React, {useState, useEffect} from 'react';
import PageTitle from "../../layouts/PageTitle";
import {DefaultButton} from "../Defaults/DefaultButton";
import {BaseInput} from "../Defaults/BaseInput";
import {createCouponsAction} from "../../../_actions/coupon.action"
import {useSelector, useDispatch} from "react-redux"
import Select from "react-select";
import {DateConvertUs, FormatDate} from "../../../_helper/dateFunction";
import {RangeDatePicker,DatePicker} from "jalali-react-datepicker";
import "./style.css"
import moment from "moment-jalaali";
import {useTranslation} from "react-i18next";

const CreateCoupon = () => {
    const [title, setTitle] = useState("");
    const [code, setCode] = useState("");
    const [status, setStatus] = useState("");
    const [percentage, setPercentage] = useState("");
    const [startDate, setStartDate] = useState(moment(new Date()));
    const [endDate, setEnDate] = useState(moment(new Date()));


    const createCoupons = useSelector((state) => state.createCoupons);
    const {error, loading, success} = createCoupons;



    const handleStartDate = ({ start, end }) => {
        setStartDate(moment(start._d).format('YYYY-MM-DD'))
        setEnDate(moment(end._d).format('YYYY-MM-DD'))

    }
    useEffect(()=>{
        document.getElementsByClassName("rdp__input--start")[0].classList.add('form-control');
        document.getElementsByClassName("rdp__input--end")[0].classList.add('form-control');
    },[])


    const dispatch = useDispatch();
    const handleCreateCoupon = () => {
        const data = {
            title: title,
            code: code,
            percentage: percentage,
            start_date: startDate,
            end_date: endDate,
            status: status?.value,
        }
        dispatch(createCouponsAction(data))
    }


    const handleStatusChange = (selected) => {
        setStatus(selected);
    };
    let dir = localStorage.getItem("Layout");
    const statusOptions = [
        {value: "active", label: dir === "rtl" ? " فعال" : "published"},
        {value: "deactivate", label: dir === "rtl" ? "غیر فعال" : "Unpublished"},
    ]
    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#000',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };
    const formatGroupLabel = data => (
        <div style={groupStyles}>
            <span style={{color: '#000'}}>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );
    const isEmpty = title === "" || status === "" || code === "" || percentage === "" || percentage === null || startDate === "" || endDate === ""
    const {t} = useTranslation();
    return (
        <>
            <PageTitle activeMenu={t('CREATE_COUPON')} pageContent={t('CREATE_COUPON')} motherMenu={t('DASHBOARD_KEY')}
                       dashUrl={""} prev={t("COUPON_VIEW_KEY")} prevUrl={"coupon"}/>

            <div className={"p-4 card"}>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <BaseInput
                                className="form-control "
                                id="title"
                                type="text"
                                value={title}
                                placeholder={t('Title_KEY')}
                                onChange={(e) => setTitle(e.target.value)}
                                label={t('Title_KEY')}
                            />
                            {error?.title && (<span className={"text-danger"}>{error.title}</span>)}
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <BaseInput
                                className="form-control "
                                id="mobile"
                                type="text"
                                value={code}
                                placeholder={t('Code_KEY')}
                                onChange={(e) => setCode(e.target.value)}
                                label={t('Code_KEY')}
                            />
                            {error?.code && (<span className={"text-danger"}>{error.code}</span>)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <label>{t('STATUS_KEY')}</label>
                            <Select
                                className="text-dark"
                                formatGroupLabel={formatGroupLabel}
                                placeholder={t('STATUS_KEY')}
                                options={statusOptions}
                                onChange={handleStatusChange}
                            />
                            {error?.status && (
                                <p className={"text-danger"}>{error?.status}</p>
                            )}
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <BaseInput
                                className="form-control "
                                id="percentage"
                                type="number"
                                onWheelCapture={e => {
                                    e.target.blur()
                                  }}
                                value={percentage}
                                placeholder={t('COMMISSION_PERCENTAGE')}
                                onChange={(e) => setPercentage(e.target.value)}
                                label={t('COMMISSION_PERCENTAGE')}
                            />
                            {error?.percentage && (<span className={"text-danger"}>{error.percentage}</span>)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-6 ">
                            <RangeDatePicker
                                onClickSubmitButton={handleStartDate}
                                fromLabel={t('CREATE_COUPON')} toLabel={t('END_DATE_KEY')} />
                            {error?.start_date && (<span className={"text-danger mt-3"}>{error.start_date}</span>)}

                        </div>

                    </div>
                    <div className="d-flex justify-content-center ">
                        <DefaultButton
                            disabled={isEmpty}
                            loading={loading}
                            type="submit"
                            onClick={() => handleCreateCoupon()}
                            className={`Approve_btn btn-primary mt-4 d-block`}
                            variant="contained"
                            color="primary"
                        >
                            {t('INSERT_KEY')}
                        </DefaultButton>
                    </div>
                </form>
            </div>
        </>
    );
};

export default CreateCoupon;
