import {useEffect} from 'react';
import {getAllREviews} from "../../../_actions/review.actions";
import {useSelector, useDispatch} from "react-redux"
import ReviewTable from "./ReviewTable";
import PageTitle from "../../layouts/PageTitle";
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import {useTranslation} from "react-i18next";

const Reviews = () => {
    const getAllReviews = useSelector((state) => state.getAllReviews);
    const {review, loading, error} = getAllReviews;
    const dispatch = useDispatch();
    let isValidREVIEW = ValidPermissions(PermissionConst.REVIEW_LIST)
    useEffect(() => {
        if (isValidREVIEW) {
            dispatch(getAllREviews());
        }
    }, []);
    const {t} = useTranslation();
    return (
        <div>
            <PageTitle activeMenu={t('REVIEW_KEY')} pageContent={t('REVIEW_KEY')} motherMenu={t('DASHBOARD_KEY')}
                     dashUrl={""}  />
            <ReviewTable loading={loading} error={error} review={review}/>
        </div>
    );
};

export default Reviews;
