import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteGiftAction,
    detailsGiftAction,
    updateGiftAction,
} from "../../../_actions/gift-actions";
import {useParams} from "react-router-dom";
import {BaseInput} from "../Defaults/BaseInput";
import {Button} from "react-bootstrap";
import {giftConst} from "../../../_constants/gift-constant";
import UpdateGiftModal from "./UpdateGiftModal";
import Pagination from "react-js-pagination";
import {giftActions} from "../../../_actions/gift-actions";
import {ERROR} from "../../../_helper";
import {useTranslation} from "react-i18next";

const GiftUpdate = ({getProductDetails, refresh, setRefresh}) => {
    const [page, setPage] = useState(1);
    const [giftTotal, setGiftTotal] = useState(1);
    const [giftsData, setGiftsData] = useState([]);
    const [isfetch, setIsfetch] = useState(false);
    const detailsGift = useSelector((state) => state.detailsGift);
    const {infoGift} = detailsGift;
    const dispatch = useDispatch();
    const {id} = useParams();

    const {t} = useTranslation();
    useEffect(() => {
        // dispatch(detailsGiftAction(id));
    }, []);


    const deleteGift = (id) => {
        dispatch(deleteGiftAction(id));
        setRefresh(!refresh);
    };
    const [modalCentered, setModalCentered] = useState(false);
    const [codes, setCodes] = useState("");
    const handleOpenModal = (item) => (e) => {
        setIsfetch(item.id)
        setModalCentered(true);
        setCodes(item.code)
    }
    const handlePageChange = async (pageNumber) => {
        setPage(pageNumber);
        const page = pageNumber;
        dispatch(giftActions.getGiftsList(page, id))
            .then((r) => {
                if (r.status === 200) {
                    window.scroll(0, 1500);
                    setGiftsData(r);
                    setGiftTotal(r.data?.meta?.total);
                }
            })
            .catch((ex) =>
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                })
            );
    };

    useEffect(() => {
        dispatch(giftActions.getGiftsList(page, id))
            .then((r) => {
                if (r.status === 200) {
                    setGiftsData(r);
                    setGiftTotal(r.data?.meta?.total);
                }
            })
            .catch((ex) =>
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                })
            );
    }, [page]);
    let dir = localStorage.getItem("Layout");
    return (
        <>
            <div
                className={dir === "rtl" ? "card p-5  col-xs-12 col-md-12  col-lg-12  col-xl-6 mr-3" : "card p-5  col-xs-12 col-md-12  col-lg-12  col-xl-6 ml-3"}>
                <h3 className={"fs-15"}>{t('EDIT_GIFT')}</h3>
                {giftsData?.data?.data?.available_gifts?.data.map(
                    (item, i) => (
                        <div key={i} className={"row"}>
                            <div className="col-xs-12 col-md-9"><BaseInput
                                className="form-control text-right rtl_direction rounded-0"
                                id="description"
                                type="text"
                                value={item.code}
                                placeholder={t('INSERT_CODE_CART')}
                                label={`کد ${i}`}
                            />
                            </div>
                            <div className="col-xs-12 col-md-3 col-sm-12">
                                <div className="d-flex mt-3">
                                    <Button className={"btn-warning btn mt-2 btn-sm mr-2 rounded-sm"}
                                            onClick={handleOpenModal(item)}>
                                        <i className={"fa fa-edit"}/>
                                    </Button>

                                    <button
                                        className={" btn-danger btn mt-2 btn-sm mr-2 rounded-sm"}
                                        onClick={() => deleteGift(item.id)}
                                    >
                                        <i className={"fa fa-trash"}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                )}

                <UpdateGiftModal
                    isfetch={isfetch}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    setIsfetch={setIsfetch}
                    setModalCentered={setModalCentered}
                    modalCentered={modalCentered}
                    codes={codes}
                />

                <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example5_paginate"
                >
                    <div className="d-inline-block pb-3 ltr_direction">
                        {giftsData?.data?.data?.available_gifts?.data.length >= 1 && (
                            <Pagination
                                activePage={page}
                                itemsCountPerPage={getProductDetails?.productDetails?.data?.available_gifts?.per_page}
                                totalItemsCount={getProductDetails?.productDetails?.data?.available_gifts?.total}
                                pageRangeDisplayed={20}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default GiftUpdate;
