import React from "react";

const FormatDate = (date) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

const DateConvert = (date) => new Date(date).toLocaleDateString("fa-IR");
const DateConvertUs = (date) => new Date(date).toLocaleDateString("en-US");
const DateConvertWithTime = (date) => {
  let newVar, time, stepOne, stepTwo, convert, final;
  time = date?.split(" ");
  stepOne = time[0].split("-");
  stepTwo = time[1].split(":");
  newVar = new Date();
  convert = new Date(
    stepOne[0],
    stepOne[1],
    stepOne[2],
    stepTwo[0],
    stepTwo[1],
    stepTwo[2]
  );
  final = `${DateConvert(time[0])} ${time[1]}`;
  return final;
};
export { FormatDate, DateConvert, DateConvertUs, DateConvertWithTime };
