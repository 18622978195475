import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getUserInfoAction, userActions} from "../../../_actions/dashboard.actions"
import {Row, Col} from "react-bootstrap"
import {ERROR} from "../../../_helper";
import Select from "react-select";
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import {useTranslation} from "react-i18next";

export default function GetUserInfo({time, setTime}) {
    const [userData, setUserData] = useState([]);
    const ChartUserInfo = useSelector((state) => state.ChartUserInfo);
    const {ChartUser} = ChartUserInfo;
    const dispatch = useDispatch();

    let isValid = ValidPermissions(PermissionConst.DASHBOARD_USER_AND_REVIEW_ORDER_STATISTICS);


    useEffect(() => {
        if (isValid) {
            dispatch(getUserInfoAction())
        }
    }, []);

    const getUer = (time) => {
        dispatch(
            userActions.filterUser(time)
        )
            .then((r) => {
                if (r.status === 200) {
                    setUserData(r?.data)
                }
            })
            .catch((ex) =>
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                })
            );
    }
    const handleChangeData = (selected) => {
        setTime(selected)
    }
    useEffect(() => {
        if (isValid) {
            getUer(time.value)
        }
    }, [time]);

    useEffect(() => {
        if (isValid) {
            dispatch(
                userActions.filterUser(time)
            )
                .then((r) => {
                    if (r.status === 200) {
                        setUserData(r?.data)
                    }
                })
                .catch((ex) =>
                    ERROR(ex.response?.data?.message || ex.message, {
                        error: ex,
                    })
                );
        }
    }, []);

    const formatGroupLabel = data => (
        <div style={groupStyles}>
            <span style={{color: '#000'}}>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );
    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#000',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };

    let dir = localStorage.getItem("Layout");
    const amar = [
        {type: 'today', value: dir === "rtl" ? "روزانه" : dir === "ltr" ? "Daily" : "روزانه"},
        {type: 'weekly', value: dir === "rtl" ? "هفتگی" : dir === "ltr" ? "Weekly" : "هفتگی"},
        {type: 'monthly', value: dir === "rtl" ? "ماهانه" : dir === "ltr" ? "Monthly    " : "ماهانه"}
    ]

    const optionData = amar?.map((item) => {
        return {
            value: item.type,
            label: item.value,
        };
    });
    const {t} = useTranslation();
    return (
        <>
            <div className={"mt-5"}>
                <div className="d-flex  justify-content-between align-items-center">
                    <h4>
                        <span>{t('STATISTICS_KEY')}</span> {time === "today" ? t('DAILY_KEY') : time === "weekly" ? t('WEEKLY_KEY') : time === "monthly" ? t('MONTHLY_KEY') : null}
                    </h4>
                    <Select
                        options={optionData}
                        formatGroupLabel={<formatGroupLabel/>}
                        className="text-dark w-25 mb-4"
                        placeholder={t('DAILY_STATISTICS_KEY')}
                        onChange={handleChangeData}/>
                </div>

                <Row>
                    <Col xs={12} md={4} sm={6}>
                        <div className="card p-4 ">
                            <div className="d-flex  justify-content-between align-items-center">
                                <h4>{t('USERS_KEY')}</h4>

                                <i className={"fa fa-user fa-3x text-dark"}/>
                            </div>
                            <div className="d-flex mt-4  justify-content-between align-items-center">
                                <div className="">{t('COUNT_KEY')}</div>
                                <div className="">{userData?.data?.user?.count}</div>
                            </div>
                            <p className={"fs-15 mt-4 text-dark"}>{dir === "rtl" ? userData?.data?.user?.description : "Statistics users"}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4} sm={6}>
                        <div className="card bg-primary p-4">
                            <div className="d-flex  justify-content-between align-items-center">
                                <h4>{t('ORDERS_KEY')}</h4>

                                <i className={"fa fa-first-order fa-3x"}/>
                            </div>
                            <div className="d-flex mt-4 justify-content-between align-items-center">
                                <div className="">{t('COUNT_KEY')}</div>
                                <div className="">{userData?.data?.order?.count}</div>
                            </div>
                            <p className={"fs-15 mt-4 text-dark"}>{dir === "rtl" ? userData?.data?.order?.description : "Statistics orders"}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4} sm={6}>
                        <div className="bg-secondary card p-4">
                            <div className="d-flex  justify-content-between align-items-center">
                                <h4>{t('COMMENT_KEY')}</h4>
                                <i className={"fa fa-comment fa-3x"}/>

                            </div>
                            <div className="d-flex mt-4 justify-content-between align-items-center">
                                <div className="">{t('COUNT_KEY')}</div>
                                <div className="">{userData?.data?.comment?.count}</div>
                            </div>
                            <p className={"fs-15 mt-4 text-white "}>{dir === "rtl" ? userData?.data?.comment?.description : "Statistics Comments"}</p>
                        </div>
                    </Col>
                </Row>
            </div>

        </>
    )
}