import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import GetUserInfo from "./GetUserInfo";
import GetOrderStatic from "./GetOrderStatic";
import GetOrderStatus from "./GetOrderStatus";
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst"
import {useTranslation} from "react-i18next"

const Home = () => {
    const [time, setTime] = useState("today");

    let history = useHistory();

    useEffect(() => {
        let user = localStorage.getItem('token');
        if (user === null || undefined) {
            history.push("/page-login");
        }
    }, []);
    const {t} = useTranslation();

    return (
        <>
            <h2 className="font-w600 mb-2 ml-auto ">{t('DASHBOARD_KEY')}</h2>
            {ValidPermissions(PermissionConst.DASHBOARD_USER_AND_REVIEW_ORDER_STATISTICS) ? (<>
                <GetUserInfo time={time} setTime={setTime}/>
            </>) : null}
            {ValidPermissions(PermissionConst.DASHBOARD_ORDERS_STATISTICS) ? (<>
                <GetOrderStatic time={time} setTime={setTime}/>
            </>) : null}

            {ValidPermissions(PermissionConst.DASHBOARD_ORDERS_STATUS_STATISTICS) ? (<>
                <GetOrderStatus/>
            </>) : null}
        </>
    );
};
export default Home;


