export const walletConst = {
    GET_ALL_TRANSACTION_REQUEST: "GET_ALL_TRANSACTION_REQUEST",
    GET_ALL_TRANSACTION_SUCCESS: "GET_ALL_TRANSACTION_SUCCESS",
    GET_ALL_TRANSACTION_FAIL: "GET_ALL_TRANSACTION_FAIL",

    //create
    CREATE_TRANSACTION_REQUEST: "CREATE_TRANSACTION_REQUEST",
    CREATE_TRANSACTION_SUCCESS: "CREATE_TRANSACTION_SUCCESS",
    CREATE_TRANSACTION_FAIL: "CREATE_TRANSACTION_FAIL",
}
