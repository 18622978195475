import {userConstant, EditConstant, permissionConstant, userWallet,singleUserConstant} from "../_constants/user.constant";
import {_http, ERROR, SUCCESS} from "../_helper";
import {createProductConst} from "../_constants/product-constant";
///get All users

export const getAllUsers = () => async (dispatch) => {
    dispatch({type: userConstant.GET_ALL_USERS_REQUEST});
    await _http("admin/users", {method: "get"})
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: userConstant.GET_ALL_USERS_SUCCESS,
                    payload: res?.data,
                });
            } else {
                ERROR(res);
                dispatch({type: userConstant.GET_ALL_USERS_FAIL, payload: res});
            }
        })
        .catch((e) => {
            let message;
            if (e?.response?.data?.message) message = e?.response?.data?.message;
            ERROR(message);
            dispatch({type: userConstant.GET_ALL_USERS_FAIL, payload: e});
        });
};
export const getUser = () => async (dispatch) => {
    dispatch({type: singleUserConstant.GET_USER_REQUEST});
    await _http("user", {method: "get"})
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: singleUserConstant.GET_USER_SUCCESS,
                    payload: res?.data,
                });
            } else {
                ERROR(res);
                dispatch({type: singleUserConstant.GET_USER_FAIL, payload: res});
            }
        })
        .catch((e) => {
            let message;
            if (e?.response?.data?.message) message = e?.response?.data?.message;
            ERROR(message);
            dispatch({type: singleUserConstant.GET_USER_FAIL, payload: e});
        });
};

///get All Permission

export const getPermissionUsers = () => async (dispatch) => {
    dispatch({type: permissionConstant.PERMISSION_USER_REQUEST});
    await _http("admin/roles_permissions", {method: "get"})
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: permissionConstant.PERMISSION_USER_SUCCESS,
                    payload: res?.data,
                });
            } else {
                ERROR(res);
                dispatch({type: permissionConstant.PERMISSION_USER_FAIL, payload: res});
            }
        })
        .catch((e) => {
            let message;
            if (e?.response?.data?.message) message = e?.response?.data?.message;
            ERROR(message);
            dispatch({type: permissionConstant.PERMISSION_USER_FAIL, payload: e});
        });
};
//userDetails
export const usersDetailsActions = (id) => async (dispatch) => {
    dispatch({type: userConstant.GET_DETAILS_USERS_REQUEST, payload: id});
    await _http(`admin/users/${id}`, {method: "get"})
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: userConstant.GET_DETAILS_USERS_SUCCESS,
                    payload: res?.data,
                });
            } else {
                ERROR(res);
                dispatch({type: userConstant.GET_DETAILS_USERS_FAIL, payload: res});
            }
        })
        .catch((error) => {
            let message;
            if (error?.response?.data?.message)
                message = error?.response?.data?.message;
            ERROR(message);
            dispatch({type: userConstant.GET_DETAILS_USERS_FAIL, payload: error});
        });
};
///get search

///create user

export const createUserActions = (data) => async (dispatch) => {
    dispatch({type: userConstant.CREATE_USER_REQUEST, payload: data});
    await _http("admin/users", {method: "post", data})
        .then((res) => {
            if (res.status === 200) {
                SUCCESS(res.data.message);
                window.location.href = "/users";
                dispatch({type: userConstant.CREATE_USER_SUCCESS, payload: res});
            } else {
                ERROR(res?.response.data.message);
                dispatch({
                    type: userConstant.CREATE_USER_FAIL,
                    payload: res.response?.data?.errors,
                });
            }
        })
        .catch((error) => {
            let message;
            if (error?.response?.data?.message)
                message = error?.response?.data?.message;
            ERROR(message);
            dispatch({type: userConstant.CREATE_USER_FAIL, payload: error});
        });
};

///Edit User
export const editUserAction = (data, id) => async (dispatch) => {
    dispatch({type: EditConstant.EDIT_USER_REQUEST, payload: data});
    await _http(`admin/users/${id}`, {method: "post", data, id})
        .then((res) => {
            if (res.status === 200) {
                SUCCESS(res?.data?.message);
                dispatch({type: EditConstant.EDIT_USER_SUCCESS, payload: data});
                console.log(data, "for actions")
                // window.location.href = "/users";
            } else {
                ERROR(res?.response?.data?.message);
                dispatch({
                    type: EditConstant.EDIT_USER_FAIL,
                    payload: res.response?.data?.errors,
                });
            }
        })
        .catch((error) => {
            console.log(error.response.data.message);
            let message;
            if (error?.response?.data?.message)
                message = error?.response?.data?.message;
            ERROR(message);
            dispatch({type: EditConstant.EDIT_USER_FAIL, payload: error});
        });
};

//get order List

//get order getUserTransactionsActions
export const getUserTransactionsActions = (id) => async (dispatch) => {
    dispatch({type: userWallet.GET_USER_TRANSACTION_REQUEST, payload: id});
    await _http(`admin/users/transaction/${id}`, {method: "get"})
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: userWallet.GET_USER_TRANSACTION_SUCCESS,
                    payload: res?.data,
                });
            } else {
                ERROR(res);
                dispatch({type: userWallet.GET_USER_TRANSACTION_FAIL, payload: res});
            }
        })
        .catch((error) => {
            let message;
            if (error?.response?.data?.message)
                message = error?.response?.data?.message;
            ERROR(message);
            dispatch({type: userWallet.GET_USER_TRANSACTION_FAIL, payload: error});
        });
};

//get order getUserCredit
export const getUserCreditActions = (id) => async (dispatch) => {
    dispatch({type: userWallet.GET_USER_CREDIT_REQUEST, payload: id});
    await _http(`admin/users/accountCredit/${id}`, {method: "get"})
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: userWallet.GET_USER_CREDIT_SUCCESS,
                    payload: res?.data,
                });
            } else {
                ERROR(res);
                dispatch({type: userWallet.GET_USER_CREDIT_FAIL, payload: res});
            }
        })
        .catch((error) => {
            let message;
            if (error?.response?.data?.message)
                message = error?.response?.data?.message;
            ERROR(message);
            dispatch({type: userWallet.GET_USER_CREDIT_FAIL, payload: error});
        });
};

function getTransactionList(page, id) {
    return () => {
        return _http(`admin/users/transaction/${id}?page=${page}`, {method: "get"});
    };
}

function getUserList(page) {
    return () => {
        return _http(`admin/users?page=${page}`, {method: "get"});
    };
}

function filterUsers(search) {
    return () => {
        return _http(`admin/users?search=${search}`, {method: "get"});
    };
}

export const userActions = {
    getUserList,
    filterUsers,
    getTransactionList,
};
