export const reviewConst = {
    GET_ALL_REVIEW_REQUEST:"GET_ALL_REVIEW_REQUEST",
    GET_ALL_REVIEW_SUCCESS:"GET_ALL_REVIEW_SUCCESS",
    GET_ALL_REVIEW_FAIL:"GET_ALL_REVIEW_FAIL",

    GET_DETAILS_REVIEW_REQUEST:"GET_DETAILS_REVIEW_REQUEST",
    GET_DETAILS_REVIEW_SUCCESS:"GET_DETAILS_REVIEW_SUCCESS",
    GET_DETAILS_REVIEW_FAIL:"GET_DETAILS_REVIEW_FAIL",



    DELETE_REVIEW_REQUEST: "DELETE_REVIEW_REQUEST",
    DELETE_REVIEW_SUCCESS:"DELETE_REVIEW_SUCCESS",
    DELETE_REVIEW_FAIL: "DELETE_REVIEW_FAIL",


    EDIT_REVIEW_REQUEST: "EDIT_REVIEW_REQUEST",
    EDIT_REVIEW_SUCCESS:"EDIT_REVIEW_SUCCESS",
    EDIT_REVIEW_FAIL: "EDIT_REVIEW_FAIL",
}
