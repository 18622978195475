import {currencyConst} from "../_constants/currency-constant"


/// get currency

export const getAllCurrencyReducer = (state = {currencyData: []}, action) => {
    switch (action.type) {
        case currencyConst.GTE_ALL_CURRENCY_REQUEST:
            return {loading: true}
        case currencyConst.GTE_ALL_CURRENCY_SUCCESS :
            return {loading: false, currencyData: action.payload}
        case currencyConst.GTE_ALL_CURRENCY_FAIL :
            return {loading: false, error: action.payload}
        default :
            return state

    }
}

///get currency

export const UpdateCurrencyReducer = (state = {}, action) => {
    switch (action.type) {
        case currencyConst.GTE_ALL_CURRENCY_REQUEST :
            return {loading: true}
        case currencyConst.UPDATE_CURRENCY_SUCCESS:
            return {loading: true, success: true}
        case currencyConst.UPDATE_CURRENCY_FAIL:
            return {loading: false, error: action.payload}
        default :
            return state
    }
}


///Details
export const detailsCurrencyReducer = (state = {currencyDetails: {}}, action) => {
    switch (action.type) {
        case currencyConst.DETAILS_CURRENCY_REQUEST :
            return {loading: true}
        case currencyConst.DETAILS_CURRENCY_SUCCESS:
            return {loading: true, currencyDetails: action.payload}
        case currencyConst.DETAILS_CURRENCY_FAIL:
            return {loading: false, error: action.payload}
        default :
            return state
    }
}