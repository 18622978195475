import React, {Suspense} from "react";
import {render} from "react-dom";
import App from "./App";
import {Provider} from "react-redux";
import {store} from "./_helper";
import SimpleReactLightbox from "simple-react-lightbox";
import ProgressBar from 'react-bootstrap/ProgressBar'
render(
    <Suspense fallback={<ProgressBar animated now={100}/>}>
        <Provider store={store}>
            <SimpleReactLightbox>
                <App/>
            </SimpleReactLightbox>
        </Provider></Suspense>,
    document.getElementById("root")
);
