import {giftConst} from "../_constants/gift-constant"


//get Details
export const detailsGiftReducer = (state = {infoGift: []}, action) => {

    switch (action.type) {
        case giftConst.DETAILS_GIFT_REQUEST :
            return {loading: true}
        case giftConst.DETAILS_GIFT_SUCCESS :
            return {loading: false, infoGift: action.payload}
        case giftConst.DETAILS_GIFT_FAIL :
            return {loading: false, error: action.payload}
        default :
            return state
    }

}
//create Gift
export const createGiftReducer = (state = {}, action) => {

    switch (action.type) {
        case giftConst.CREATE_GIFT_REQUEST :
            return {loading: true}
        case giftConst.CREATE_GIFT_SUCCESS :
            return {loading: false, success: true}
        case giftConst.CREATE_GIFT_FAIL :
            return {loading: false, error: action.payload}
        default :
            return state
    }
}

//get Details
export const updateGiftReducer = (state = {}, action) => {
    switch (action.type) {
        case giftConst.UPDATE_GIFT_REQUEST :
            return {loading: true}
        case giftConst.UPDATE_GIFT_SUCCESS :
            return {loading: false, success: true}
        case giftConst.UPDATE_GIFT_FAIL :
            return {loading: false, error: action.payload}

        default :
            return state
    }
}


//Create Gift with excel
export const createGiftExcelReducer = (state = {}, action) => {

    switch (action.type) {
        case giftConst.CREATE_EXCEL_REQUEST :
            return {loading: true}
        case giftConst.CREATE_EXCEL_SUCCESS :
            return {loading: false, success: true}
        case giftConst.CREATE_EXCEL_FAIL:
            return {loading: false, error: action.payload}
        default :
            return state
    }
}

