import {walletConst} from "../_constants/wallet-constant"
import {_http, ERROR, SUCCESS} from "../_helper";
import {getError} from "../_helper/getError";
import {createProductConst} from "../_constants/product-constant";

export const getAllTransaction = () => async (dispatch) => {
    dispatch({type: walletConst.GET_ALL_TRANSACTION_REQUEST})
    await _http("admin/transactions", {method: "get"}).then((res) => {
        if (res.status === 200) {
            dispatch({type: walletConst.GET_ALL_TRANSACTION_SUCCESS, payload: res?.data})
        } else {

            dispatch({type: walletConst.GET_ALL_TRANSACTION_FAIL, payload: res})
        }
    }).catch((error) => {
        let message;
        if (error?.response?.data?.message) message = error?.response?.data?.message;
        ERROR(message);
        dispatch({type: walletConst.GET_ALL_TRANSACTION_FAIL, payload: error})

    })
}
    export const chargeWallet = (data, id) => async (dispatch) => {
    dispatch({type: walletConst.CREATE_TRANSACTION_REQUEST, payload: data, id});
    await _http("admin/changeCredit", {method: "post", data, id}).then((res) => {
        if (res.status === 200) {
            dispatch({type: walletConst.CREATE_TRANSACTION_FAIL, payload: res});
            SUCCESS(res?.data?.message);
        } else {
            dispatch({type: walletConst.CREATE_TRANSACTION_FAIL, payload: res?.response?.data?.errors});
            ERROR(res.response?.data?.message)
        }
    }).catch((error) => {
        getError(error);
        dispatch({type: walletConst.CREATE_TRANSACTION_FAIL,payload:error?.response?.data?.message});
    })

}


function filterWallet(search) {
    return () => {
        return _http(`admin/transactions?search=${search}`, {method: "get"})
    }
}

//get order List
function getWalletPage(page) {
    return () => {
        return _http(`admin/transactions?page=${page}`, {method: "get"});
    };
}

export const WalletActions = {
    filterWallet, getWalletPage,
}

