export const PermissionConst = {
    CATEGORY_LIST: 'category_list',
    CATEGORY_STORE: 'category_store',
    CATEGORY_SHOW: 'category_show',
    CATEGORY_UPDATE: 'category_update',
    CATEGORY_DESTROY: 'category_destroy',
    COUPON_LIST: 'coupon_list',
    COUPON_STORE: 'coupon_store',
    COUPON_SHOW: 'coupon_show',
    COUPON_UPDATE: 'coupon_update',
    COUPON_DESTROY: 'coupon_destroy',
    CURRENCY_LIST: 'currency_list',
    CURRENCY_SHOW: 'currency_show',
    CURRENCY_UPDATE: 'currency_update',
    DASHBOARD_ORDERS_STATISTICS: 'dashboard_orders_statistics',
    DASHBOARD_USER_AND_REVIEW_ORDER_STATISTICS: 'dashboard_user_and_review_and_order_statistics',
    DASHBOARD_ORDERS_STATUS_STATISTICS: 'dashboard_orders_status_statistics',
    GIFT_LIST: 'gift_list',
    GIFT_STORE: 'gift_store',
    GIFT_SHOW: 'gift_show',
    GIFT_UPDATE: 'gift_update',
    GIFT_DESTROY: 'gift_destroy',
    GIFT_IMPORT_EXCEL_GIFTS: 'gift_import_excel_gifts',
    ORDER_LIST: 'order_list',
    ORDER_SHOW: 'order_show',
    PRODUCT_LIST: 'product_list',
    PRODUCT_STORE: 'product_store',
    PRODUCT_SHOW: 'product_show',
    PRODUCT_UPDATE: 'product_update',
    REVIEW_LIST: 'review_list',
    REVIEW_SHOW: 'review_show',
    REVIEW_UPDATE: 'review_update',
    REVIEW_DESTROY: 'review_destroy',
    USER_LIST: 'user_list',
    USER_STORE: 'user_store',
    USER_SHOW: 'user_show',
    USER_UPDATE: 'user_update',
    USER_SEE_USER_TRANSACTIONS: 'user_see_user_transactions',
    USER_SEE_USER_ACCOUNT_CREDIT_BALANCE: 'user_see_user_account_credit_balance',
    USER_SEE_ALL_TRANSACTIONS: 'user_see_all_transactions',
    USER_CAN_DEPOSIT_OR_WITHDRAW_FROM_USER_ACCOUNT: 'user_can_deposit_or_withdraw_from_user_account',
}

