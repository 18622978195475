export const productConstant = {
    GET_ALL_PRODUCT_REQUEST: "GET_ALL_PRODUCT_REQUEST",
    GET_ALL_PRODUCT_SUCCESS: "GET_ALL_PRODUCT_SUCCESS",
    GET_ALL_PRODUCT_FAIL: "GET_ALL_PRODUCT_FAIL",

    //details
    DETAILS_PRODUCT_REQUEST: "DETAILS_PRODUCT_REQUEST",
    DETAILS_PRODUCT_SUCCESS: "DETAILS_PRODUCT_SUCCESS",
    DETAILS_PRODUCT_FAIL: "DETAILS_PRODUCT_FAIL",

}

export const createProductConst = {
    CREATE_PRODUCT_REQUEST: "CREATE_PRODUCT_REQUEST",
    CREATE_PRODUCT_SUCCESS: "CREATE_PRODUCT_SUCCESS",
    CREATE_PRODUCT_FAIL: "CREATE_PRODUCT_FAIL",

    EDIT_PRODUCT_REQUEST: "EDIT_PRODUCT_REQUEST",
    EDIT_PRODUCT_SUCCESS: "EDIT_PRODUCT_SUCCESS",
    EDIT_PRODUCT_FAIL: "EDIT_PRODUCT_FAIL",
}