import {Button, Modal} from "react-bootstrap";
import {useState} from "react";
import {useTranslation} from "react-i18next";

const TransactionModal = ({children, trans, data}) => {
    const [modalCentered, setModalCentered] = useState(false);

    const {t} = useTranslation();
    return (
        <div>
            {/* <!-- Button trigger modal --> */}
            <Button
                variant="primary"
                item
                type="button"
                className="d-flex m-auto btn-sm"
                onClick={() => setModalCentered(true)}
            >
                {children}
            </Button>
            {/* <!-- Modal --> */}
            <Modal
                className="fade "
                bsClass="my-modal"
                size="md"
                show={modalCentered}
                onHide={setModalCentered}
            >
                <Modal.Header>
                    <h4 className="number-black">{t('DETAILS_KEY')}</h4>
                    <Button
                        onClick={() => setModalCentered(false)}
                        variant=""
                        className="close"
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        <li className={"mt-2"}>
                            <span> {t('DESCRIPTION_KEY')} : </span>
                            {data?.meta.description}
                        </li>
                        <li className={"mt-2"}>
                            <span>{t('EMAIL_KEY')}  : </span>
                            {trans.email}
                        </li>
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setModalCentered(false)}
                        variant="danger light"
                        className={"ml-3"}
                    >
                        بستن
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
export default TransactionModal;
