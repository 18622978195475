import React, { useState } from 'react';
import PageTitle from "../../layouts/PageTitle";
import { DefaultButton } from "../Defaults/DefaultButton";
import { BaseInput } from "../Defaults/BaseInput";
import { useSelector, useDispatch } from "react-redux"
import "../coupon/style.css"
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { submitMasterAction } from '../../../_actions/order-actions';
import FileUploader from '../../../_helper/FileUploader';

const MasterCardSubmit = () => {
    const [card_number, setCardNumber] = useState("");
    const [expiry_date, setExpiryDate] = useState("");
    const [security_code, setSecurityCode] = useState("");
    const [cardholder_name, setCardholderName] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [phone_number, setPhoneNumber] = useState("");
    const [state, setState] = useState("");
    const [street_address, setStreetAddress] = useState("");
    const [zip_code, setZipCode] = useState("");
    const [photo, setPhoto] = useState('')


    const submitMaster = useSelector((state) => state.submitMaster);
    const { error, loading, success } = submitMaster;
    let { id } = useParams();

    const dispatch = useDispatch();
    const data = new FormData();
    const handleSubmitMaster = () => {
        data.append("card_number", card_number);
        data.append("expiry_date", expiry_date);
        data.append("security_code", security_code);
        data.append("city", city);
        data.append("country", country);
        data.append("phone_number", phone_number);
        data.append("cardholder_name", cardholder_name);
        data.append("state", state);
        data.append("street_address", street_address);
        data.append("zip_code", zip_code);
        data.append("photo", photo[0]);

        dispatch(submitMasterAction(data, id))
    }

    const isEmpty = card_number === "" || expiry_date === "" || security_code === "" || cardholder_name === ""
    const { t } = useTranslation();
    return (
        <>
            <PageTitle activeMenu={t('CREATE_COUPON')} pageContent={t('CREATE_COUPON')} motherMenu={t('DASHBOARD_KEY')}
                dashUrl={""} prev={t("COUPON_VIEW_KEY")} prevUrl={"coupon"} />

            <div className={"p-4 card"}>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <BaseInput
                                className="form-control "
                                id="title"
                                type="text"
                                value={card_number}
                                placeholder={"XXXXXXXXXXXXXXXX"}
                                onChange={(e) => setCardNumber(e.target.value)}
                                label={"شماره کارت"}
                            />
                            {error?.card_number && (<span className={"text-danger"}>{error.card_number}</span>)}
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <BaseInput
                                className="form-control "
                                id="mobile"
                                type="text"
                                value={expiry_date}
                                placeholder={"XX/XX"}
                                onChange={(e) => setExpiryDate(e.target.value)}
                                label={"تاریخ انقصا"}
                            />
                            {error?.expiry_date && (<span className={"text-danger"}>{error?.expiry_date}</span>)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <BaseInput
                                className="form-control "
                                id="title"
                                type="text"
                                value={security_code}
                                placeholder={"XXX"}
                                onChange={(e) => setSecurityCode(e.target.value)}
                                label={"CV2"}
                            />
                            {error?.security_code && (<span className={"text-danger"}>{error.security_code}</span>)}
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <BaseInput
                                className="form-control "
                                id="mobile"
                                type="text"
                                value={cardholder_name}
                                placeholder={"Ahad AllahVerdi"}
                                onChange={(e) => setCardholderName(e.target.value)}
                                label={"نام"}
                            />
                            {error?.cardholder_name && (<span className={"text-danger"}>{error.cardholder_name}</span>)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <BaseInput
                                className="form-control "
                                id="title"
                                type="text"
                                value={city}
                                placeholder={"String"}
                                onChange={(e) => setCity(e.target.value)}
                                label={"City"}
                            />
                            {error?.city && (<span className={"text-danger"}>{error.city}</span>)}
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <BaseInput
                                className="form-control "
                                id="mobile"
                                type="text"
                                value={country}
                                placeholder={""}
                                onChange={(e) => setCountry(e.target.value)}
                                label={"Country"}
                            />
                            {error?.country && (<span className={"text-danger"}>{error.country}</span>)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <BaseInput
                                className="form-control "
                                id="title"
                                type="text"
                                value={phone_number}
                                placeholder={"xxxxxxxxxx"}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                label={"Phone"}
                            />
                            {error?.phone_number && (<span className={"text-danger"}>{error.phone_number}</span>)}
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <BaseInput
                                className="form-control "
                                id="mobile"
                                type="text"
                                value={state}
                                placeholder={"state"}
                                onChange={(e) => setState(e.target.value)}
                                label={"State"}
                            />
                            {error?.state && (<span className={"text-danger"}>{error.state}</span>)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <BaseInput
                                className="form-control "
                                id="title"
                                type="text"
                                value={street_address}
                                placeholder={"Street Address"}
                                onChange={(e) => setStreetAddress(e.target.value)}
                                label={"Street Address"}
                            />
                            {error?.street_address && (<span className={"text-danger"}>{error.street_address}</span>)}
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <BaseInput
                                className="form-control "
                                id="mobile"
                                type="text"
                                value={zip_code}
                                placeholder={"Zip Code"}
                                onChange={(e) => setZipCode(e.target.value)}
                                label={"Zip Code"}
                            />
                            {error?.zip_code && (<span className={"text-danger"}>{error.zip_code}</span>)}
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <FileUploader
                                src={photo}
                                onFileUpload={setPhoto}
                                uploadMultiple={false}
                                showPreview={true}
                                title={t('PHOTO_SELECTION_KEY')}
                                description={t('NEED_CHANGE_IMAGE_KEY')}
                            />
                            {error?.photo && (
                                <p className={"text-danger"}>
                                    {error?.photo}
                                </p>
                            )}
                        </div>

                    </div>
                    <div className="d-flex justify-content-center ">
                        <DefaultButton
                            disabled={isEmpty}
                            loading={loading}
                            type="submit"
                            onClick={() => handleSubmitMaster()}
                            className={`Approve_btn btn-primary mt-4 d-block`}
                            variant="contained"
                            color="primary"
                        >
                            {"ثبت"}
                        </DefaultButton>
                    </div>
                </form>
            </div>
        </>
    );
};

export default MasterCardSubmit;
