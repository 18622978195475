import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Row, Col} from "react-bootstrap";
import {
    editCategories,
    getCategoriesDetails,
} from "../../../_actions/category-actions";
import {BaseInput} from "../../components/Defaults/BaseInput";
import {DefaultButton} from "../Defaults/DefaultButton";
import FileUploader from "../../../_helper/FileUploader";
import PageTitle from "../../layouts/PageTitle";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

const EditCategory = () => {
    const [slug, setSlug] = useState("");
    const [name, setName] = useState("");
    const [metaTitle, setMetaTitle] = useState("");
    const [metaDesc, setMetaDesc] = useState("");
    const [photo, setPhoto] = useState(undefined);
    const [status, setStatus] = useState(0);
    const [sort, setSort] = useState(0);
    const [files, setFiles] = useState([]);

    const editCategory = useSelector((state) => state.editCategory);
    const {loading, error, success} = editCategory;

    const categoryDetails = useSelector((state) => state.categoryDetails);
    const {category} = categoryDetails;
    const dispatch = useDispatch();
    const {id} = useParams();
    useEffect(() => {
        dispatch(getCategoriesDetails(id));
    }, [dispatch, id]);
    useEffect(() => {
        setSlug(category?.slug);
        setName(category?.name);
        setStatus(category?.status);
        setSort(category?.sort);
        setPhoto(category?.photo);
        setMetaTitle(category?.meta_title);
        setMetaDesc(category?.meta_desc);
    }, [category]);
    const {id: ID} = useParams();
    const file = new FormData();
    const createBtn = () => {
        if (files.length !== 0) {
            file.append("photo", files?.[0]);
        }
        file.append("name", name);
        file.append("slug", slug);
        file.append("meta_title", metaTitle);
        file.append("meta_desc", metaDesc);
        file.append("status", status);
        file.append("sort", sort);
        file.append("_method", "patch");

        dispatch(editCategories(file, ID));
    };
    const isEmpty =
        slug === "" ||
        name === "" ||
        metaTitle === "" ||
        metaDesc === "" ||
        status === "" ||
        sort === "";
    const {t} = useTranslation();
    return (
        <>
            <PageTitle
                activeMenu={t("EDIT_CATEGORIES_KEY")}
                pageContent={t("EDIT_CATEGORIES_KEY")}
                motherMenu={t("DASHBOARD_KEY")}
                prev={t('CATEGORIES_KEY')}
                prevUrl={"categories"}
                dashUrl={""}
            />
            <div className={"card p-5 "}>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Row>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control "
                                id="name"
                                type="text"
                                value={name}
                                placeholder={t('NAME_KEY')}
                                onChange={(e) => setName(e.target.value)}
                                label={t('NAME_KEY')}
                            />
                        </Col>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control "
                                id="slug"
                                type="text"
                                value={slug}
                                placeholder={t('SLUG_KEY')}
                                onChange={(e) => setSlug(e.target.value)}
                                label={t('SLUG_KEY')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control "
                                id="metaTitle"
                                type="text"
                                value={metaTitle}
                                placeholder={t('META_TITLE_KEY')}
                                onChange={(e) => setMetaTitle(e.target.value)}
                                label={t('META_TITLE_KEY')}
                            />
                        </Col>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control "
                                id="metaDesc"
                                type="text"
                                value={metaDesc}
                                placeholder={t('META_DESCRIPTION_KEY')}
                                onChange={(e) => setMetaDesc(e.target.value)}
                                label={t('META_DESCRIPTION_KEY')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <select style={{height: "40px", color: "#6e6e6e", borderRadius: "11px"}}
                                    className={"mt-4  w-100 bg-transparent"}
                                    onChange={(e) => setStatus(e.target.value)}>
                                <option value="">{t('STATUS_KEY')}</option>
                                <option value="1">{t('PUBLISH_KEY')}</option>
                                <option value="0">{t('UNPUBLISH_KEY')}</option>
                            </select>
                            {error?.status && (
                                <p className={"text-danger"}>{error?.status}</p>
                            )}
                        </Col>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control "
                                id="sort"
                                type="text"
                                value={sort}
                                placeholder={t('Ordering_KEY')}
                                onChange={(e) => setSort(e.target.value)}
                                label={t('Ordering_KEY')}
                                error={error?.sort}
                            />
                            {error?.sort && <p className={"text-danger"}>{error?.sort}</p>}
                        </Col>
                    </Row>
                    <div className="mt-3 mb-3">

                        <Col md={6} xs={12}>
                            <h4>شرایط آپلود عکس </h4>
                            <ul>
                                <li className={"mt-3"}>{t('IMAGE_SIZE_UPLOAD_KEY')}</li>
                                <li className={"mt-2"}>{t('PHOTO_DIMENSIONS_UPLOAD_KEY')}</li>
                            </ul>
                        </Col>

                    </div>
                    <Row>
                        <Col md={6} xs={12}>
                            <div className={"cursor-pointer position-relative w-100 "}>
                                <FileUploader
                                    src={files}
                                    onFileUpload={setFiles}
                                    uploadMultiple={false}
                                    showPreview={true}
                                    title={t('PHOTO_SELECTION_KEY')}
                                    description={t('NEED_CHANGE_IMAGE_KEY')}
                                />
                                {error?.photo && (
                                    <p className={"text-danger"}>
                                        {error?.photo}
                                    </p>
                                )}
                            </div>
                        </Col>
                        <Col md={6} xs={12}>
                            <img
                                className={"w-100 img-fluid img-responsive"}
                                style={{height: "250px", objectFit: "contain"}}
                                src={photo}
                                alt={name}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <div className={"text-center m-auto mt-3"}>
                            <DefaultButton
                                className={"mt-4"}
                                disabled={isEmpty}
                                onClick={() => createBtn()}
                                loading={loading}
                                variant="primary"
                            >
                                {t('EDIT_KEY')}
                            </DefaultButton>
                        </div>
                    </Row>
                </form>
            </div>
        </>
    );
};
export default EditCategory;
