import React, {useEffect} from "react";

/// Components
import Markup from "./jsx";
import {ERROR} from "./_helper/alerts"
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import axios from "axios";
import {withResizeDetector} from "react-resize-detector";

import ThemeContextProvider from "./context/ThemeContext";
import {useSelector} from "react-redux";
import backend from "i18next-http-backend";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import cookie from "js-cookie";

i18n
    .use(initReactI18next)
    .use(backend)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: ["fa", "en"],
        fallbackLng: "fa",
        // fallbackLng: "en",
        detection: {
            order: ["path", "cookie", "htmlTag", "localStorage", "subdomain"],
            caches: ["cookie"],
        },
        // lng: "fa",
        // fallbackLng: "fa",
        backend: {
            loadPath: "/assets/locales/{{lng}}/translations.json",
        },
    });

const App = () => {
    const body = document.querySelector("body");

    const auth = useSelector((state) => state.auth)
    const {userInfo} = auth;


    return (
        <ThemeContextProvider>
            <Markup/>
        </ThemeContextProvider>
    );
};
export default withResizeDetector(App);

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error === "Cannot read property 'status' of undefined") {
            // ERROR("سایت در حال حاضر در دسترس نیست");
        } else if (401 === error.response.status) {
            localStorage.removeItem("token");
            if (process.browser) {
                window.location = "/page-login";
            }
        } else if (403 === error.response.status) {
            // ERROR(error?.response?.data?.message)
            if (process.browser) {
                window.location = "/";
            }
        } else {
            return Promise.reject(error);
        }
    }
);
