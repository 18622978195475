import React, {useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {BaseInput} from "../Defaults/BaseInput";
import {useTranslation} from "react-i18next";
import {useSelector, useDispatch} from "react-redux";
import EditorToolbar, {formats, modules} from "../Product/EditorToolbar";
import ReactQuill from "react-quill";
import {DefaultButton} from "../Defaults/DefaultButton";
import {createPageAction} from "../../../_actions/page.actions"


import {Button, Modal} from "react-bootstrap";
import {Multiselect} from "multiselect-react-dropdown";

const CreatePage = ({children, setModalCentered, modalCentered}) => {

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("")
    const [slug, setSlug] = useState("");
    const [metaTitle, setMetaTitle] = useState("");
    const [metaDesc, setMetaDesc] = useState("");
    const [status, setStatus] = useState("");
    const [menuStatus, setMenuStatus] = useState("");


    const createPage = useSelector((state) => state.createPage);
    const {error, success, loading} = createPage;

    const {t} = useTranslation();
    const handleChange = value => {
        setDescription({value});
    };

    const dispatch = useDispatch();
    const handleCreatePage = () => {
        const data = {
            title: title,
            slug: slug,
            description: description.value,
            meta_title: metaTitle,
            meta_desc: metaDesc,
            status: status[0].id,
            menu_status: menuStatus[0].id,
        }
        dispatch(createPageAction(data))
    }

    if (success) {
        setModalCentered(false);
        window.location.href ="/pages"
    }

    const handleMenuStatus = (selected) => {
        setMenuStatus(selected)
    }
    const handleStatus = (selected) => {
        setStatus(selected)
    }
    const style = {
        chips: {
            display: "flex",
            justify: "space-between",
            color: "#fff"
        },
        searchBox: {
            border: "0.5px solid #faf8f9",
            "border-radius": "10px",
            display: "flex"
        },
        multiselectContainer: {
            color: "#000",
        },
        inputField: {
            margin: " 5px",
            textAlign: "right",
        },
        optionContainer: {
            border: " 1px solid",
        },
        option: {
            color: "#000",
            textAlign: "right",
            display: "flex"
        },
        groupHeading: {}
    };
    const statusSelector = [
        {id: 1, value: t('PUBLISH_KEY')},
        {id: 0, value: t('UNPUBLISH_KEY')}
    ]
    const menuStatusSelector = [
        {id: 1, value: t('PUBLISH_KEY')},
        {id: 0, value: t('UNPUBLISH_KEY')}
    ]
    return (
        <div className={" p-4"}>
            <div>
                {/* <!-- Button trigger modal --> */}
                {/*<Button*/}
                {/*    variant="primary"*/}
                {/*    item*/}
                {/*    type="button"*/}
                {/*    className="d-flex m-auto"*/}
                {/*    onClick={() => setModalCentered(true)}*/}
                {/*>*/}
                {/*    {children}*/}
                {/*</Button>*/}
                {/* <!-- Modal --> */}
                <Modal
                    className="fade "
                    bsClass="my-modal"
                    size="xl"
                    dialogClassName="custom-modal"
                    show={modalCentered}
                    onHide={setModalCentered}
                >
                    <Modal.Header>
                        <h4 className="number-black">{t('GIFT_KEY')}</h4>
                        <Button
                            onClick={() => setModalCentered(false)}
                            variant=""
                            className="close"
                        >
                            <span>&times;</span>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <Row>
                                <Col md={6} xs={12}>
                                    <BaseInput
                                        className="form-control "
                                        id="title"
                                        type="text"
                                        value={title}
                                        placeholder={t('Title_KEY')}
                                        onChange={(e) => setTitle(e.target.value)}
                                        label={t('Title_KEY')}
                                    />
                                    {error?.name && (
                                        <p className={"text-danger"}>{error?.name}</p>
                                    )}
                                </Col>
                                <Col md={6} xs={12}>
                                    <BaseInput
                                        className="form-control"
                                        id="slug"
                                        type="text"
                                        value={slug}
                                        placeholder={t('SLUG_KEY')}
                                        onChange={(e) => setSlug(e.target.value)}
                                        label={t('SLUG_KEY')}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xs={12}>
                                    <label htmlFor="">{t('STATUS_KEY')}</label>
                                    <Multiselect
                                        options={statusSelector}
                                        displayValue="value"
                                        placeholder={t('STATUS_KEY')}
                                        singleSelect={true}
                                        keepSearchTerm={true}
                                        onSelect={handleStatus}
                                        style={style}
                                    />
                                    {error?.name && (
                                        <p className={"text-danger"}>{error?.name}</p>
                                    )}
                                </Col>
                                <Col md={6} xs={12}>
                                    <label htmlFor="">{t('MENU_STATUS_KEY')}</label>
                                    <Multiselect
                                        options={menuStatusSelector}
                                        displayValue="value"
                                        placeholder={t('MENU_STATUS_KEY')}
                                        singleSelect={true}
                                        keepSearchTerm={true}
                                        onSelect={handleMenuStatus}
                                        style={style}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} xs={12} className={"mt-4"}>
                                    <BaseInput
                                        className="form-control"
                                        id="metaTitle"
                                        type="text"
                                        value={metaTitle}
                                        placeholder={t('META_TITLE_KEY')}
                                        onChange={(e) => setMetaTitle(e.target.value)}
                                        label={t('META_TITLE_KEY')}
                                    />
                                    {error?.name && (
                                        <p className={"text-danger"}>{error?.name}</p>
                                    )}
                                </Col>
                                <Col md={6} xs={12} className={"mt-4"}>
                                    <BaseInput
                                        className="form-control"
                                        id="metaDesc"
                                        type="text"
                                        value={metaDesc}
                                        placeholder={t('META_DESCRIPTION_KEY')}
                                        onChange={(e) => setMetaDesc(e.target.value)}
                                        label={t('META_DESCRIPTION_KEY')}
                                    />
                                </Col>

                            </Row>
                            <Row>
                                <Col md={12} xs={12}>
                                    <div className="text-editor mt-5">
                                        <label
                                            className={`mt-3 text-center d-block mt-3`}>{t('DESCRIPTION_KEY')}</label>
                                        <EditorToolbar/>
                                        <ReactQuill
                                            theme="snow"
                                            // value={description.value}
                                            onChange={handleChange}
                                            placeholder={""}
                                            modules={modules}
                                            formats={formats}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div className={"text-center m-auto mt-3"}>
                                    <DefaultButton
                                        className={"mt-4"}
                                        onClick={() => handleCreatePage()}
                                        loading={loading}
                                        variant="primary"
                                    >
                                        {t('SAVE_KEY')}
                                    </DefaultButton>
                                </div>
                            </Row>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => setModalCentered(false)}
                            variant="danger light"
                            className={"ml-3"}
                        >
                            بستن
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

        </div>
    );
};

export default CreatePage;
