export const dashboardConst = {

    //userInfo
    GET_USER_INFO_REQUEST: "GET_USER_INFO_REQUEST",
    GET_USER_INFO_SUCCESS: "GET_USER_INFO_SUCCESS",
    GET_USER_INFO_FAIL: "GET_USER_INFO_FAIL",

    //order info
    GET_ORDER_INFO_REQUEST: "GET_USER_INFO_REQUEST",
    GET_ORDER_INFO_SUCCESS: "GET_ORDER_INFO_SUCCESS",
    GET_ORDER_INFO_FAIL: "GET_ORDER_INFO_FAIL",


    //order info
    GET_ORDER_STATUS_REQUEST: "GET_USER_INFO_REQUEST",
    GET_ORDER_STATUS_SUCCESS: "GET_ORDER_INFO_SUCCESS",
    GET_ORDER_STATUS_FAIL: "GET_ORDER_INFO_FAIL",

}