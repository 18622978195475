import {Button, Modal} from "react-bootstrap";
import {useState} from "react";

import {useTranslation} from "react-i18next";

const CurrencyModal = ({children, gifts}) => {
    const [modalCentered, setModalCentered] = useState(false);
    const {t} = useTranslation();
    const br = <br/>
    return (
        <div>
            {/* <!-- Button trigger modal --> */}
            <Button
                variant="primary"
                item
                type="button"
                className="d-flex m-auto"
                onClick={() => setModalCentered(true)}
            >
                {children}
            </Button>
            {/* <!-- Modal --> */}
            <Modal
                className="fade "
                bsClass="my-modal"
                size="md"
                show={modalCentered}
                onHide={setModalCentered}
            >
                <Modal.Header>
                    <h4 className="number-black">{t('GIFT_KEY')}</h4>
                    <Button
                        onClick={() => setModalCentered(false)}
                        variant=""
                        className="close"
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    {gifts?.map((item, index) => (
                        <>
                            <div className={"text-left"} key={index}>
                                <span className={"mb-4 rounded-md"}>{++index}</span>
                                <p className={"mt-3"}
                                   dangerouslySetInnerHTML={{__html: item?.code?.code?.replaceAll(",", "<br/>")}}/>
                                <hr/>
                            </div>
                        </>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setModalCentered(false)}
                        variant="danger light"
                        className={"ml-3"}
                    >
                        بستن
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
export default CurrencyModal;
