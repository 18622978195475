import React, { Fragment, useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import { useSelector, useDispatch } from "react-redux"
import { getMasterCardOrderList } from "../../../_actions/order-actions"
import ValidPermissions from "../../../_helper/ValidPermissions";
import { PermissionConst } from "../../../_constants/PermissionConst";
import { useTranslation } from "react-i18next";
import MasterCardTable from "./MasterCardTable";

const MasterCardOrderList = () => {
    const masterCardOrders = useSelector((state) => state.masterCardOrders)
    const { loading, error, masterCards } = masterCardOrders;

    const dispatch = useDispatch();

    let isValid = ValidPermissions(PermissionConst.ORDER_LIST)
    useEffect(() => {
        if (isValid) {
            dispatch(getMasterCardOrderList())
        }
    }, []);
    const { t } = useTranslation();
    return (
        <Fragment>
            <PageTitle activeMenu={t('ORDERS_VIEW_KEY')} pageContent={t('ORDERS_VIEW_KEY')} motherMenu={t('DASHBOARD_KEY')}
                dashUrl={""} prevUrl={""} />
            <div className="row">
                <MasterCardTable loading={loading} error={error} orders={masterCards} />
            </div>
        </Fragment>
    );
};

export default MasterCardOrderList;
