import React, {useState, useEffect} from "react";
import PageTitle from "../../layouts/PageTitle";
import {DefaultButton} from "../Defaults/DefaultButton";
import {BaseInput} from "../Defaults/BaseInput";
import {
    editCouponsAction,
    detailsCouponsAction,
} from "../../../_actions/coupon.action";
import {useSelector, useDispatch} from "react-redux";
import Select from "react-select";
import {DatePicker} from "jalali-react-datepicker";
import "./style.css";
import moment from "moment-jalaali";
import {useParams} from "react-router-dom";
import {DateConvertWithTime} from "../../../_helper/dateFunction";
import {PermissionConst} from "../../../_constants/PermissionConst";
import ValidPermissions from "../../../_helper/ValidPermissions";
import {useTranslation} from "react-i18next";

const EditCoupon = () => {
    const [title, setTitle] = useState("");
    const [code, setCode] = useState("");
    const [status, setStatus] = useState("");
    const [percentage, setPercentage] = useState();

    const [exsiStartDate, setExsitStartDate] = useState(false);
    const [exsiEndtDate, setExsitEndDate] = useState(false);
    const [exsitStatus, setExsitStatus] = useState(false);

    const [startDate, setStartDate] = useState();
    const [endDate, setEnDate] = useState();

    const editCoupons = useSelector((state) => state.editCoupons);
    const {error, loading, success} = editCoupons;

    const detailsCoupons = useSelector((state) => state.detailsCoupons);
    const {details} = detailsCoupons;

    const {id} = useParams();

    let isValid = ValidPermissions(PermissionConst.CURRENCY_SHOW)

    useEffect(() => {
        if (isValid) {
            dispatch(detailsCouponsAction(id));
        }
    }, []);


    useEffect(() => {
        setCode(details?.data?.code);
        setStatus(details?.data?.status);
        setPercentage(details?.data?.percentage);
        setTitle(details?.data?.title);
        setStartDate(details?.data?.start_date);
        setEnDate(details?.data?.end_date);
    }, [details]);

    /// new date
    let dateStart = startDate?.value?._d?.toISOString();
    let dateEnd = endDate?.value?._d?.toISOString();
    let start = moment(dateStart).format("YYYY-M-D HH:mm:ss");
    let end = moment(dateEnd).format("YYYY-M-D HH:mm:ss");

    const handleStartDate = (selected) => {
        setExsitStartDate(true);

        setStartDate(selected);
    };
    const handleEndDate = (selected) => {
        setExsitEndDate(true);
        setEnDate(selected);
    };
    let dir = localStorage.getItem("Layout");
    const statusOptions = [
        {value: "active", label:dir === "rtl" ?  " فعال" : "published"},
        {value: "deactivate", label: dir === "rtl" ?  "غیر فعال" :"Unpublished"},
    ];

    const dispatch = useDispatch();
    const handleCreateCoupon = () => {
        const data = {
            title: title,
            code: code,
            percentage: percentage,
            status: exsitStatus === true ? status.value : status,
            start_date: exsiStartDate === true ? start : startDate,
            end_date: exsiEndtDate === true ? end : endDate,

            _method: "patch",
        };
        dispatch(editCouponsAction(data, id));
    };

    const handleStatusChange = (selected) => {
        setExsitStatus(true);
        setStatus(selected);
    };

    const groupStyles = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    };
    const groupBadgeStyles = {
        backgroundColor: "#EBECF0",
        borderRadius: "2em",
        color: "#000",
        display: "inline-block",
        fontSize: 12,
        fontWeight: "normal",
        lineHeight: "1",
        minWidth: 1,
        padding: "0.16666666666667em 0.5em",
        textAlign: "center",
    };
    const formatGroupLabel = (data) => (
        <div style={groupStyles}>
            <span style={{color: "#000"}}>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );
    const {t} = useTranslation();
    return (
        <>
            <PageTitle
                activeMenu={t('EDIT_KEY')}
                pageContent={t('EDIT_KEY')}
                motherMenu={t('DASHBOARD_KEY')}
                prev={t("COUPON_VIEW_KEY")}
                dashUrl={""}
                prevUrl={"coupon"}
            />

            <div className={"p-4 card"}>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <BaseInput
                                className="form-control"
                                id="title"
                                type="text"
                                value={title}
                                placeholder={t('Title_KEY')}
                                onChange={(e) => setTitle(e.target.value)}
                                label={t('Title_KEY')}
                            />
                            {error?.title && (
                                <span className={"text-danger"}>{error.title}</span>
                            )}
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <BaseInput
                                className="form-control "
                                id="mobile"
                                type="text"
                                value={code}
                                placeholder={t("Code_KEY")}
                                onChange={(e) => setCode(e.target.value)}
                                label={t("Code_KEY")}
                            />
                            {error?.code && (
                                <span className={"text-danger"}>{error.code}</span>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <label>{t('STATUS_KEY')}</label>
                            <Select
                                className="text-dark"
                                formatGroupLabel={formatGroupLabel}
                                placeholder={
                                    status === "deactivate"
                                        ? t('DEACTIVATE_KEY')
                                        : status === "active"
                                            ? t('ACTIVE_KEY')
                                            : "وضعیت را انتخاب کنید ..."
                                }
                                options={statusOptions}
                                onChange={handleStatusChange}
                            />
                            {error?.status && (
                                <p className={"text-danger"}>{error?.status}</p>
                            )}
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <BaseInput
                                className="form-control"
                                id="percentage"
                                type="number"
                                onWheelCapture={e => {
                                    e.target.blur()
                                  }}
                                value={percentage}
                                placeholder={t('COMMISSION_PERCENTAGE')}
                                onChange={(e) => setPercentage(e.target.value)}
                                label={t('COMMISSION_PERCENTAGE')}
                            />
                            {error?.percentage && (
                                <span className={"text-danger"}>{error.percentage}</span>
                            )}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12 col-md-6 ">
                            <DatePicker
                                // value={startDate}
                                onClickSubmitButton={handleStartDate}
                                className={"form-control"}
                                label={t('START_DATE_KEY')}
                            />

                            {error?.start_date && (
                                <span className={"text-danger mt-3"}>{error.start_date}</span>
                            )}
                        </div>
                        <div className="col-xs-12 col-md-6 ">
                            <DatePicker
                                // value={endDate}
                                onClickSubmitButton={handleEndDate}
                                className={"form-control"}
                                label={t("END_DATE_KEY")}
                            />
                            {error?.end_date && (
                                <span className={"text-danger mt-3"}>{error.end_date}</span>
                            )}
                        </div>
                    </div>
                    <p className={"text-warning mt-3"}>
                        <span className={"text-danger"}>{t('Point')} : </span>
                        {t('Point_DES_key')}
                    </p>

                    <div className="d-flex justify-content-center ">
                        <DefaultButton
                            loading={loading}
                            type="submit"
                            onClick={() => handleCreateCoupon()}
                            className={`Approve_btn btn-primary mt-4 d-block`}
                            variant="contained"
                            color="primary"
                        >
                            {t('SAVE_KEY')}
                        </DefaultButton>
                    </div>
                </form>
            </div>
        </>
    );
};

export default EditCoupon;
