import React, {useEffect} from 'react';
import {useParams} from "react-router-dom"
import {getCategoriesDetails} from "../../../_actions/category-actions"
import {useSelector, useDispatch} from "react-redux"
import ProgressBar from 'react-bootstrap/ProgressBar'
import {ERROR} from "../../../_helper";
import {Row, Col} from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import {useTranslation} from "react-i18next";

const CategoryDetails = () => {
    const categoryDetails = useSelector((state) => state.categoryDetails)
    const {category, error, loading} = categoryDetails
    const {id} = useParams()
    const dispatch = useDispatch()
    const {t} = useTranslation();

    let isValid = ValidPermissions(PermissionConst.CATEGORY_SHOW);
    useEffect(() => {
        if (isValid) {
            dispatch(getCategoriesDetails(id))
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (error !== "" && error !== undefined && error !== null)
            ERROR(error?.response?.data?.message)
    }, [error]);

    if (loading) {
        return <ProgressBar animated now={45}/>
    }
    const {photo, name, slug, status} = category
    return (
        <div>
            <PageTitle activeMenu={t('DETAILS_KEY')} pageContent={t('DETAILS_KEY')} motherMenu={t('DASHBOARD_KEY')}
                       prev={t('CATEGORIES_KEY')}
                       prevUrl={`categories`} dashUrl={`dashboard`}/>
            <div className="card p-5">
                <Row>
                    <Col md={6} xs={12}>
                        <img className={'img-fluid'} src={photo} alt={name}/>
                    </Col>
                    <Col md={6} xs={12}>
                        <ul>
                            <li className={'mt-3'}>
                                {t('NAME_KEY')} : {" "}
                                {name}</li>
                            <li className={'mt-3'}>
                                {t('SLUG_KEY')} : {" "}
                                {slug}</li>
                            <li className={'mt-3'}>
                                {t('STATUS_KEY')} : {" "}
                                {status === 0 ? (
                                    <>
                                                        <span className="badge light badge-danger ">
                                                <i className="fa fa-circle text-danger mr-1"/>
                                                            <span className={'mr-2'}>
                                                            غیر فعال
                                                            </span>
                                                </span>
                                    </>
                                ) : status === 1 ? (
                                    <>
                                                        <span className="badge light badge-success ">
                                                          <i className="fa fa-circle text-success mr-1"/>
                                                            <span className={'mr-2'}>
                                                                 فعال
                                                            </span>
                                                           </span>
                                    </>
                                ) : null}
                            </li>
                        </ul>
                    </Col>
                </Row>
            </div>

        </div>
    );
};

export default CategoryDetails;
