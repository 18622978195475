export const currencyConst = {
    GTE_ALL_CURRENCY_REQUEST: "GTE_ALL_CURRENCY_REQUEST",
    GTE_ALL_CURRENCY_SUCCESS: "GTE_ALL_CURRENCY_SUCCESS",
    GTE_ALL_CURRENCY_FAIL: "GTE_ALL_CURRENCY_FAIL",

    UPDATE_CURRENCY_REQUEST: "UPDATE_CURRENCY_REQUEST",
    UPDATE_CURRENCY_SUCCESS: "UPDATE_CURRENCY_SUCCESS",
    UPDATE_CURRENCY_FAIL: "UPDATE_CURRENCY_FAIL",


    DETAILS_CURRENCY_REQUEST: "DETAILS_CURRENCY_REQUEST",
    DETAILS_CURRENCY_SUCCESS: "DETAILS_CURRENCY_SUCCESS",
    DETAILS_CURRENCY_FAIL: "DETAILS_CURRENCY_FAIL",
}