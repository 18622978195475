import React from 'react';
import {useSelector} from "react-redux"
import PageTitle from "../../layouts/PageTitle";
import {useTranslation} from "react-i18next";
import PageTable from "./PageTable";

const Pages = () => {
    const getAllPage = useSelector((state) => state.getAllPage);
    const {t} = useTranslation();
    return (
        <div>
            <PageTitle activeMenu={t('PAGES_KEY')} pageContent={t('PAGES_KEY')} motherMenu={t('DASHBOARD_KEY')}
                       dashUrl={""} prevUrl={""}/>

            <PageTable getAllPage={getAllPage} />
        </div>
    );
};

export default Pages;

