import React, {useState, useEffect} from "react";
import {Dropdown} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {getAllUsers, userActions} from "../../../_actions/user.Action";
import Pagination from "react-js-pagination";
import {FormatDate} from "../../../_helper/dateFunction";
import {ERROR} from "../../../_helper";
import Spinner from "react-bootstrap/Spinner";
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import {useTranslation} from "react-i18next";

const UserTable = ({users}) => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [userTotal, setUserTotal] = useState(1);
    const [usersData, setUsersData] = useState([]);

    const dir = localStorage.getItem("Layout");

    const dispatch = useDispatch();

    let isValid = ValidPermissions(PermissionConst.USER_LIST);

    useEffect(() => {
        if (isValid) {
            dispatch(getAllUsers());
        }
    }, []);
    const {userData, loading} = users;

    const handlePageChange = async (pageNumber) => {
        setPage(pageNumber);
        const page = pageNumber;
        dispatch(userActions.getUserList(page))
            .then((r) => {
                if (r.status === 200) {
                    window.scroll(0, 0);
                    setUsersData(r);
                    setUserTotal(r.data?.meta?.total);
                }
            })
            .catch((ex) =>
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                })
            );
    };
    useEffect(() => {
        if (isValid) {
            dispatch(userActions.getUserList(page))
                .then((r) => {
                    if (r.status === 200) {
                        setUsersData(r);
                        setUserTotal(r.data?.meta?.total);
                    }
                })
                .catch((ex) =>
                    ERROR(ex.response?.data?.message || ex.message, {
                        error: ex,
                    })
                );
        }
    }, [page]);
    const {t} = useTranslation();
    const handelSearch = (e) => {
        e.preventDefault();
        dispatch(userActions.filterUsers(search))
            .then((r) => {
                if (r.status === 200) {
                    console.log(r, "R");
                    setUsersData(r);
                }
            })
            .catch((ex) =>
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                })
            );
    };
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <div
                        className={`d-flex justify-content-between align-items-center w-100`}
                    >
                        {!loading && <h4 className="card-title"> {t('USERS_KEY')}</h4>}
                        {loading && (
                            <div className={"text-center"}>
                                <Spinner animation="grow" variant="success"/>
                                <Spinner animation="grow" variant="danger"/>
                                <Spinner animation="grow" variant="warning"/>
                                <Spinner animation="grow" variant="info"/>
                                <Spinner animation="grow" variant="light"/>
                                <Spinner animation="grow" variant="dark"/>
                            </div>
                        )}
                        <form className={"d-flex"} onSubmit={handelSearch}>
                            <input
                                className={"form-control rounded-0"}
                                type="search"
                                placeholder={t('SEARCH_KEY')}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <button type={"submit"} className={"btn btn-primary rounded-0"}>
                                <i className={"fa fa-search "}/>
                            </button>
                        </form>
                    </div>
                </div>
                <div className="card-body">
                    <div className="w-100 table-responsive">
                        <div id="patientTable_basic_table" className="dataTables_wrapper">
                            <table
                                id="example5"
                                className="display dataTable no-footer w-100  table-striped  table-hover "
                                style={{minWidth: 845}}
                                role="grid"
                                aria-describedby="example5_info"
                            >
                                <thead>
                                <tr role="row">
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient ID: activate to sort column ascending"
                                    >
                                        {t('ID_KEY')}
                                    </th>

                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient Name: activate to sort column ascending"
                                    >
                                        {t('EMAIL_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Disease: activate to sort column ascending"
                                    >
                                        {t('PHONE_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Disease: activate to sort column ascending"
                                    >
                                        {t('DATE_SIGN_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Disease: activate to sort column ascending"
                                    >
                                        {t('BANK_ACCOUNT_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example7"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Disease: activate to sort column ascending"
                                    >
                                        {t('ACTIONS_KEY')}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {usersData?.data?.data?.map((user) => (
                                    <>
                                        <tr key={user.id} role="row" className="odd">
                                            <td className={"text-center"}>{user?.id}</td>
                                            <td className={"text-center"}>
                                                {user?.email && user?.email}
                                            </td>
                                            <td className={"text-center"}>
                                                {user?.mobile ? user?.mobile : "----"}
                                            </td>
                                            <td className={"text-center"}>
                                                {user?.created_at ? user?.created_at : "----"}
                                            </td>
                                            <td className={"text-center"}>
                                                {user?.bankAccount ? user?.bankAccount : "----"}
                                            </td>
                                            <td className={"text-center"}>
                                                <Dropdown className="dropdown ml-auto ">
                                                    <Dropdown.Toggle
                                                        variant=""
                                                        className="btn-link i-false"
                                                        data-toggle="dropdown"
                                                    >
                                                        <svg
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                strokeWidth={1}
                                                                fill="none"
                                                                fillRule="evenodd"
                                                            >
                                                                <rect x={0} y={0} width={24} height={24}/>
                                                                <circle fill="#000000" cx={5} cy={12} r={2}/>
                                                                <circle
                                                                    fill="#000000"
                                                                    cx={12}
                                                                    cy={12}
                                                                    r={2}
                                                                />
                                                                <circle
                                                                    fill="#000000"
                                                                    cx={19}
                                                                    cy={12}
                                                                    r={2}
                                                                />
                                                            </g>
                                                        </svg>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                        {ValidPermissions(PermissionConst.USER_STORE) ? (<>
                                                            <Dropdown.Item
                                                                className={
                                                                    "d-flex align-items-center justify-content-center"
                                                                }
                                                                href={`users/${user.id}`}
                                                            >
                                                                <i className={ dir === "rtl" ? "fa fa-info text-secondary fa-2x ml-3" : "fa fa-info text-secondary fa-2x mr-3"}/>
                                                                {t('DETAILS_KEY')}

                                                            </Dropdown.Item>
                                                        </>) : null}
                                                        {ValidPermissions(PermissionConst.USER_UPDATE) ? (<>
                                                            <Dropdown.Item
                                                                href={`user-edit/${user.id}`}
                                                                className={
                                                                    "d-flex align-items-center justify-content-center"
                                                                }
                                                            >
                                                                <i className={dir === "rtl" ?"fa fa-edit text-warning fa-2x ml-3" : "fa fa-edit text-warning fa-2x mr-3"}/>
                                                                {t('EDIT_KEY')}
                                                            </Dropdown.Item>
                                                        </>) : null}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    </>
                                ))}
                                </tbody>
                            </table>
                            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                                <div className="dataTables_info">{/*   */}</div>
                                <div
                                    className="dataTables_paginate paging_simple_numbers"
                                    id="example5_paginate"
                                >
                                    <div className="d-inline-block pb-3 ltr_direction">
                                        <Pagination
                                            activePage={page}
                                            itemsCountPerPage={usersData?.data?.meta?.per_page}
                                            totalItemsCount={userTotal}
                                            pageRangeDisplayed={20}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default UserTable;
