import {createProductConst, productConstant} from "../_constants/product-constant"
import {_http} from "../_helper/httpServices"
import {SUCCESS, ERROR} from "../_helper/alerts"

//Gte All Product

export const getAllProducts = () => {
    return async (dispatch) => {
        dispatch({type: productConstant.GET_ALL_PRODUCT_REQUEST})
        await _http("admin/products", {method: "get"}).then((res) => {
            if (res.status === 200) {
                dispatch({type: productConstant.GET_ALL_PRODUCT_SUCCESS, payload: res?.data})
            } else {
                dispatch({type: productConstant.GET_ALL_PRODUCT_FAIL, payload: res})
            }
        }).catch((error) => {
            let message;
            if (error?.response?.data?.message) message = error?.response?.data?.message
            ERROR(message)
            dispatch({type: productConstant.GET_ALL_PRODUCT_FAIL, payload: error})
        })
    };
}

////get details product
export const productDetailsActions = (id) => async (dispatch) => {
    dispatch({type: productConstant.DETAILS_PRODUCT_REQUEST, payload: id})

    await _http(`admin/products/${id}`, {method: "get"}).then((res) => {
        if (res.status === 200) {
            dispatch({type: productConstant.DETAILS_PRODUCT_SUCCESS, payload: res?.data})
        } else {
            ERROR(res)
            dispatch({type: productConstant.GET_ALL_PRODUCT_FAIL, payload: res})
        }
    }).catch((error) => {
        let message;
        if (error?.response?.data?.message) message = error?.response?.data?.message
        ERROR(message)
        dispatch({type: productConstant.GET_ALL_PRODUCT_FAIL, payload: error})

    })
}
///create product

export const createNewProduct = (data) => async (dispatch) => {
    dispatch({type: createProductConst.CREATE_PRODUCT_REQUEST, payload: data})
    await _http("admin/products", {method: "post", data}).then((res) => {
        if (res.status === 200) {
            SUCCESS(res?.data?.message);
            window.location.href = "/products"
            dispatch({type: createProductConst.CREATE_PRODUCT_SUCCESS, payload: data})
        } else {
            ERROR(res?.response?.data?.message);
            dispatch({type: createProductConst.CREATE_PRODUCT_FAIL, payload: res.response?.data?.errors})
        }
    }).catch((error) => {
        let message;
        if (error?.response?.data?.message) message = error?.response?.data?.message
        ERROR(message)
        dispatch({type: createProductConst.CREATE_PRODUCT_FAIL, payload: error})
    })
}

///edit product

export const editProductAction = (data, id) => async (dispatch) => {
    dispatch({type: createProductConst.EDIT_PRODUCT_REQUEST, payload: data})
    await _http(`admin/products/${id}`, {method: "post", data, id}).then((res) => {
        if (res.status === 200) {
            SUCCESS(res?.data?.message)
            dispatch({type: createProductConst.EDIT_PRODUCT_SUCCESS, payload: data})
        } else {
            console.log(res?.response?.data,"res")
            ERROR(res?.response?.data?.message)
            dispatch({type: createProductConst.EDIT_PRODUCT_FAIL, payload: res.response?.data?.errors})
        }
    }).catch((error) => {
        console.log(error.response.data.message)
        let message;
        if (error?.response?.data?.message) message = error?.response?.data?.message
        ERROR(message)
        dispatch({type: createProductConst.EDIT_PRODUCT_FAIL, payload: error})
    })
}

//get order List
function getProductList({page,search}) {
    return () => {
        return _http(`admin/products`, {method: "get", params: { search,page }});
    };
}


export const productActions = {
    getProductList,
}


