import {walletConst} from "../_constants/wallet-constant"


//getAll reducer

export const getAllTransactionReducer = (state = {transition: []}, actions) => {
    switch (actions.type) {
        case walletConst.GET_ALL_TRANSACTION_REQUEST :
            return {loading: true}
        case walletConst.GET_ALL_TRANSACTION_SUCCESS :
            return {loading: false, transition: actions.payload}
        case walletConst.GET_ALL_TRANSACTION_FAIL :
            return {loading: false, error: actions.payload}
        default :
            return state

    }
}




export const createTransactionReducer = (state = {}, actions) => {
    switch (actions.type) {
        case walletConst.CREATE_TRANSACTION_REQUEST :
            return {loading: true}
        case walletConst.CREATE_TRANSACTION_SUCCESS :
            return {loading: false, success: true}
        case walletConst.CREATE_TRANSACTION_FAIL :
            return {loading: false, error: actions.payload}
        default :
            return state
    }
}


