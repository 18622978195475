import {Button, Modal, Col} from "react-bootstrap";
import {useState, useEffect} from "react";
import {BaseInput} from "../Defaults/BaseInput";
import {DefaultButton} from "../Defaults/DefaultButton";
import {useDispatch, useSelector} from "react-redux";
import {detailsGiftAction, updateGiftAction,} from "../../../_actions/gift-actions";

const UpdateGiftModal = ({children, isfetch, refresh, setRefresh, codes, setModalCentered, modalCentered}) => {
    const [code, setCode] = useState(codes);
    const detailsGift = useSelector((state) => state.detailsGift);
    const {infoGift} = detailsGift;
    console.log(codes)
    const dispatch = useDispatch();

    useEffect(() => {
        if (modalCentered) {
            dispatch(detailsGiftAction(isfetch));
        }
    }, [modalCentered, isfetch]);


    useEffect(() => {
        setCode(codes);
    }, [codes]);

    const editGift = () => {
        const data = {
            id: isfetch,
            code: code
        }
        dispatch(updateGiftAction(data, isfetch))
        setModalCentered(false);
        setRefresh(!refresh)
    };

    const handleModalOpen = () => {
        setModalCentered(true);
    };
    return (
        <div>
      <span onClick={handleModalOpen}>
        {children}
      </span>
            <Modal
                className="fade "
                bsClass="my-modal"
                size="md"
                show={modalCentered}
                onHide={setModalCentered}
            >
                <Modal.Header>
                    <h4 className="number-black"> نمایش گیفت ها</h4>
                    <Button
                        onClick={() => setModalCentered(false)}
                        variant=""
                        className="close"
                    >
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <BaseInput
                            className="form-control text-right rtl_direction"
                            id="code"
                            type="text"
                            value={code}
                            placeholder="ویراش گیفت محصول"
                            onChange={(e) => setCode(e.target.value)}
                            label={"ویرایش  گیفت محصول"}
                        />
                        <div className={"text-center m-auto mt-3"}>
                            <DefaultButton
                                // disabled={isEmpty}
                                className={"mt-3"}
                                onClick={() => editGift()}
                                variant="primary"
                                type={"submit"}

                            >
                                ویرایش اطلاعات
                            </DefaultButton>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setModalCentered(false)}
                        variant="danger light"
                        className={"ml-3"}
                    >
                        بستن
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
export default UpdateGiftModal;
