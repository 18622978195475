import {Button} from "react-bootstrap";
import {useState, useEffect} from "react";
import {NumberInput} from "../Defaults/NumberInput";
import {DefaultButton} from "../Defaults/DefaultButton";
import {useSelector, useDispatch} from "react-redux";
import {UpdateCurrencyAction, getCurrencyData, DetailsCurrencyAction} from "../../../_actions/currency-actions"
import {BaseInput} from "../Defaults/BaseInput";

import {useParams} from "react-router-dom"
import {useTranslation} from "react-i18next";

const CurrencyEdit = () => {
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [current, setCurrent] = useState(0);
    const [currency, setCurrency] = useState(0);


    const updateCurrency = useSelector((state) => state.updateCurrency)
    const {success, error, loading} = updateCurrency;

    const detailsCurrency = useSelector((state) => state.detailsCurrency)
    const {currencyDetails, error: errorCurrency} = detailsCurrency;

    const dispatch = useDispatch()
    ///get Details

    let {id} = useParams();
    useEffect(() => {
        dispatch(DetailsCurrencyAction(id))
    }, []);

    useEffect(() => {
        setMin(currencyDetails?.min);
        setMax(currencyDetails?.max);
        setCurrency(currencyDetails?.currency);
        setCurrent(currencyDetails?.current);
    }, [currencyDetails]);

    ///Edit Details
    const editCurrency = () => {
        const data = {
            min: min.replaceAll(",", ""),
            max: max.replaceAll(",", ""),
            current: current.replaceAll(",", ""),
        }
        dispatch(UpdateCurrencyAction(data, id))
    }
    const {t} = useTranslation();
    return (
        <>
            <h4 className="number-black">{t('EDIT_KEY')}</h4>
            <div className={"card p-5 mt-4"}>

                <form onSubmit={(e) => e.preventDefault()}>
                    <h5 className="mb-3 ">{t('CURRENCY_KEY')} </h5>
                    <div className="row">
                        {/*<div className=" col-sm-6 col-xs-12">*/}
                        {/*    <NumberInput*/}
                        {/*        label={"کمترین  "}*/}
                        {/*        name="min"*/}
                        {/*        allowLeadingZeros={true}*/}
                        {/*        placeholder="قیمت را به ریال وارد کنید..."*/}
                        {/*        className="form-control"*/}
                        {/*        value={min}*/}
                        {/*        onChange={(e) => setMin(e.target.value)}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        {/*<div className=" col-sm-6 col-xs-12">*/}
                        {/*    <NumberInput*/}
                        {/*        id="max"*/}
                        {/*        label={"بیشترین  "}*/}
                        {/*        name="max"*/}
                        {/*        placeholder="قیمت را به ریال وارد کنید..."*/}
                        {/*        className="form-control"*/}
                        {/*        allowLeadingZeros={true}*/}
                        {/*        value={max}*/}
                        {/*        onChange={(e) => setMax(e.target.value)}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>
                    <div className="row">
                        <div className=" col-sm-6 col-xs-12">
                            <NumberInput
                                htmlFor
                                id="current"
                                label={t('CURRENT_KEY')}
                                placeholder={t("AMOUNT_RIAL_DESCRIPTION_KEY")}
                                name="current"
                                className="form-control"
                                allowLeadingZeros={true}
                                value={current}
                                onChange={(e) => setCurrent(e.target.value)}
                            />
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <BaseInput
                                id="orderFeMax"
                                name="orderFeMax"
                                disabled
                                label={t('CURRENCY_NAME_KEY')}
                                className="form-control"
                                allowLeadingZeros={true}
                                value={currency}
                                onChange={(e) => setCurrency(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <DefaultButton
                            onClick={() => editCurrency()}
                            type="submit"
                            className="d-flex justify-content-center"
                            loading={loading}
                            variant="primary"
                        >
                            {t('SAVE_KEY')}
                        </DefaultButton>
                    </div>
                </form>
            </div>
        </>
    );
};
export default CurrencyEdit;
