import {useEffect} from 'react';
import PageTitle from "../../layouts/PageTitle";
import {useDispatch, useSelector} from "react-redux";
import CategoryTable from "./CategoryTable"
import {getAllCategory} from "../../../_actions/category-actions"
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import {useTranslation} from "react-i18next";

const AllCategories = () => {
    const allCategories = useSelector((state) => state.allCategories);
    const {categories} = allCategories;
    const dispatch = useDispatch()
    let isValid = ValidPermissions(PermissionConst.CATEGORY_LIST);
    useEffect(() => {
        if (isValid) {
            dispatch(getAllCategory())
        }
    }, []);
    const {t} = useTranslation();
    return (
        <>
            <PageTitle dashUrl={""} activeMenu={t('ALL_CATEGORY_KEY')} pageContent={t('ALL_CATEGORY_KEY')}
                       motherMenu={t('DASHBOARD_KEY')}/>
            <div className={"card p-5"}>
                <CategoryTable categoriesData={categories}/>
            </div>
        </>

    )
};
export default AllCategories;
