import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import Pagination from "react-js-pagination";
import {userActions} from "../../../_actions/user.Action"
import {ERROR} from "../../../_helper";
import {useTranslation} from "react-i18next";

const UserTransaction = ({transaction, id}) => {
    const [page, setPage] = useState(1);
    const [userTotal, setUserTotal] = useState(1);
    const [usersData, setUsersData] = useState([]);
    const {t} = useTranslation();

    const dispatch = useDispatch();

    const handlePageChange = async (pageNumber, id) => {
        setPage(pageNumber);
        const page = pageNumber;
        dispatch(userActions.getTransactionList(page, id))
            .then((r) => {
                if (r.status === 200) {
                    window.scroll(0, 0);
                    setUsersData(r);
                    setUserTotal(r.data?.meta?.total);
                }
            })
            .catch((ex) =>
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                })
            );
    };
    useEffect(() => {
        dispatch(userActions.getTransactionList(page, id))
            .then((r) => {
                if (r.status === 200) {
                    setUsersData(r?.data);
                    setUserTotal(r.data?.meta?.total);
                }
            })
            .catch((ex) =>
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                })
            );
    }, [page]);

    return (
        <>
            {usersData?.data?.length === 0 ? (<p className={"text-warning"}>تراکنشی وجود ندارد</p>) : (
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                        </div>
                        <div className="card-body">
                            <div className="w-100 table-responsive">
                                <div id="patientTable_basic_table" className="dataTables_wrapper">
                                    <table
                                        id="example5"
                                        className="display dataTable no-footer w-100  table-striped  table-hover "
                                        style={{minWidth: 845}}
                                        role="grid"
                                        aria-describedby="example5_info"
                                    >
                                        <thead>
                                        <tr role="row">
                                            <th
                                                className="sorting text-center"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Patient ID: activate to sort column ascending"
                                            >
                                                {t('TRANSACTION_KEY')}
                                            </th>

                                            <th
                                                className="sorting text-center"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Patient Name: activate to sort column ascending"
                                            >
                                                {t('AMOUNT_KEY')}
                                            </th>
                                            <th
                                                className="sorting text-center"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Disease: activate to sort column ascending"
                                            >
                                                {t('ACCOUNT_BALANCE')}
                                            </th>
                                            <th
                                                className="sorting text-center"
                                                tabIndex={0}
                                                aria-controls="example5"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Disease: activate to sort column ascending"
                                            >
                                                {t('DATE_TRANSACTION')}
                                            </th>
                                            <th
                                                className="sorting text-center"
                                                tabIndex={0}
                                                aria-controls="example7"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Disease: activate to sort column ascending"
                                            >
                                                {t('TYPE_TRANSACTION')}
                                            </th>
                                            <th
                                                className="sorting text-center"
                                                tabIndex={0}
                                                aria-controls="example7"
                                                rowSpan={1}
                                                colSpan={1}
                                                aria-label="Disease: activate to sort column ascending"
                                            >
                                                {t('DESCRIPTION_KEY')}
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {usersData?.data?.map((user, i) => (
                                            <>
                                                <tr key={user.id} role="row" className="odd">
                                                    <td className={"text-center"}>{i}</td>
                                                    <td className={user?.type === "deposit" ? "text-center text-success" : user?.type === "withdraw" ? "text-center text-danger" : "text-center"}>
                                                        {parseInt(user?.amount)?.toLocaleString()}
                                                    </td>
                                                    <td className={"text-center text-warning"}>
                                                        {parseInt(user?.remaining)?.toLocaleString()}
                                                    </td>
                                                    <td className={"text-center"}>
                                                        {user?.created_at ? user?.created_at : "----"}
                                                    </td>
                                                    <td className={"text-center"}>
                                                        {user?.type === "deposit" ? (
                                                            <>
                                                        <span className="badge light badge-success ">
                                                            <span
                                                                className={'mr-2 fs-13 d-flex align-items-center p-1'}>
                                                                  <i className={"fa fa-plus ml-2"}/>
                                                                {user?.type === "deposit" ? "واریز" : ""}
                                                            </span>
                                                           </span>
                                                            </>
                                                        ) : user?.type === "withdraw" ? (
                                                            <>
                                                    <span className="badge light badge-danger ">
                                                    <span className={'mr-2 d-flex align-items-center p-1 fs-13'}>
                                                         <i className={"fa fa-minus ml-2"}/>
                                                        {user?.type === "withdraw" ? "برداشت" : ""}
                                                    </span>
                                                    </span>
                                                            </>
                                                        ) : null}
                                                    </td>

                                                    <td className={"text-center"}>{user?.meta?.description}</td>
                                                </tr>
                                            </>
                                        ))}
                                        </tbody>
                                    </table>
                                    <div
                                        className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                                        <div className="dataTables_info">{/*   */}</div>
                                        <div
                                            className="dataTables_paginate paging_simple_numbers"
                                            id="example5_paginate"
                                        >
                                            <div className="d-inline-block pb-3 ltr_direction">
                                                <Pagination
                                                    activePage={page}
                                                    itemsCountPerPage={usersData?.meta?.per_page}
                                                    totalItemsCount={userTotal}
                                                    pageRangeDisplayed={20}
                                                    onChange={handlePageChange}
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default UserTransaction;
