/// Menu
import MetisMenu from "metismenujs";
import React, { useState, Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { FaWallet } from "react-icons/fa";
import DecodeToken from "../../../_helper/DecodeToken";
import { BsCreditCard } from 'react-icons/bs';
import { AiOutlineTransaction } from 'react-icons/ai';
import ValidPermissions from "../../../_helper/ValidPermissions";
import { PermissionConst } from "../../../_constants/PermissionConst";
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../../_actions/user.Action"

class MM extends Component {
    componentDidMount() {
        this.$el = this.el;
        this.mm = new MetisMenu(this.$el);
    }

    componentWillUnmount() {
        //  this.mm("dispose");
        // console.log(this.mm);
    }

    render() {
        return (
            <div className="mm-wrapper">
                <ul className="metismenu" ref={(el) => (this.el = el)}>
                    {this.props.children}
                </ul>
            </div>
        );
    }
}

const SideBar = () => {
    const { iconHover, sidebarposition, headerposition, sidebarLayout } =
        useContext(ThemeContext);
    const user = useSelector((state) => state.user.singlUserData)
    const dispatch = useDispatch()

    useEffect(() => {
        var btn = document.querySelector(".nav-control");
        var aaa = document.querySelector("#main-wrapper");
        function toggleFunc() {
            return aaa.classList.toggle("menu-toggle");
        }
        btn.addEventListener("click", toggleFunc);
        dispatch(getUser())

    }, []);

    let scrollPosition = useScrollPosition();
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];
    /// Active menu
    let deshBoard = [
        "",
        "my-wallets",
        "transactions",
        "coin-details",
        "market-capital",
    ],
        app = [
            "app-profile",
            "post-details",
            "app-calender",
            "email-compose",
            "email-inbox",
            "email-read",
            "ecom-product-grid",
            "ecom-product-list",
            "ecom-product-order",
            "ecom-checkout",
            "ecom-invoice",
            "ecom-customers",
            "post-details",
            "ecom-product-detail",
        ],
        bootstrap = [
            "ui-accordion",
            "ui-badge",
            "ui-alert",
            "ui-button",
            "ui-modal",
            "ui-button-group",
            "ui-list-group",
            "ui-media-object",
            "ui-card",
            "ui-carousel",
            "ui-dropdown",
            "ui-popover",
            "ui-progressbar",
            "ui-tab",
            "ui-typography",
            "ui-pagination",
            "ui-grid",
        ],
        plugins = [
            "uc-select2",
            "uc-nestable",
            "uc-sweetalert",
            "uc-toastr",
            "uc-noui-slider",
            "map-jqvmap",
            "uc-lightgallery",
        ],
        widget = ["widget-basic"],
        forms = [
            "form-element",
            "form-wizard",
            "form-editor-summernote",
            "form-pickers",
            "form-validation-jquery",
        ],
        table = ["table-bootstrap-basic", "users-list"],
        pages = [
            "page-register",
            "page-login",
            "page-lock-screen",
            "page-error-400",
            "page-error-403",
            "page-error-404",
            "page-error-500",
            "page-error-503",
        ];

    // const user
    let dir = localStorage.getItem("Layout")
    const { t } = useTranslation();
    return (
        <div
            className={`deznav ${iconHover} ${sidebarposition.value === "fixed" &&
                sidebarLayout.value === "horizontal" &&
                headerposition.value === "static"
                ? scrollPosition > 120
                    ? "fixed"
                    : ""
                : ""
                }`}
        >
            <PerfectScrollbar className="deznav-scroll">
                <div className="main-profile">
                    <img src={localStorage.getItem("avatar")} alt="" />
                    <Link to={"#"}>
                        <i className="fa fa-cog" aria-hidden="true" />
                    </Link>
                    <p className="font-w400">{t('WELCOME_KEY')}</p>
                    {user?.email}
                    <br />
                    <span className="mt-3 d-block">{user?.mobile}</span>
                </div>
                <MM className="metismenu" id="menu">
                    <>

                        <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
                            <Link to="/">
                                <i className="flaticon-144-layout" />
                                <span className="nav-text">{t('DASHBOARD_KEY')}</span>
                            </Link>
                        </li>

                        <li className={`${bootstrap.includes(path) ? "mm-active" : ""}`}>

                            <Link className="has-arrow ai-icon" to="#">
                                <i className="flaticon-003-diamond" />
                                <span className="nav-text">{t('CATEGORIES_KEY')}</span>
                            </Link>
                            <ul>
                                {ValidPermissions(PermissionConst.CATEGORY_STORE) ? (<>
                                    <li>
                                        <Link to="/new-category">
                                            <span className="nav-text">{t('NEW_CATEGORY_KEY')}</span>
                                        </Link>
                                    </li>
                                </>) : null}
                                {ValidPermissions(PermissionConst.CATEGORY_LIST) ? (<>
                                    <li>
                                        <Link to="/categories">
                                            <span className="nav-text">{t('ALL_CATEGORY_KEY')}</span>
                                        </Link>
                                    </li>
                                </>) : null}
                            </ul>
                        </li>
                        <li className={`${bootstrap.includes(path) ? "mm-active" : ""}`}>
                            <Link className="has-arrow ai-icon" to="#">
                                <i className="fa fa-user" />
                                <span className="nav-text"> {t('USERS_KEY')}</span>
                            </Link>
                            <ul>
                                {ValidPermissions(PermissionConst.USER_LIST) ? (<>
                                    <li>
                                        <Link to="/users">
                                            <span className="nav-text">{t("USERS_LIST_KEY")}</span>
                                        </Link>
                                    </li>
                                </>) : null}
                                {ValidPermissions(PermissionConst.USER_STORE) ? (<>
                                    <li>
                                        <Link to="/new-user">
                                            <span className="nav-text">{t("USERS_NEW_KEY")}</span>
                                        </Link>
                                    </li>
                                </>) : null}
                            </ul>
                        </li>
                        <li className={`${plugins.includes(path) ? "mm-active" : ""}`}>
                            <Link className="has-arrow ai-icon" to="#">
                                <i className="fa fa-product-hunt" aria-hidden="true" />
                                <span className="nav-text"> {t('PRODUCT_KEY')}</span>
                            </Link>
                            <ul>
                                {ValidPermissions(PermissionConst.PRODUCT_LIST) ? (<>
                                    <li>
                                        <Link
                                            className={`${path === "role" ? "mm-active" : ""}`}
                                            to="/products"
                                        >
                                            {t('ALL_PRODUCT_KEY')}
                                        </Link>
                                    </li>
                                </>) : null}
                                {ValidPermissions(PermissionConst.PRODUCT_STORE) ? (<>
                                    <li>
                                        <Link
                                            className={`${path === "" ? "mm-active" : ""}`}
                                            to="/new-products"
                                        >
                                            {t('PRODUCT_NEW_KEY')}
                                        </Link>
                                    </li>
                                </>) : null}
                            </ul>
                        </li>
                        <li className={`${plugins.includes(path) ? "mm-active" : ""}`}>
                            <Link className="has-arrow ai-icon" to="#">
                                <i className="fa fa-first-order" aria-hidden="true" />
                                <span className="nav-text">{t('ORDERS_KEY')}</span>
                            </Link>
                            <ul>
                                {ValidPermissions(PermissionConst.COUPON_LIST) ? (<>
                                    <li>
                                        <Link
                                            className={`${path === "" ? "mm-active" : ""}`}
                                            to="/orders"
                                        >
                                            {t('ORDERS_VIEW_KEY')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className={`${path === "" ? "mm-active" : ""}`}
                                            to="/master-cards"
                                        >
                                            سفارشات مستر کارت
                                        </Link>
                                    </li>
                                </>) : null}
                            </ul>
                        </li>
                        <li className={`${plugins.includes(path) ? "mm-active" : ""}`}>
                            <Link className="has-arrow ai-icon" to="#">
                                <AiOutlineTransaction
                                    className={"wallet_icons"}
                                    style={dir === "rtl" ? {
                                        color: "#969ba0",
                                        fontSize: " 19px",
                                        stroke: "#969ba0",
                                        margin: "0 0 0 26px",
                                    } : dir === "ltr" ? {
                                        color: "#969ba0",
                                        fontSize: " 19px",
                                        stroke: "#969ba0",
                                        margin: "0 26px 0 0",
                                    } : {
                                        color: "#969ba0",
                                        fontSize: " 19px",
                                        stroke: "#969ba0",
                                        margin: "0 0 0 26px",
                                    }}
                                />
                                <span className="nav-text">{t("TRANSACTION_KEY")}</span>
                            </Link>
                            <ul>
                                {ValidPermissions(PermissionConst.USER_SEE_ALL_TRANSACTIONS) ? (<>
                                    <li>
                                        <Link
                                            className={`${path === "" ? "mm-active" : ""}`}
                                            to="/transactions"
                                        >
                                            {t('TRANSACTIONS_KEY')}
                                        </Link>
                                    </li>
                                </>) : null}
                            </ul>
                        </li>
                        <li className={`${plugins.includes(path) ? "mm-active" : ""}`}>
                            <Link className="has-arrow ai-icon" to="#">
                                <i className="fa fa-comments" aria-hidden="true" />
                                <span className="nav-text">{t("COMMENT_KEY")}</span>
                            </Link>
                            <ul>
                                {ValidPermissions(PermissionConst.REVIEW_LIST) ? (<>
                                    <li>
                                        <Link
                                            className={`${path === "" ? "mm-active" : ""}`}
                                            to="/reviews"
                                        >
                                            {t("COMMENT_VIEW_KEY")}
                                        </Link>
                                    </li>
                                </>) : null}
                            </ul>
                        </li>
                        <li className={`${plugins.includes(path) ? "mm-active" : ""}`}>
                            <Link className="has-arrow ai-icon" to="Currency">
                                <i className="fa fa-usd" aria-hidden="true" />
                                <span className="nav-text">{t("CURRENCY_KEY")}</span>
                            </Link>
                            <ul>
                                {ValidPermissions(PermissionConst.CURRENCY_LIST) ? (<>
                                    <li>
                                        <Link
                                            className={`${path === "" ? "mm-active" : ""}`}
                                            to="/Currency"
                                        >
                                            {t('CURRENCY_LIST')}
                                        </Link>
                                    </li>
                                </>) : null}
                            </ul>
                        </li>
                        <li className={`${plugins.includes(path) ? "mm-active" : ""}`}>
                            <Link className="has-arrow ai-icon" to="Currency">
                                <BsCreditCard className={"wallet_icons"}
                                    style={dir === "rtl" ? {
                                        color: "#969ba0",
                                        fontSize: " 19px",
                                        margin: "0 0 0 26px",
                                        background: "transparent"
                                    } : dir === "ltr" ? {
                                        color: "#969ba0",
                                        fontSize: " 19px",
                                        margin: "0 26px 0 0",
                                        background: "transparent"
                                    } : {
                                        color: "#969ba0",
                                        fontSize: " 19px",
                                        margin: "0 0 0 26px",
                                        background: "transparent"
                                    }} />
                                <span className="nav-text">{t('COUPON_KEY')}</span>
                            </Link>
                            <ul>
                                {ValidPermissions(PermissionConst.COUPON_LIST) ? (<>
                                    <li>
                                        <Link
                                            className={`${path === "" ? "mm-active" : ""}`}
                                            to="/coupon"
                                        >
                                            {t('COUPON_VIEW_KEY')}
                                        </Link>
                                    </li>
                                </>) : null}
                            </ul>
                        </li>

                        <li className={`${plugins.includes(path) ? "mm-active" : ""}`}>
                            <Link className="has-arrow ai-icon" to="Currency">
                                <i className="fa fa-file" aria-hidden="true" />

                                <span className="nav-text">{t('PAGES_KEY')}</span>
                            </Link>
                            <ul>
                                <li>
                                    <Link
                                        className={`${path === "" ? "mm-active" : ""}`}
                                        to="/pages"
                                    >
                                        {t('PAGES_KEY')}
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </>
                    {/* )} */}
                </MM>
            </PerfectScrollbar>
        </div>
    );
};

export default SideBar;
