import {_http} from "../_helper/httpServices";
import {SUCCESS, ERROR, CONFIRM} from "../_helper/alerts";
import {
    categoryConstant,
    categoriesDetail,
    categoriesDelete,
    categoriesEdit,
} from "../_constants/category.constant";
//craete category

export const createCategory = (data) => async (dispatch) => {
    dispatch({type: categoryConstant.NEW_CATEGORY_REQUEST, payload: data});
    await _http("admin/categories", {method: "post", data})
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: categoryConstant.NEW_CATEGORY_SUCCESS,
                    payload: data,
                });
                SUCCESS(res.message || "دسته بندی جدید اضافه شد");
            } else {
                dispatch({
                    type: categoryConstant.NEW_CATEGORY_FAIL,
                    payload: res?.response?.data?.errors,
                });
                ERROR(res.response?.data?.message);
            }
        })
        .catch((error) => {
            let message;
            if (error?.response?.data?.message)
                message = error?.response?.data?.message;
            else message = error?.message;
            ERROR(message);
            dispatch({
                type: categoryConstant.NEW_CATEGORY_FAIL,
                payload: error?.response?.data?.errors,
            });
        });
};

//get category details
export const getCategoriesDetails = (id) => async (dispatch) => {
    dispatch({
        type: categoriesDetail.GET_DETAILS_CATEGORY_REQUEST,
        payload: id,
    });
    await _http(`admin/categories/${id}`, "get")
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: categoriesDetail.GET_DETAILS_CATEGORY_SUCCESS,
                    payload: res.data?.data,
                });
            } else {
                ERROR(res?.response?.data?.message);
                dispatch({
                    type: categoriesDetail.GET_DETAILS_CATEGORY_FAIL,
                    payload: res.response.error,
                });
            }
        })
        .catch((error) => {
            let message;
            if (error?.response?.data?.message)
                message = error?.response?.data?.message;
            ERROR(message);
            dispatch({
                type: categoriesDetail.GET_DETAILS_CATEGORY_FAIL,
                payload: error,
            });
        });
};

///get all category

export const getAllCategory = () => async (dispatch) => {
    dispatch({type: categoryConstant.GET_ALL_CATEGORY_REQUEST});
    await _http("admin/categories", {method: "get"})
        .then((res) => {
            if (res.status === 200) {
                console.log(res);
                dispatch({
                    type: categoryConstant.GET_ALL_CATEGORY_SUCCESS,
                    payload: res?.data,
                });
            } else {
                ERROR(res?.data?.message);
            }
        })
        .catch((error) => {
            let message;
            if (error?.response?.data?.message)
                message = error?.response?.data?.message;
            ERROR(message);
            dispatch({
                type: categoryConstant.GET_ALL_CATEGORY_FAIL,
                payload: error,
            });
        });
};

/// delete category
export const deleteCategories = (id) => async (dispatch) => {
    dispatch({type: categoriesDelete.DELETE_CATEGORY_REQUEST, payload: id});
    await _http(`admin/categories/${id}`, {method: "Delete"})
        .then((res) => {
            if (res.status === 200) {
                SUCCESS("عملیات موفقیت آمیز بود");
                window.location.href = "/categories"
            } else {
                ERROR(res.response.data.message);
                dispatch({type: categoriesDelete.DELETE_CATEGORY_FAIL, payload: res});
            }
        })
        .catch((error) => {
            let message;
            if (error?.response?.data?.message)
                message = error?.response?.data?.message;
            ERROR(message);
            dispatch({type: categoriesDelete.DELETE_CATEGORY_FAIL, payload: error});
        });
};
//update category

export const editCategories = (data, id) => async (dispatch) => {
    dispatch({type: categoriesEdit.EDIT_CATEGORY_REQUEST, payload: data, id});
    await _http(`admin/categories/${id}`, {method: "post", data, id})
        .then((res) => {
            if (res.status === 200) {
                SUCCESS("ویرایش با موفقیت انجام شد");
                window.location.href = "/categories";
                dispatch({type: categoriesEdit.EDIT_CATEGORY_SUCCESS, payload: res});
            } else {
                ERROR(res?.response?.data?.message);
                console.log(res, "res")
                dispatch({
                    type: categoriesEdit.EDIT_CATEGORY_FAIL,
                    payload: res?.response?.data?.errors,
                });
            }
        })
        .catch((error) => {
            let message;
            if (error?.response?.data?.message)
                message = error?.response?.data?.message;
            else message = error?.message;
            ERROR(error?.response?.data?.message);
            dispatch({
                type: categoryConstant.NEW_CATEGORY_FAIL,
                payload: error?.response?.data?.errors,
            });
        });
};

//get order List
function getCategoryList(page) {
    return () => {
        return _http("get", `admin/categories?page=${page}`);
    };
}

export const categoriesActions = {
    getCategoryList,
};
