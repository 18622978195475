import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "../_reducers";
import { composeWithDevTools } from "redux-devtools-extension";
const loggerMiddleware = createLogger();

const initialState = {
  auth: {
    userInfo: localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : null,
  },
};

export const store = createStore(
  rootReducer,
  initialState,
  // composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware))
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);
