import React, {Fragment, useEffect} from "react";
import PageTitle from "../../layouts/PageTitle";
import OrderTable from "./OrderTable";
import {useSelector, useDispatch} from "react-redux"
import {getOrderList} from "../../../_actions/order-actions"
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import {useTranslation} from "react-i18next";

const OrderList = () => {
    const allOrders = useSelector((state) => state.allOrders)
    const {loading, error, orders} = allOrders;

    const dispatch = useDispatch();

    let isValid = ValidPermissions(PermissionConst.ORDER_LIST)
    useEffect(() => {
        if (isValid) {
            dispatch(getOrderList())
        }
    }, []);
    const {t} = useTranslation();
    return (
        <Fragment>
            <PageTitle activeMenu={t('ORDERS_VIEW_KEY')} pageContent={t('ORDERS_VIEW_KEY')} motherMenu={t('DASHBOARD_KEY')}
                     dashUrl={""}  prevUrl={""}/>
            <div className="row">
                <OrderTable loading={loading} error={error} orders={orders}/>
            </div>
        </Fragment>
    );
};

export default OrderList;
