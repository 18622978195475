export const userConstant = {
    GET_ALL_USERS_REQUEST: "GET_ALL_USERS_REQUEST",
    GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
    GET_ALL_USERS_FAIL: "GET_ALL_USERS_FAIL",

    GET_DETAILS_USERS_REQUEST: "GET_DETAILS_USERS_REQUEST",
    GET_DETAILS_USERS_SUCCESS: "GET_DETAILS_USERS_SUCCESS",
    GET_DETAILS_USERS_FAIL: "GET_DETAILS_USERS_FAIL",

    CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
    CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
    CREATE_USER_FAIL: "CREATE_USER_FAIL",

}
export const EditConstant = {
    EDIT_USER_REQUEST: "EDIT_USER_REQUEST",
    EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
    EDIT_USER_FAIL: "EDIT_USER_FAIL",

}
export const singleUserConstant = {
    GET_USER_REQUEST: "GET_USER_REQUEST",
    GET_USER_SUCCESS: "GET_USER_SUCCESS",
    GET_USER_FAIL: "GET_USER_FAIL",

}
export const permissionConstant = {
    PERMISSION_USER_REQUEST: "PERMISSION_USER_REQUEST",
    PERMISSION_USER_SUCCESS: "PERMISSION_USER_SUCCESS",
    PERMISSION_USER_FAIL: "PERMISSION_USER_FAIL",
}

export const userWallet = {
    GET_USER_TRANSACTION_REQUEST: "GET_USER_TRANSACTION_REQUEST",
    GET_USER_TRANSACTION_SUCCESS: "GET_USER_TRANSACTION_SUCCESS",
    GET_USER_TRANSACTION_FAIL: "GET_USER_TRANSACTION_FAIL",

    GET_USER_CREDIT_REQUEST: "GET_USER_CREDIT_REQUEST",
    GET_USER_CREDIT_SUCCESS: "GET_USER_CREDIT_SUCCESS",
    GET_USER_CREDIT_FAIL: "GET_USER_CREDIT_FAIL",
}