import {
  authConstants,
  verifyConst,
  EmailConstants,
} from "../_constants/auth.constants";
import axios from "axios";
import { ERROR, SUCCESS } from "./../_helper/alerts";
import { _http } from "../_helper/httpServices";
import { getError } from "../_helper/getError";
import i18n from "i18next";

const ENDPOINT = process.env.REACT_APP_BASE_URL;
//user auth
export const userAuth = (data) => async (dispatch) => {
  dispatch({ type: authConstants.REQUEST_OTP, payload: data });
  await axios
    .post(ENDPOINT + "loginOtp", data,{headers:{'Accept-Language': i18n.language,
  }})
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: authConstants.SUCCESS_OTP, payload: data });
        SUCCESS("یک پیام حاوی رمز عبور برای شما ارسال شد");
      } else {
        ERROR("اطلاعات وارد شده اشتباه می باشد");
      }
    })
    .catch((error) => {
      getError(error);
      dispatch({
        type: authConstants.FAIL_OTP,
        payload: error?.response?.data?.errors,
      });
    });
};
export const VerifyUserAuth = (data) => async (dispatch) => {
  dispatch({ type: authConstants.REQUEST_OTP, payload: data });
  await axios
    .post(ENDPOINT + "loginOtp", data,{headers:{'Accept-Language': i18n.language,
  }})
    .then((res) => {
      if (res.status === 200) {
        localStorage.setItem(
          "token",
          JSON.stringify(res?.data?.data?.access_token)
        );
        window.location.href = "/";
        SUCCESS("ورود موفقیت آمیز بود");
      } else {
        ERROR("اطلاعات وارد شده اشتباه می باشد");
      }
    })
    .catch((error) => {
      let message;
      if (error?.response?.data?.message)
        message = error?.response?.data?.message;
      else message = error?.message;
      ERROR(message);
      dispatch({
        type: authConstants.FAIL_OTP,
        payload: error?.response?.data?.errors,
      });
    });
};

//user auth
export const EmailAuth = (data) => async (dispatch) => {
  dispatch({ type: EmailConstants.REQUEST_EMAIL, payload: data });
  await axios
    .post(ENDPOINT + "login", data,{headers:{'Accept-Language': i18n.language,
  }})
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: EmailConstants.SUCCESS_EMAIL, payload: data });
        SUCCESS("یک ایمیل حاوی رمز عبور برای شما ارسال شد");
      } else {
        ERROR("اطلاعات وارد شده اشتباه می باشد");
      }
    })
    .catch((error) => {
      let message;
      if (error?.response?.data?.message)
        message = error?.response?.data?.message;
      else message = error?.message;
      ERROR(message);
      dispatch({
        type: EmailConstants.FAIL_EMAIL,
        payload: error?.response?.data?.errors,
      });
    });
};
export const VerifyEmailAuth = (data) => async (dispatch) => {
  dispatch({ type: EmailConstants.REQUEST_EMAIL, payload: data });
  await axios
    .post(ENDPOINT + "login", data,{headers:{'Accept-Language': i18n.language,
  }})
    .then((res) => {
      if (res.status === 200) {
        localStorage.setItem(
          "token",
          JSON.stringify(res?.data?.data?.access_token)
        );
        SUCCESS("ورود با موفقیت انجام شد");
        window.location.href = "/";
      } else {
        ERROR("اطلاعات وارد شده اشتباه می باشد");
      }
    })
    .catch((error) => {
      let message;
      if (error?.response?.data?.message)
        message = error?.response?.data?.message;
      else message = error?.message;
      ERROR(message);
      dispatch({
        type: EmailConstants.FAIL_EMAIL,
        payload: error?.response?.data?.errors,
      });
    });
};

/// user Log out logout
export function logoutActions() {
  return (dispatch) => {
    dispatch(request());
    return _http("logout", { method: "get" })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: authConstants.LOGOUT,
            data: res.data,
          });
          localStorage.removeItem("token");
          window.location.href = "/page-login";
        }
      })
      .catch((e) => {
        if (e?.response?.data) ERROR(e?.response?.data?.message);
        // else ERROR(e?.message);
      });

    function request(res) {
      return { type: authConstants.LOGOUT, res };
    }
  };
}
