import React, {useEffect, useState} from 'react';
import Spinner from "react-bootstrap/Spinner";
import {Dropdown} from "react-bootstrap";
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import Pagination from "react-js-pagination";
import {PageAction, deletePageAction, getAllPageAction} from "../../../_actions/page.actions";
import {ERROR} from "../../../_helper";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import CreatePage from "./CreatePage";

const PageTable = ({getAllPage}) => {
    const [page, setPage] = useState(1);
    const [pageTotal, setPageTotal] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [modalCentered, setModalCentered] = useState(false);

    const dispatch = useDispatch();
    const {loading, error, pages} = getAllPage;
    const history = useHistory()

    const handlePageChange = async (pageNumber) => {
        setPage(pageNumber);
        const page = pageNumber;
        dispatch(PageAction.getPageList(page))
            .then((r) => {
                if (r.status === 200) {
                    window.scroll(0, 0);
                    setPageData(r);
                    setPageTotal(r.data?.meta?.total);
                }
            })
            .catch((ex) =>
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                })
            );
    };
    useEffect(() => {
        dispatch(PageAction.getPageList(page))
            .then((r) => {
                if (r.status === 200) {
                    setPageData(r);
                    setPageTotal(r.data?.meta?.total);
                }
            })
            .catch((ex) =>
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                })
            );
    }, [page, refresh]);


    const {t} = useTranslation();

    const deletePage = (id) => {
        dispatch(deletePageAction(id))
        setRefresh(!refresh)
    }
    let dir = localStorage.getItem("Layout");


    return (
        <div>
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <div
                            className={`d-flex justify-content-between align-items-center w-100`}
                        >
                            {!loading && <h4 className="card-title"> {t('PAGES_KEY')}</h4>}
                            {loading && (
                                <div className={"text-center"}>
                                    <Spinner animation="grow" variant="success"/>
                                    <Spinner animation="grow" variant="danger"/>
                                    <Spinner animation="grow" variant="warning"/>
                                    <Spinner animation="grow" variant="info"/>
                                    <Spinner animation="grow" variant="light"/>
                                    <Spinner animation="grow" variant="dark"/>
                                </div>
                            )}
                            <div className={"d-flex align-items-center cursor-pointer"}
                                 onClick={() => setModalCentered(true)}
                            >
                                <i className={dir === "rtl" ? "fa fa-plus  text-warning fa-2x  ml-2" : "fa fa-plus  text-warning fa-2x  mr-2"}/>
                                {t('NEW_KEY')}
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="w-100 table-responsive">
                            <div id="patientTable_basic_table" className="dataTables_wrapper">
                                <table
                                    id="example5"
                                    className="display dataTable no-footer w-100  table-striped  table-hover "
                                    style={{minWidth: 845}}
                                    role="grid"
                                    aria-describedby="example5_info"
                                >
                                    <thead>
                                    <tr role="row">
                                        <th
                                            className="sorting text-center"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Patient ID: activate to sort column ascending"
                                        >
                                            {t('Title_KEY')}
                                        </th>

                                        <th
                                            className="sorting text-center"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Patient Name: activate to sort column ascending"
                                        >
                                            {t('META_TITLE_KEY')}
                                        </th>
                                        <th
                                            className="sorting text-center"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Disease: activate to sort column ascending"
                                        >
                                            {t('META_DESCRIPTION_KEY')}
                                        </th>
                                        <th
                                            className="sorting text-center"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Disease: activate to sort column ascending"
                                        >
                                            {t('SLUG_KEY')}
                                        </th>
                                        <th
                                            className="sorting text-center"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Disease: activate to sort column ascending"
                                        >
                                            {t('STATUS_KEY')}
                                        </th>
                                        <th
                                            className="sorting text-center"
                                            tabIndex={0}
                                            aria-controls="example7"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Disease: activate to sort column ascending"
                                        >
                                            {t('DATE_CREATE_KEY')}
                                        </th>
                                        <th
                                            className="sorting text-center"
                                            tabIndex={0}
                                            aria-controls="example7"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Disease: activate to sort column ascending"
                                        >
                                            {t('UPDATE_KEY')}
                                        </th>
                                        <th
                                            className="sorting text-center"
                                            tabIndex={0}
                                            aria-controls="example7"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Disease: activate to sort column ascending"
                                        >
                                            {t('EMAIL_KEY')}
                                        </th>
                                        <th
                                            className="sorting text-center"
                                            tabIndex={0}
                                            aria-controls="example7"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Disease: activate to sort column ascending"
                                        >
                                            {t('MENU_STATUS_KEY')}
                                        </th>
                                        <th
                                            className="sorting text-center"
                                            tabIndex={0}
                                            aria-controls="example7"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Disease: activate to sort column ascending"
                                        >
                                            {t('ACTIONS_KEY')}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {pageData?.data?.data?.map((user) => (
                                        <>
                                            <tr key={user.id} role="row" className="odd">
                                                <td className={"text-center"}>{user?.title}</td>
                                                <td className={"text-center"}>
                                                    {user.meta_title}
                                                </td>
                                                <td className={"text-center"}>
                                                    {user.meta_desc}
                                                </td>
                                                <td className={"text-center"}>
                                                    {user?.slug}
                                                </td>
                                                <td className={"text-center"}>
                                                    {user?.status === 1 ? (
                                                        <span className="badge light badge-success ">
                                                          <i className="fa fa-circle text-success mr-1"/>
                                                            <span className={'mr-2'}>
                                                                {t('ACTIVE_KEY')}
                                                            </span>
                                                           </span>
                                                    ) : user?.status === 0 ? (
                                                        <span className="badge light badge-danger ">
                                                          <i className="fa fa-circle text-danger mr-1"/>
                                                            <span className={'mr-2'}>
                                                                {t('DEACTIVATE_KEY')}
                                                            </span>
                                                        </span>
                                                    ) : user?.status}
                                                </td>
                                                <td className={"text-center"}>
                                                    {user?.created_at}
                                                </td>
                                                <td className={"text-center"}>
                                                    {user?.updated_at}
                                                </td>
                                                <td className={"text-center"}>
                                                    {user?.user?.email}
                                                </td>
                                                <td className={"text-center"}>
                                                    {user?.menu_status === 1 ? (
                                                        <span className="badge light badge-success ">
                                                    <i className="fa fa-circle text-success mr-1"/>
                                                    <span className={'mr-2'}>
                                                {t('ACTIVE_KEY')}
                                                    </span>
                                                    </span>

                                                    ) : user?.menu_status === 0 ? (
                                                        <span className="badge light badge-danger">
                                                    <i className="fa fa-circle text-danger mr-1"/>
                                                    <span className={'mr-2'}>
                                                {t('DEACTIVATE_KEY')}
                                                    </span>
                                                    </span>
                                                    ) : user?.menu_status}
                                                </td>
                                                <td className={"text-center"}>
                                                    <Dropdown className="dropdown ml-auto ">
                                                        <Dropdown.Toggle
                                                            variant=""
                                                            className="btn-link i-false"
                                                            data-toggle="dropdown"
                                                        >
                                                            <svg
                                                                width="24px"
                                                                height="24px"
                                                                viewBox="0 0 24 24"
                                                                version="1.1"
                                                            >
                                                                <g
                                                                    stroke="none"
                                                                    strokeWidth={1}
                                                                    fill="none"
                                                                    fillRule="evenodd"
                                                                >
                                                                    <rect x={0} y={0} width={24} height={24}/>
                                                                    <circle fill="#000000" cx={5} cy={12} r={2}/>
                                                                    <circle
                                                                        fill="#000000"
                                                                        cx={12}
                                                                        cy={12}
                                                                        r={2}
                                                                    />
                                                                    <circle
                                                                        fill="#000000"
                                                                        cx={19}
                                                                        cy={12}
                                                                        r={2}
                                                                    />
                                                                </g>
                                                            </svg>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                            <Dropdown.Item
                                                                className={
                                                                    "d-flex align-items-center justify-content-center"
                                                                }
                                                                onClick={() => deletePage(user.id)}
                                                            >
                                                                <i className={dir === "rtl" ? "fa fa-trash text-danger fa-2x ml-3" : "fa fa-trash text-danger fa-2x mr-3"}/>
                                                                {t('DELETE_KEY')}

                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                href={`edit-page/${user.id}`}
                                                                className={
                                                                    "d-flex align-items-center justify-content-center"
                                                                }
                                                            >
                                                                <i className={dir === "rtl" ? "fa fa-edit text-warning fa-2x ml-3" : "fa fa-edit text-warning fa-2x mr-3"}/>
                                                                {t('EDIT_KEY')}
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                                    </tbody>
                                </table>
                                <div
                                    className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                                    <div className="dataTables_info">{/*   */}</div>
                                    <div
                                        className="dataTables_paginate paging_simple_numbers"
                                        id="example5_paginate"
                                    >
                                        <div className="d-inline-block pb-3 ltr_direction">
                                            <Pagination
                                                activePage={page}
                                                itemsCountPerPage={pageData?.data?.meta?.per_page}
                                                totalItemsCount={pageTotal}
                                                pageRangeDisplayed={20}
                                                onChange={handlePageChange}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CreatePage modalCentered={modalCentered} setModalCentered={setModalCentered}/>

        </div>
    )

};

export default PageTable;
