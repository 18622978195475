import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Row, Col} from "react-bootstrap";
import {createCategory} from "../../../_actions/category-actions";
import {BaseInput} from "../../components/Defaults/BaseInput";
import {DefaultButton} from "../Defaults/DefaultButton";
import FileUploader from "../../../_helper/FileUploader";
import PageTitle from "../../layouts/PageTitle";
import {useTranslation} from "react-i18next";

const CreateCategory = () => {
    const [slug, setSlug] = useState("");
    const [name, setName] = useState("");
    const [metaTitle, setMetaTitle] = useState("");
    const [metaDesc, setMetaDesc] = useState("");
    const [status, setStatus] = useState(0);
    const [sort, setSort] = useState(0);
    const [files, setFiles] = useState([]);
    const newCategory = useSelector((state) => state.newCategory)
    const {loading, error, success} = newCategory
    const dispatch = useDispatch()
    const file = new FormData();
    const createbtn = () => {
        file.append("photo", files?.[0]);
        file.append("name", name);
        file.append("slug", slug);
        file.append("meta_title", metaTitle);
        file.append("meta_desc", metaDesc);
        file.append("status", status);
        file.append("sort", sort);
        dispatch(createCategory(file))
    }
    const isEmpty = slug === "" || name === "" || metaTitle === "" || metaDesc === "" || status === "" || sort === "";
    const {t} = useTranslation();
    let dir = localStorage.getItem("Layout");
    return (
        <>
            <PageTitle dashUrl={""} activeMenu={t('CREATE_CATEGORY_KEY')} pageContent={t('CREATE_CATEGORY_KEY')}
                       motherMenu={t('DASHBOARD_KEY')}/>

            <div className={'card p-5 '}>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Row>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className={dir === "rtl" ? `form-control ` : dir === "ltr" ? "form-control " : "form-control "}
                                id="name"
                                type="text"
                                value={name}
                                placeholder={t('NAME_PRODUCT_KEY')}
                                onChange={(e) => setName(e.target.value)}
                                label={t('NAME_KEY')}
                            />
                        </Col>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className={dir === "rtl" ? `form-control ` : dir === "ltr" ? "form-control " : "form-control "}
                                id="slug"
                                type="text"
                                value={slug}
                                placeholder={t('SLUG_KEY')}
                                onChange={(e) => setSlug(e.target.value)}
                                label={t('SLUG_KEY')}
                            />
                            {error && (<p className={"text-danger mb-2 mt-2"}>{error?.slug}</p>)}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className={dir === "rtl" ? `form-control t` : dir === "ltr" ? "form-control " : "form-control "}
                                id="metaTitle"
                                type="text"
                                value={metaTitle}
                                placeholder={t('META_TITLE_KEY')}
                                onChange={(e) => setMetaTitle(e.target.value)}
                                label={t('META_TITLE_KEY')}
                            />
                        </Col>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className={dir === "rtl" ? `form-control ` : dir === "ltr" ? "form-control " : "form-control "}
                                id="metaDesc"
                                type="text"
                                value={metaDesc}
                                placeholder={t('META_DESCRIPTION_KEY')}
                                onChange={(e) => setMetaDesc(e.target.value)}
                                label={t('META_DESCRIPTION_KEY')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <select style={{height: "40px", color: "#6e6e6e", borderRadius: "11px"}}
                                    className={"mt-4  w-100 bg-transparent"}
                                    onChange={(e) => setStatus(e.target.value)}>
                                <option value="">{t('STATUS_KEY')}</option>
                                <option value="1">{t('PUBLISH_KEY')}</option>
                                <option value="0">{t('UNPUBLISH_KEY')}</option>
                            </select>
                            {error?.status && (
                                <p className={"text-danger"}>{error?.status}</p>
                            )}
                        </Col>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className={dir === "rtl" ? `form-control` : dir === "ltr" ? "form-control " : "form-control"}
                                id="sort"
                                type="number"
                                value={sort}
                                placeholder="مرتب سازی دسته بندی "
                                onChange={(e) => setSort(e.target.value)}
                                label={t('Ordering_KEY')}
                                error={error?.sort}
                            />
                            {error?.sort && (
                                <p className={"text-danger"}>{error?.sort}</p>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <div className={"cursor-pointer position-relative w-100 "}>
                                <FileUploader
                                    src={files}
                                    onFileUpload={setFiles}
                                    uploadMultiple={false}
                                    showPreview={true}
                                    title={t("PHOTO_SELECTION_KEY")}
                                    description={t("NEED_CHANGE_IMAGE_KEY")}
                                />
                                {error?.photo && (
                                    <p className={"text-danger"}>{error?.photo || "مقدار sort باید به صورت عددی وارد شود"}</p>
                                )}
                            </div>
                        </Col>
                        <Col md={6} xs={12}>
                            <h4>{t("CONDITIONS_UPLOAD_KEY")}</h4>
                            <ul>
                                <li className={"mt-3"}>{t('IMAGE_SIZE_UPLOAD_KEY')}</li>
                                <li className={"mt-2"}>{t('PHOTO_DIMENSIONS_UPLOAD_KEY')}</li>
                            </ul>
                        </Col>

                    </Row>
                    <Row>
                        <div className={"text-center m-auto mt-3"}>
                            <DefaultButton
                                disabled={isEmpty}
                                type="submit"
                                onClick={() => createbtn()}
                                loading={loading}
                                variant="primary"
                            >
                                {t("CREATE_CATEGORY_KEY")}
                            </DefaultButton>
                        </div>
                    </Row>
                </form>
            </div>
        </>

    );
};

export default CreateCategory;
