import React, {useEffect, useState} from 'react';
import PageTitle from "../../layouts/PageTitle";
import {Col, Row} from "react-bootstrap";
import {BaseInput} from "../Defaults/BaseInput";
import FileUploader from "../../../_helper/FileUploader";
import {DefaultButton} from "../Defaults/DefaultButton";
import {useDispatch, useSelector} from "react-redux";
import {getAllProducts, createNewProduct} from "../../../_actions/products-actions";
import {getAllCategory} from "../../../_actions/category-actions";
import {_http, ERROR} from "../../../_helper";
import {Multiselect} from "multiselect-react-dropdown";
import Switch from '@material-ui/core/Switch';
import EditorToolbar, {formats, modules} from "./EditorToolbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.css";
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import {useTranslation} from "react-i18next";

const NewProducts = ({}) => {
    const [slug, setSlug] = useState("");
    const [name, setName] = useState("");
    const [nameEn, setNameEn] = useState("");
    const [percentageFee, setPercentageFee] = useState("");
    const [minPrice, setMinPrice] = useState(0);
    const [build, setBuild] = useState()
    const [metaTitle, setMetaTitle] = useState("");
    const [metaDesc, setMetaDesc] = useState("");
    const [status, setStatus] = useState("");
    const [currencyData, setCurrencyData] = useState();
    const [categorySelect, setCategorySelect] = useState([]);
    const [typeValue, setTypes] = useState([]);
    const [is_a_range, setIsARange] = useState(false);
    const [maxPrice,setMaxPrice] = useState();
    const [increment,setIncrement] = useState();


    let isValidSave = slug === "" || null || name === "" || null || nameEn === ""
        || null || percentageFee === "" || null || minPrice === 0 || "" || null
        || metaTitle === "" || null || metaDesc === "" || status === "" || null
        || currencyData === "" || null || categorySelect === [] || "" || null;


    const {t} = useTranslation();

    const [files, setFiles] = useState([]);
    const createProduct = useSelector((state) => state.createProduct)
    const {loading, error, success} = createProduct
    const getProducts = useSelector((state) => state.getProducts)
    const {products} = getProducts;
    //category
    const allCategories = useSelector((state) => state.allCategories);
    const {categories} = allCategories;

    let isValCategory = ValidPermissions(PermissionConst.CATEGORY_LIST)
    let isValCurrency = ValidPermissions(PermissionConst.CURRENCY_LIST)
    let isValProduct = ValidPermissions(PermissionConst.PRODUCT_LIST)

    useEffect(() => {
        if (isValCategory) {
            dispatch(getAllCategory())
        }
        if (isValProduct) {
            dispatch(getAllProducts())
        }
        products?.data?.forEach((build) => {
            return setBuild(build.biller_id)
        });
    }, []);


    const dispatch = useDispatch()
    const file = new FormData();
    const createbtn = () => {
        file.append("photo", files?.[0]);
        file.append("name", name);
        file.append("name_en", nameEn);
        file.append("percentage_fee", percentageFee);
        file.append("slug", slug);
        file.append("min_price", minPrice);
        file.append("meta_title", metaTitle);
        file.append("meta_desc", metaDesc);
        file.append("description", description.value);
        file.append("status", status);
        file.append("currency_code", typeValue?.map((item) => item.currency));
        file.append("category_id", categorySelect?.map((item) => item.id));
        file.append("is_a_range", Number(is_a_range));
        if(is_a_range){
            file.append("increment", increment);
            file.append("max_price", maxPrice);
        }
        dispatch(createNewProduct(file))
    }

    useEffect(() => {
        if (error !== "" && error !== undefined && error !== null)
            ERROR(error.photo || error.slug || error.status || error.name || error.category_id);
    }, [error]);


    useEffect(() => {
        const fetchCurrency = async () => {
            await _http("admin/currencies", {method: "get"}).then((r) => {
                if (r.status === 200) {
                    setCurrencyData(r)
                }
            })
        }
        if (isValCurrency) {
            fetchCurrency()
        }
    }, []);
    ///select  category
    const handleCategoryChange = (selected) => {
        setCategorySelect(selected);
    };
    ///select  type
    const handleTypeChange = (selected) => {
        setTypes(selected);
    };

    const style = {
        chips: {
            display: "flex",
            justify: "space-between",
            color: "#fff"
        },
        searchBox: {
            border: "0.5px solid #faf8f9",
            "border-radius": "10px",
            display: "flex"
        },
        multiselectContainer: {
            color: "#000",
        },
        inputField: {
            margin: " 5px",
            textAlign: "right",
        },
        optionContainer: {
            border: " 1px solid",
        },
        option: {
            color: "#000",
            textAlign: "right",
            display: "flex"
        },
        groupHeading: {}
    };

    ///quiil editor
    const [description, setDescription] = React.useState({value: null});
    const handleChange = value => {
        setDescription({value});
    };
    return (
        <>
            <PageTitle activeMenu={t('PRODUCT_NEW_KEY')} pageContent={t('PRODUCT_NEW_KEY')}
                       motherMenu={t('DASHBOARD_KEY')}
                       dashUrl={""} prev={t('ALL_PRODUCT_KEY')} prevUrl={t('PRODUCT_KEY')}/>
            <div className={`card p-5`}>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Row>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control "
                                id="name"
                                type="text"
                                value={name}
                                placeholder={t('NAME_KEY')}
                                onChange={(e) => setName(e.target.value)}
                                label={t('NAME_KEY')}
                            />
                            {error?.name && (
                                <p className={"text-danger"}>{error?.name}</p>
                            )}
                        </Col>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control"
                                id="metaTitle"
                                type="text"
                                value={nameEn}
                                placeholder={t('EN_NAME')}
                                onChange={(e) => setNameEn(e.target.value)}
                                label={t('EN_NAME')}
                            />
                        </Col>
                    </Row>
                    <Row className={"mt-4"}>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control"
                                id="metaTitle"
                                type="text"
                                value={metaTitle}
                                placeholder={t('META_TITLE_KEY')}
                                onChange={(e) => setMetaTitle(e.target.value)}
                                label={t('META_TITLE_KEY')}
                            />
                        </Col>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control "
                                id="metaDesc"
                                type="text"
                                value={metaDesc}
                                placeholder={t('META_DESCRIPTION_KEY')}
                                onChange={(e) => setMetaDesc(e.target.value)}
                                label={t('META_DESCRIPTION_KEY')}
                            />
                        </Col>
                    </Row>
                    <Row className={"mt-4"}>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control "
                                id="percentageFee"
                                type="number"
                                onWheelCapture={e => {
                                    e.target.blur()
                                  }}
                                value={percentageFee}
                                placeholder={t('COMMISSION_PERCENTAGE')}
                                onChange={(e) => setPercentageFee(e.target.value)}
                                label={t('COMMISSION_PERCENTAGE')}
                            />
                        </Col>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control  "
                                id="slug"
                                type="text"
                                value={slug}
                                placeholder={t('SLUG_KEY')}
                                onChange={(e) => setSlug(e.target.value)}
                                label={t('SLUG_KEY')}
                            />
                            {error?.slug && (
                                <p className={"text-danger"}>{error?.slug}</p>
                            )}

                        </Col>
                    </Row>
                    <Row className={"mt-4"}>
                        <Col md={6} xs={12}>
                            <label htmlFor="">{t('CATEGORIES_KEY')}</label>
                            <Multiselect
                                options={categories?.data}
                                displayValue="name"
                                placeholder={t('CATEGORIES_KEY')}
                                singleSelect={true}
                                keepSearchTerm={true}
                                onSelect={handleCategoryChange}
                                style={style}
                                // selectedValues={categories?.data?.map((item) => item.id)}
                            />
                            {error?.category_id && (
                                <p className={"text-danger"}>{error?.category_id}</p>
                            )}
                            {!isValCategory && (
                                <p className={"text-danger mt-2"}>شما دسترسی لازم برای انتخاب دسته بندی را ندارید.</p>)}
                        </Col>
                        <Col md={6} xs={12}>
                            <label htmlFor="status">{t('STATUS_KEY')}</label>
                            <select
                                className="form-control text-dark mb-2"
                                id="status"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <option>{t('STATUS_KEY')}</option>
                                <option value="published">{t('PUBLISH_KEY')}</option>
                                <option value="unpublished "> {t('UNPUBLISH_KEY')}</option>
                            </select>
                            {error?.status && (
                                <p className={"text-danger"}>{error?.status}</p>
                            )}

                        </Col>
                    </Row>
                    <Row className={"mt-4"}>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control"
                                id="minPrice"
                                type="number"
                                disabled={build === null}
                                value={minPrice}
                                placeholder={t('PRICE_IN_DOLLARS')}
                                onChange={(e) => setMinPrice(e.target.value)}
                                label={t('PRICE_IN_DOLLARS')}
                            />
                            {error?.min_price && (
                                <p className={"text-danger"}>{error?.min_price}</p>
                            )}
                        </Col>
                        <Col xs={12} md={6}>
                            <div className={""}>
                                <label htmlFor="">{t('CURRENCY_NAME_KEY')}</label>
                                <Multiselect
                                    options={currencyData?.data?.data}
                                    displayValue="currency"
                                    placeholder={t('CURRENCY_NAME_KEY')}
                                    singleSelect={true}
                                    keepSearchTerm={true}
                                    onSelect={handleTypeChange}
                                    style={style}
                                    // selectedValues={categories?.data?.map((item) => item.id)}
                                />
                            </div>
                            {!isValCategory && (
                                <p className={"text-danger mt-2"}>{t('PERMISSION_ALERT_KET')}</p>)}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className={"cursor-pointer position-relative  mt-3"}>
                            <label >{t('RANGE')}</label>
                                <Switch
                                    checked={is_a_range}
                                    onChange={()=>{setIsARange(!is_a_range)}}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                            </div>
                        </Col>
                       
                    </Row>
                    {is_a_range&&<Row>
                        <Col xs={12} md={6}>
                            <BaseInput
                                    className="form-control"
                                    id="increment"
                                    type="number"
                                    value={increment}
                                    placeholder={"1.23"}
                                    onChange={(e) => setIncrement(e.target.value)}
                                    label={t('INCREMENT')}
                                />
                                {error?.min_price && (
                                    <p className={"text-danger"}>{error?.increment}</p>
                                )}
                        </Col>
                        <Col xs={12} md={6}>
                            <BaseInput
                                    className="form-control"
                                    id="maxPrice"
                                    type="number"
                                    value={maxPrice}
                                    placeholder={t('MAX_PRICE')}
                                    onChange={(e) => setMaxPrice(e.target.value)}
                                    label={t('MAX_PRICE')}
                                />
                                {error?.min_price && (
                                    <p className={"text-danger"}>{error?.max_price}</p>
                                )}
                        </Col>
                    </Row>}
                    <Row>
                        <Col md={12} xs={12}>
                            <div className="text-editor mt-5">
                                <label className={`mt-3 text-center d-block mt-3`}>{t('DESCRIPTION_KEY')}</label>
                                <EditorToolbar/>
                                <ReactQuill
                                    theme="snow"
                                    value={description.value}
                                    onChange={handleChange}
                                    placeholder={""}
                                    modules={modules}
                                    formats={formats}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <div className={"cursor-pointer position-relative w-100 mt-3"}>
                                <FileUploader
                                    src={files}
                                    onFileUpload={setFiles}
                                    uploadMultiple={false}
                                    showPreview={true}
                                    title={t('PHOTO_SELECTION_KEY')}
                                    description={t('NEED_CHANGE_IMAGE_KEY')}
                                />
                                {error?.photo && (
                                    <p className={"text-danger"}>{error?.photo || "مقدار sortsort باید به صورت عددی وارد شود"}</p>
                                )}
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <ul className={"mt-3"}>
                                <h4>{t('CONDITIONS_UPLOAD_KEY')} </h4>
                                <li className={"mt-3"}>{t('IMAGE_SIZE_UPLOAD_KEY')}</li>
                                <li className={"mt-2"}>
                                    {t('conditions_Upload_pic_Product')}
                                </li>
                            </ul>
                        </Col>
                    </Row>
                   
                    <Row>
                        <div className={"text-center m-auto mt-3"}>
                            <DefaultButton
                                onClick={() => createbtn()}
                                disabled={isValidSave}
                                loading={loading}
                                variant="primary"
                            >
                                {t('SAVE_KEY')}
                            </DefaultButton>
                        </div>
                    </Row>
                </form>
            </div>
        </>
    );
};

export default NewProducts;
