import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import {useDispatch, useSelector} from "react-redux";
import avatar from "../../../images/avatar/1.jpg";
import {Dropdown} from "react-bootstrap";
import {logoutActions} from "../../../_actions/auth.actions";
import SwitchLang from "../../components/Lang/SwithLang";
import ProfilePhoto from "../../../images/Profile.png"
import {useTranslation} from "react-i18next";

const Header = () => {
    const [notifyData, setNotifyData] = useState([]);
    const [page, setPage] = useState(1);
    const [notifyTotal, setNotifyTotal] = useState(0);
    const [ticketTotal, setTicketTotal] = useState(0);
    const [hasTicketing, setHasTicketing] = useState(false);

    const [hasNotification, setHasNotification] = useState(false);
    const [saveToken, setSaveToken] = useState("");
    const {t} = useTranslation();

    const dispatch = useDispatch();
    const logout = () => {
        dispatch(logoutActions());
    };
    return (
        <div className="header">
            <div className="header-content">
                <nav className="navbar navbar-expand">
                    <div className="collapse navbar-collapse justify-content-between">

                        <div className="header-left"/>
                        <ul className="navbar-nav header-right main-notification">

                            <SwitchLang/>
                            <Dropdown as="li" className="nav-item dropdown header-profile">
                                <Dropdown.Toggle
                                    variant=""
                                    as="a"
                                    className="nav-link i-false c-pointer"
                                    // href="#"
                                    role="button"
                                    data-toggle="dropdown"
                                >
                                    <img src={ProfilePhoto} width={20} alt=""/>

                                </Dropdown.Toggle>
                                <Dropdown.Menu align="right" className="mt-2">
                                    <Link
                                        onClick={logout}
                                        className="dropdown-item text-right ai-icon"
                                    >

                                        <span className="mr-2">{t('EXIT_KEY')} </span>
                                    </Link>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
};
export default Header;
