import React, {useEffect, useState} from "react";
import PageTitle from "../../layouts/PageTitle";
import {Col, Row} from "react-bootstrap";
import {BaseInput} from "../Defaults/BaseInput";
import FileUploader from "../../../_helper/FileUploader";
import {DefaultButton} from "../Defaults/DefaultButton";
import {useDispatch, useSelector} from "react-redux";
import {
    productDetailsActions,
    editProductAction,
} from "../../../_actions/products-actions";
import {getAllCategory} from "../../../_actions/category-actions";
import {_http, ERROR} from "../../../_helper";
import {useParams} from "react-router-dom";
import Select from "react-select";
import EditorToolbar, {formats, modules} from "./EditorToolbar";
import ReactQuill from "react-quill";
import GiftCreate from "./GiftCreate";
import GiftUpdate from "./GiftUpdate";
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import {useTranslation} from "react-i18next";
import Switch from '@material-ui/core/Switch';

const EditProducts = () => {
    const [slug, setSlug] = useState("");
    const [name, setName] = useState("");
    const [nameEn, setNameEn] = useState("");
    const [percentageFee, setPercentageFee] = useState("");
    const [categoryId, setCategoryId] = useState(0);
    const [minPrice, setMinPrice] = useState(0);
    const [country, setCountry] = useState("");
    const [categorySelect, setCategorySelect] = useState([]);

    const [build, setBuild] = useState();
    const [metaTitle, setMetaTitle] = useState("");
    const [metaDesc, setMetaDesc] = useState("");
    const [status, setStatus] = useState("");
    const [currencyData, setCurrencyData] = useState();
    const [photo, setPhoto] = useState("");
    const [typeValue, setTypes] = useState({
        value: null,
        label: "ارزها",
        enLabel: "",
    });
    const [is_a_range, setIsARange] = useState(false);
    const [maxPrice,setMaxPrice] = useState();
    const [increment,setIncrement] = useState();
    const {t} = useTranslation();
    const [files, setFiles] = useState([]);
    //edit product
    const editProduct = useSelector((state) => state.editProduct);
    const {loading, error, success} = editProduct;
    const dispatch = useDispatch();
    ///category
    const allCategories = useSelector((state) => state.allCategories);
    const {categories} = allCategories;
    ///productDetails
    const getProductDetails = useSelector((state) => state.getProductDetails);
    const {productDetails} = getProductDetails;
    //params
    const {id} = useParams();

    const [refresh, setRefresh] = useState(false);
    const [description, setDescription] = React.useState("");

    //dispatch
    let isValProduct = ValidPermissions(PermissionConst.PRODUCT_SHOW)
    useEffect(() => {
        if (isValProduct) {
            dispatch(productDetailsActions(id));
            dispatch(getAllCategory());
        }
    }, [refresh]);
    /// set items
    useEffect(() => {
        setSlug(productDetails?.data?.slug);
        setName(productDetails?.data?.name);
        setNameEn(productDetails?.data?.name_en);
        setPhoto(productDetails?.data?.photo);
        setStatus(productDetails?.data?.status);
        setPercentageFee(productDetails?.data?.percentage_fee);
        setMetaTitle(productDetails?.data?.meta_title);
        setCategoryId(productDetails?.data?.category_id);
        setMinPrice(productDetails?.data?.min_price);
        setDescription(productDetails?.data?.description);
        setMetaDesc(productDetails?.data?.meta_desc);
        setCountry(productDetails?.data?.country);
        setTypes(productDetails?.data?.currency_code);
        setIsARange(productDetails?.data?.is_a_range);
        setMaxPrice(productDetails?.data?.max_price);
        setIncrement(productDetails?.data?.increment)

        // setCategorySelect(productDetails?.data?.category?.name);
    }, [productDetails]);
    ///send Data

    const file = new FormData();
    const editFormbtn = () => {
        if (files.length !== 0) {
            file.append("photo", files?.[0]);
        }

        if (categorySelect.length !== 0) {
            file.append("category_id", categorySelect.value);
        }

        file.append(
            "currency_code",
            typeValue?.label === undefined || null ? typeValue : typeValue.label
        );
        file.append(
            "status",
            status?.value === undefined || null ? status : status.value
        );
        file.append("name", name);
        file.append("name_en", nameEn);
        file.append("description", description);
        file.append("slug", slug);
        file.append("percentage_fee", percentageFee);
        file.append("min_price", minPrice);
        file.append("country", country);
        file.append("meta_title", metaTitle);
        file.append("meta_desc", metaDesc);
        file.append("_method", "patch");
        file.append("is_a_range", Number(is_a_range));
        if(is_a_range){
            file.append("increment", increment);
            file.append("max_price", maxPrice);
        }

        dispatch(editProductAction(file, id));
    };
    const handleChange = (value) => {
        setDescription({value});
    };
    //validation empty
    const isEmpty =
        slug === "" ||
        null ||
        name === "" ||
        null ||
        metaTitle === "" ||
        null ||
        metaDesc === "" ||
        null ||
        status === "" ||
        null ||
        nameEn === "" ||
        null ||
        description === "" ||
        minPrice === "" ||
        percentageFee === "" ||
        categoryId === "";

    useEffect(() => {

        const fetchCurrency = async () => {
            await _http("admin/currencies", {method: "get"}).then((r) => {
                if (r.status === 200) {
                    setCurrencyData(r);
                }
            });
        };
        if (isValProduct) {
            fetchCurrency();
        }
    }, []);
    //select cureency
    const handleTypeChange = (selected) => {
        setTypes(selected);
    };
    ///select  category
    const handleCategoryChange = (selected) => {
        setCategorySelect(selected);
    };

    const handleStatusChange = (selected) => {
        setStatus(selected);
    };
    const CategoryOptions = categories?.data?.map((item) => {
        return {
            value: item.id,
            label: item.name,
        };
    });
    const CurrencyOptions = currencyData?.data?.data?.map((item) => {
        return {
            value: item.id,
            label: item.currency, isDisabled: productDetails?.data?.biller_id !== null ? true : false,
        };
    });
    const statusOptions = [
        {value: "published", label: t('PUBLISH_KEY')},
        {value: "unpublished", label: t('UNPUBLISH_KEY')},
    ];
    const groupStyles = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    };
    const groupBadgeStyles = {
        backgroundColor: "#EBECF0",
        borderRadius: "2em",
        color: "#000",
        display: "inline-block",
        fontSize: 12,
        fontWeight: "normal",
        lineHeight: "1",
        minWidth: 1,
        padding: "0.16666666666667em 0.5em",
        textAlign: "center",
    };
    const formatGroupLabel = (data) => (
        <div style={groupStyles}>
            <span style={{color: "#000"}}>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    let des = <p dangerouslySetInnerHTML={{__html: productDetails?.data?.description}}/>;
    return (
        <>
            <PageTitle
                activeMenu={t('EDIT_KEY')}
                pageContent={t('EDIT_KEY')}
                motherMenu={t('DASHBOARD_KEY')}
                prev={t('ALL_PRODUCT_KEY')}
                prevUrl={"products"}
                dashUrl={""}
            />
            <div className={`card p-5`}>
                <form className={"mt-5"} onSubmit={(e) => e.preventDefault()}>
                    <Row>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control "
                                id="name"
                                type="text"
                                value={name}
                                placeholder={t('FA_NAME')}
                                onChange={(e) => setName(e.target.value)}
                                label={t('FA_NAME')}
                            />
                            {error?.name && <p className={"text-danger"}>{error?.name}</p>}
                        </Col>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control "
                                id="nameEn"
                                type="text"
                                value={nameEn}
                                placeholder={t('EN_NAME')}
                                onChange={(e) => setNameEn(e.target.value)}
                                label={t('EN_NAME')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control "
                                id="metaTitle"
                                type="text"
                                value={metaTitle}
                                placeholder={t('META_TITLE_KEY')}
                                onChange={(e) => setMetaTitle(e.target.value)}
                                label={t('META_TITLE_KEY')}
                            />
                        </Col>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control "
                                id="metaDesc"
                                type="text"
                                value={metaDesc}
                                placeholder={t('META_DESCRIPTION_KEY')}
                                onChange={(e) => setMetaDesc(e.target.value)}
                                label={t('META_DESCRIPTION_KEY')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control "
                                id="percentageFee"
                                type="number"
                                onWheelCapture={e => {
                                    e.target.blur()
                                  }}
                                value={percentageFee}
                                placeholder={t('COMMISSION_PERCENTAGE')}
                                onChange={(e) => setPercentageFee(e.target.value)}
                                label={t('COMMISSION_PERCENTAGE')}
                            />
                        </Col>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control "
                                id="slug"
                                type="text"
                                value={slug}
                                placeholder={t('SLUG_KEY')}
                                onChange={(e) => setSlug(e.target.value)}
                                label={t('SLUG_KEY')}
                            />
                            {error?.slug && <p className={"text-danger"}>{error?.slug}</p>}
                        </Col>
                    </Row>
                    <Row className={"mt-3"}>
                        <Col md={6} xs={12} >
                            <label htmlFor="">{t('CATEGORIES_KEY')}</label>
                            <Select
                                className="text-dark"
                                formatGroupLabel={formatGroupLabel}
                                placeholder={categorySelect}
                                onChange={handleCategoryChange}
                                options={CategoryOptions}
                            />
                        </Col>
                        <Col md={6} xs={12} className={"mt-4"}>
                            <Select
                                className="text-dark"
                                formatGroupLabel={formatGroupLabel}
                                placeholder={
                                    status === "unpublished"
                                        ? t('PUBLISH_KEY')
                                        : status === "published"
                                            ? t("UNPUBLISH_KEY")
                                            : status
                                }
                                options={statusOptions}
                                onChange={handleStatusChange}
                            />
                            {error?.status && (
                                <p className={"text-danger"}>{error?.status}</p>
                            )}
                        </Col>
                    </Row>
                    <Row className={"mt-3"}>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control "
                                id="country"
                                type="text"
                                value={country}
                                placeholder={t('COUNTRY_KEY')}
                                onChange={(e) => setCountry(e.target.value)}
                                label={t('COUNTRY_KEY')}
                            />
                        </Col>
                        <Col md={6} xs={12}>
                            <BaseInput
                                className="form-control "
                                id="minPrice"
                                type="text"
                                disabled={productDetails?.data?.biller_id !== null}
                                value={minPrice}
                                placeholder={t('PRICE_IN_DOLLARS')}
                                onChange={(e) => setMinPrice(e.target.value)}
                                label={t('PRICE_IN_DOLLARS')}
                            />
                        </Col>
                    </Row>
                    <Row className={"mt-2"}>
                        <Col xs={12} md={6}>
                            <div className={"direction-ltr"}>
                                <label htmlFor="">{t('CURRENCY_NAME_KEY')}</label>
                                <Select
                                    className="text-dark"

                                    formatGroupLabel={formatGroupLabel}
                                    placeholder={typeValue && typeValue}
                                    value={typeValue}
                                    onChange={handleTypeChange}
                                    options={CurrencyOptions}
                                />
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className={"cursor-pointer position-relative  mt-3"}>
                            <label >{t('RANGE')}</label>
                                <Switch
                                    checked={is_a_range}
                                    onChange={()=>{setIsARange(!is_a_range)}}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                            </div>
                        </Col>
                    </Row>
                   
                    {is_a_range&&<Row>
                        <Col xs={12} md={6}>
                            <BaseInput
                                    className="form-control"
                                    id="increment"
                                    type="number"
                                    value={increment}
                                    placeholder={"1.23"}
                                    onChange={(e) => setIncrement(e.target.value)}
                                    label={t('INCREMENT')}
                                />
                                {error?.min_price && (
                                    <p className={"text-danger"}>{error?.increment}</p>
                                )}
                        </Col>
                        <Col xs={12} md={6}>
                            <BaseInput
                                    className="form-control"
                                    id="maxPrice"
                                    type="number"
                                    value={maxPrice}
                                    placeholder={t('MAX_PRICE')}
                                    onChange={(e) => setMaxPrice(e.target.value)}
                                    label={t('MAX_PRICE')}
                                />
                                {error?.min_price && (
                                    <p className={"text-danger"}>{error?.max_price}</p>
                                )}
                        </Col>
                    </Row>}
                    <Row>
                        <Col md={12} xs={12}>
                            <div className="text-editor mt-5 ">
                                <label className={`mt-3 text-center d-block mt-3`}>
                                    {t('DESCRIPTION_KEY')}
                                </label>
                                <EditorToolbar/>
                                <ReactQuill
                                    value={description ? description : ""}
                                    theme="snow"
                                    onChange={(value) => setDescription(value)}
                                    placeholder={t('DESCRIPTION_KEY')}
                                    modules={modules}
                                    formats={formats}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className={"mt-3"}>
                        <ul className={"mt-3"}>
                            <h4>{t('CONDITIONS_UPLOAD_KEY')} </h4>
                            <li className={"mt-3"}>{t('IMAGE_SIZE_UPLOAD_KEY')}</li>
                            <li className={"mt-2"}>
                                {t('conditions_Upload_pic_Product')}
                            </li>
                        </ul>
                    </div>

                    <Row>
                        <Col md={6} xs={12}>
                            <div className={"cursor-pointer position-relative w-100 mt-4"}>
                                <FileUploader
                                    src={files}
                                    onFileUpload={setFiles}
                                    uploadMultiple={false}
                                    showPreview={true}
                                    title={t('PHOTO_SELECTION_KEY')}
                                    description={t('NEED_CHANGE_IMAGE_KEY')}
                                />
                                {error?.photo && (
                                    <p className={"text-danger"}>
                                        {error?.photo}
                                    </p>
                                )}
                            </div>
                        </Col>
                        <Col md={6} xs={12}>
                            {photo && (
                                <img
                                    className={"img-responsive img-fluid mt-3"}
                                    width={"250"}
                                    height={"250"}
                                    src={photo}
                                    alt={""}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <div className={"text-center m-auto mt-3"}>
                            <DefaultButton
                                // disabled={isEmpty}
                                className={"mt-3"}
                                onClick={() => editFormbtn()}
                                loading={loading}
                                variant="primary"
                            >
                                {t('EDIT_KEY')}
                            </DefaultButton>
                        </div>
                    </Row>
                </form>
            </div>
            <div className="row">
                {ValidPermissions(PermissionConst.GIFT_STORE) ? (<>
                    <GiftCreate id={id}/>

                </>) : null}
                {ValidPermissions(PermissionConst.GIFT_UPDATE) ? (<>
                    <GiftUpdate refresh={refresh} setRefresh={setRefresh} getProductDetails={getProductDetails}
                                id={id}/>
                </>) : null}
            </div>
        </>
    );
};

export default EditProducts;
