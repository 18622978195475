import {Dropdown} from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner'
import {Link} from "react-router-dom"
import {useTranslation} from "react-i18next";

const CurrencyTable = ({currencyData, error, loading}) => {

    const {t} = useTranslation();
    let dir = localStorage.getItem("Layout");
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <div className={`d-flex justify-content-between align-items-center w-100`}>
                        {!loading && <h4 className="card-title">{t("TABLE_CURRENCY_KEY")}</h4>}
                        {loading && <div className={"text-center"}><Spinner animation="grow" variant="success"/>
                            <Spinner animation="grow" variant="danger"/>
                            <Spinner animation="grow" variant="warning"/>
                            <Spinner animation="grow" variant="info"/>
                            <Spinner animation="grow" variant="light"/>
                            <Spinner animation="grow" variant="dark"/></div>}
                    </div>
                </div>
                <div className="card-body">
                    <div className="w-100 table-responsive ">
                        <div id="patientTable_basic_table" className="dataTables_wrapper ">
                            <table
                                id="example5"
                                className=" dataTable no-footer w-100  table-striped  table-hover "
                                style={{minWidth: 845}}
                                role="grid"
                                aria-describedby="example5_info"
                            >
                                <thead>
                                <tr role="row">
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient ID: activate to sort column ascending"
                                    >
                                        {t('ID_KEY')}

                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient ID: activate to sort column ascending"
                                    >
                                        {t('Min_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Date Check in: activate to sort column ascending"
                                    >
                                        {t('Max_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Doctor Assgined: activate to sort column ascending"
                                    >
                                        {t('CURRENCY_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient Name: activate to sort column ascending"
                                    >
                                        {t('CURRENT_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Doctor Assgined: activate to sort column ascending"
                                    >
                                        {t('UPDATE_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example6"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Disease: activate to sort column ascending"
                                    >
                                        {t('UPDATE_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example7"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Disease: activate to sort column ascending"
                                    >
                                        {t('ACTIONS_KEY')}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {currencyData?.map((item) => (
                                    <>
                                        <tr key={item.id} role="row" className="odd">

                                            <td className={'text-center'}>{item.id}</td>

                                            <td className={'text-center text-warning'}>
                                                {parseInt(item?.min).toLocaleString()}
                                            </td>
                                            <td className={'text-center text-success'}>{parseInt(item.max)?.toLocaleString()}</td>
                                            <td className={'text-center'}>{item.currency.toLocaleString()}</td>
                                            <td className={'text-center'}>{parseInt(item.current)?.toLocaleString()}</td>
                                            <td className={'text-center'}>{item.updated_at}</td>
                                            <td className={'text-center'}>{item.created_at}</td>
                                            <td className={'text-center'}>
                                                <Dropdown className="dropdown ml-auto ">
                                                    <Dropdown.Toggle
                                                        variant=""
                                                        className="btn-link i-false"
                                                        data-toggle="dropdown"
                                                    >
                                                        <svg
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                strokeWidth={1}
                                                                fill="none"
                                                                fillRule="evenodd"
                                                            >
                                                                <rect x={0} y={0} width={24} height={24}/>
                                                                <circle fill="#000000" cx={5} cy={12} r={2}/>
                                                                <circle fill="#000000" cx={12} cy={12} r={2}/>
                                                                <circle fill="#000000" cx={19} cy={12} r={2}/>
                                                            </g>
                                                        </svg>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                        {/*<Dropdown.Item*/}
                                                        {/*    className={'d-flex align-items-center justify-content-center'}*/}
                                                        {/*    // href={`products/${item.id}`}*/}
                                                        {/*>*/}
                                                        {/*    <i className="fa fa-info text-secondary fa-2x ml-4"></i>*/}
                                                        {/*    جزئیات*/}
                                                        {/*</Dropdown.Item>*/}
                                                        <Dropdown.Item
                                                            className={'d-flex align-items-center justify-content-center'}
                                                        >
                                                            <Link className={"d-flex align-items-center"} to={`currency/${item.id}`}>
                                                                <i className={dir === "rtl" ? "fa fa-edit  text-warning fa-2x ml-3" : "fa fa-edit  text-warning fa-2x mr-3"}/>
                                                                {t('EDIT_KEY')}
                                                            </Link>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                    </>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CurrencyTable;
