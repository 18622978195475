import parseJwt from "./DecodeToken";

let token = parseJwt(localStorage.getItem("token"));

console.log(token, "admin")

export default function ValidPermissions(permission) {
    let admin = token?.roles?.find((item) => item.name === "admin")
    if (admin) return true;

    let result = token?.permissions?.filter((item) => item.name === permission);
    return result?.length !== 0;

}

