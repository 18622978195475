import React, { useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import NumberFormat from "react-number-format";

export const NumberInput = ({
  id,
  dir = "",
  label = "",
  errorText = "",
  error,
  allowLeadingZeros = false,
  ...rest
}) => {
  const [_id] = useState(id || `basic-input-${Math.random()}`);
  return (
    <FormGroup className={!!dir ? `dir-${dir}` : ""}>
      {label && (
        <Label className="fs-12" for={_id}>
          {label}
        </Label>
      )}
      <NumberFormat
        id={_id}
        {...rest}
        thousandSeparator={true}
        allowLeadingZeros={allowLeadingZeros}
      />
      {error && (
        <div className="text-right ml-auto text_align_lang">
          <span style={{ fontSize: "0.7rem" }} className="text-danger mb-4">
            {errorText}
          </span>
        </div>
      )}
    </FormGroup>
  );
};
