import {Line} from "react-chartjs-2";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getUserInfoAction, userActions} from "../../../_actions/dashboard.actions";
import {ERROR} from "../../../_helper";
import Select from "react-select";
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst"
import {useTranslation} from "react-i18next";

export default function GetOrderStatus() {
    const [orderData, setOrderData] = useState([]);
    const [time, setTime] = useState("today");
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const dir = localStorage.getItem("Layout");
    let isValid = ValidPermissions(PermissionConst.DASHBOARD_ORDERS_STATISTICS);
    useEffect(() => {
        if (isValid) {
            dispatch(getUserInfoAction())
        }
    }, []);


    const getOrder = (time) => {
        if (isValid) {
            dispatch(
                userActions.filterOrderStatus(time)
            )
                .then((r) => {
                    if (r.status === 200) {
                        setOrderData(r?.data)
                    }
                })
                .catch((ex) =>
                    ERROR(ex.response?.data?.message || ex.message, {
                        error: ex,
                    })
                );
        }
    }
    const handleChangeData = (selected) => {
        setTime(selected)
    }
    useEffect(() => {
        if (isValid) {
            getOrder(time.value)
        }
    }, [time]);


    useEffect(() => {
        if (isValid) {
            dispatch(
                userActions.filterOrderStatus(time)
            )
                .then((r) => {
                    if (r.status === 200) {
                        setOrderData(r?.data)
                    }
                })
                .catch((ex) =>
                    ERROR(ex.response?.data?.message || ex.message, {
                        error: ex,
                    })
                );
        }

    }, []);

    let date = orderData?.data?.statistics.map((d) => d.date)
    let count = orderData?.data?.statistics.map((d) => d.count)
    let price = orderData?.data?.statistics.map((d) => d.price)
    const data = {
        labels: date,
        datasets: [
            {
                label:t("COUNT_KEY"),
                data: count,
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)"
            },
            {
                label: t("PRICE_KEY"),
                // data: [33, 25, 35, 51, 54, 76],
                data: price,
                fill: false,
                borderColor: "#742774"
            }
        ]
    };

    const formatGroupLabel = data => (
        <div style={groupStyles}>
            <span style={{color: '#000'}}>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );
    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#000',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };
    const amar = [
        {type: 'today', value: dir === "rtl" ? "روزانه" : dir === "ltr" ? "Daily" : "روزانه"},
        {type: 'weekly', value: dir === "rtl" ? "هفتگی" : dir === "ltr" ? "Weekly" : "هفتگی"},
        {type: 'monthly', value: dir === "rtl" ? "ماهانه" : dir === "ltr" ? "Monthly    " : "ماهانه"},
        {type: 'yearly', value: dir === "rtl" ? "سالانه" : dir === "ltr" ? "yearly    " : "سالانه"}
    ]
    const optionData = amar?.map((item) => {
        return {
            value: item.type,
            label: item.value,
        };
    });
    return (
        <>
            <div className=" d-flex justify-content-between align-content-center" style={{marginTop: "180px"}}>
                <h3>{t('CHART_ORDERS_KEY')}</h3>

                <Select
                    options={optionData}
                    formatGroupLabel={<formatGroupLabel/>}
                    className="text-dark w-25 mb-4"
                    placeholder={t('DAILY_STATISTICS_KEY')}
                    onChange={handleChangeData}/>
            </div>
            <div className="card p-5">
                <Line data={data}/>
            </div>
        </>
    )
    }