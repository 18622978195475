import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {getAllTransaction, WalletActions} from "../../../_actions/wallet-actions"
import Pagination from "react-js-pagination";
import Spinner from 'react-bootstrap/Spinner';
import TransctionModal from "./TransctionModal";
import {ERROR} from "../../../_helper";
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import {useTranslation} from "react-i18next";

const OrderTable = ({transition, loading, error}) => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [WalletTotal, setWalletTotal] = useState(1);
    const [WalletData, setWalletData] = useState([]);

    const [type, setTypes] = useState("");
    const [sort, setSort] = useState("");

    const {t} = useTranslation();

    const dispatch = useDispatch();
    let isValid = ValidPermissions(PermissionConst.USER_SEE_ALL_TRANSACTIONS)

    useEffect(() => {
        if (isValid) {
            dispatch(getAllTransaction())
        }
    }, [])


    const handlePageChange = async (pageNumber) => {
        setPage(pageNumber);
        const page = pageNumber;
        dispatch(
            WalletActions.getWalletPage(page)
        )
            .then((r) => {
                if (r.status === 200) {
                    window.scroll(0, 0)
                    setWalletData(r)
                    setWalletTotal(r.data?.meta?.total);
                }
            })
            .catch((ex) =>
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                })
            );
    }
    useEffect(() => {
        if (isValid) {
            dispatch(
                WalletActions.getWalletPage(page)
            )
                .then((r) => {
                    if (r.status === 200) {
                        setWalletData(r)
                        setWalletTotal(r.data?.meta?.total);
                    }
                })
                .catch((ex) =>
                    ERROR(ex.response?.data?.message || ex.message, {
                        error: ex,
                    })
                );
        }
    }, [page]);
    const handleSearch = (e) => {
        e.preventDefault()
        dispatch(
            WalletActions.filterWallet(search)
        ).then((r) => {
            if (r.status === 200) {
                setWalletData(r)
            }
        })
            .catch((ex) =>
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                })
            );
    }
    let dir = localStorage.getItem("Layout");
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <div className={`d-flex justify-content-between align-items-center w-100`}>
                        {!loading && <h4 className="card-title">{t('TRANSACTION_KEY')}</h4>}
                        {loading && <div className={"text-center"}><Spinner animation="grow" variant="success"/>
                            <Spinner animation="grow" variant="danger"/>
                            <Spinner animation="grow" variant="warning"/>
                            <Spinner animation="grow" variant="info"/>
                            <Spinner animation="grow" variant="light"/>
                            <Spinner animation="grow" variant="dark"/></div>}

                        <form className={"d-flex"} onSubmit={handleSearch}>
                            <input className={"form-control rounded-0"} type="search" placeholder={t('SEARCH_KEY')}
                                   onChange={(e) => setSearch(e.target.value)}/>
                            <button type={"submit"} className={"btn btn-primary rounded-0"}>
                                <i className={"fa fa-search "}/>
                            </button>
                        </form>
                    </div>
                </div>
                <div className="card-body">
                    <div className="w-100 table-responsive">
                        <div id="patientTable_basic_table" className="dataTables_wrapper">
                            <table
                                id="example5"
                                className=" dataTable no-footer w-100  table-striped  table-hover "
                                style={{minWidth: 845}}
                                role="grid"
                                aria-describedby="example5_info"
                            >
                                <thead>
                                <tr role="row">
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient ID: activate to sort column ascending"
                                    >
                                        {t('ID_KEY')}

                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient ID: activate to sort column ascending"
                                    >
                                        {t('EMAIL_KEY')}

                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient ID: activate to sort column ascending"
                                    >
                                        {t('MOBILE_KEY')}

                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient ID: activate to sort column ascending"
                                    >
                                        {t('AMOUNT_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient ID: activate to sort column ascending"
                                    >
                                        {t('DATE_TRANSACTION')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient ID: activate to sort column ascending"
                                    >
                                        {t('ACCOUNT_BALANCE')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient Name: activate to sort column ascending"
                                    >
                                        {t('TYPE_TRANSACTION')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example7"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Disease: activate to sort column ascending"
                                    >
                                        {t('DETAILS_KEY')}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {WalletData?.data?.data?.map((item) => (
                                    <>
                                        <tr key={item.id} role="row" className="odd">
                                            <td className={'text-center'}>{item.id}</td>
                                            <td className={'text-center'}>{item?.to_holder?.email} </td>
                                            <td className={'text-center'}>{item?.to_holder?.mobile} </td>
                                            <td className={'text-center'}>
                                                {item?.type === "deposit" ? (
                                                    <>
                                                        <span className="badge light badge-success ">
                                                          <i className="fa fa-plus text-success mr-1"/>
                                                            <span className={'mr-2 fs-13'}>
                                                                  {parseInt(item?.amount).toLocaleString()}
                                                            </span>
                                                           </span>
                                                    </>
                                                ) : item?.type === "withdraw" ? (
                                                    <>
                                                    <span className="badge light badge-danger ">
                                                    <span className={'mr-2 fs-13'}>
                                                 {item?.amount.toLocaleString()}
                                                    </span>
                                                    </span>
                                                    </>
                                                ) : null}
                                            </td>
                                            <td className={'text-center'}>{item?.created_at}</td>
                                            <td className={'text-center text-warning'}>{parseInt(item?.remaining).toLocaleString()}</td>
                                            <td className={'text-center'}>
                                                {item?.type === "deposit" ? (
                                                    <>
                                                        <span className="badge light badge-success ">
                                                            <span
                                                                className={'mr-2 fs-13 d-flex align-items-center p-1'}>
                                                                  <i className={dir === "rtl" ?"fa fa-plus ml-2" : "fa fa-plus mr-2"}/>
                                                                {item?.type === "deposit" ? t('DEPOSIT_KEY') : ""}
                                                            </span>
                                                           </span>
                                                    </>
                                                ) : item?.type === "withdraw" ? (
                                                    <>
                                                    <span className="badge light badge-danger ">
                                                    <span className={'mr-2 d-flex align-items-center p-1 fs-13'}>
                                                         <i className={ dir === "rtl" ? "fa fa-minus ml-2": "fa fa-minus mr-2"} />
                                                        {item?.type === "withdraw" ? t('WITHDRAW_KEY') : ""}
                                                    </span>
                                                    </span>
                                                    </>
                                                ) : null}


                                            </td>

                                            <td className={'text-center'}>
                                                <TransctionModal data={item} trans={item?.to_holder}>
                                                    {t('DETAILS_KEY')}
                                                </TransctionModal>
                                            </td>
                                        </tr>
                                    </>
                                ))}
                                </tbody>
                            </table>
                            <div
                                className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                                <div className="dataTables_info">
                                    {/*   */}
                                </div>
                                <div
                                    className="dataTables_paginate paging_simple_numbers"
                                    id="example5_paginate"
                                >
                                    <div className="d-inline-block pb-3 ltr_direction">
                                        <Pagination
                                            activePage={page}
                                            itemsCountPerPage={WalletData?.data?.meta?.per_page}
                                            totalItemsCount={WalletTotal}
                                            pageRangeDisplayed={10}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default OrderTable;
