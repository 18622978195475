import React from "react";
import { Spinner, Button } from "react-bootstrap";

export const DefaultButton = ({ children, loading, ...rest }) => {
  return (
    <Button {...rest}>
      <div className={"d-flex align-items-center justify-content-center"}>
        {children}
        {loading && (
          <Spinner
            size={"sm"}
            className='mr-2 mt-2'
            animation='border'
            variant='light'
          />
        )}
      </div>
    </Button>
  );
};
