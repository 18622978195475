import React, {useEffect, useState} from "react";
import {DefaultButton} from "../Defaults/DefaultButton";
import FlipMove from "react-flip-move";
import {FaTrash} from "react-icons/fa";
import "./style.css";
import {createGiftAction} from "../../../_actions/gift-actions";
import {useDispatch} from "react-redux";
import {Tabs, Tab} from "react-bootstrap";
import Exeluploader from "./Exeluploader";
import {useTranslation} from "react-i18next";

const GiftCreate = ({id, giftUpdate, setgiftUpdate}) => {
    const [items, setItems] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [currentItem, setCurrentItem] = useState({
        code: "",
        key: "",
        product_id: id,
    });
    const handleTodo = (e) => {
        e.preventDefault();
        let newItem = currentItem;
        if (newItem !== "") {
            setIsActive(true);
            let newItems = [...items, newItem];
            JSON.stringify(setItems(newItems));
            setCurrentItem({
                code: "",
                key: "",
                product_id: id,
            });
        }
    };

    const handleInput = (e) => {
        setCurrentItem({
            code: e.target.value,
            key: Math.floor(Math.random() * 10000),
            product_id: parseInt(id),
        });
    };
    const deleteTodo = (key) => {
        const filter = items.filter((x) => x.key !== key);
        setItems(filter);
    };

    const dispatch = useDispatch();
    const sendGiftData = () => {
        const data = {
            product_id: id,
            codes: items,
        };

        dispatch(createGiftAction(data));
    };
    const isButton = currentItem.code === "";


    const {t} = useTranslation();
    return (
        <div className={"card p-5 col-xs-12 col-md-12  col-lg-12  col-xl-5"}>
            <h4 className={"mb-4"}>{t('CREATE_GIFT_CART')}</h4>
            <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="home" title={t('RIGHT_CODE_CART')}>
                    <div className="col-xs-12 col-md-12">
                        <form onSubmit={handleTodo} id={"to-do-form"} className={""}>
                            <div className="d-flex">
                <textarea
                    cols="30"
                    rows="3"
                    className={"form-control rounded-0"}
                    onChange={handleInput}
                    value={currentItem.code}
                    placeholder={t('INSERT_CODE_CART')}
                />
                                <button
                                    disabled={isButton}
                                    className={"btn btn-xs btn-primary rounded-0"}
                                    type={"submit"}
                                >
                                    <i className={"fa fa-plus"}/>
                                </button>
                            </div>
                            <FlipMove easing={"ease-in-out"} duration={500}>
                                {items?.map((item, i) => (
                                    <div key={i} className={"list"}>
                                        <p className={"input_todo"}>{item.code}</p>
                                        <FaTrash
                                            onClick={() => deleteTodo(item.key)}
                                            className={"trash-icons"}
                                        />
                                    </div>
                                ))}
                            </FlipMove>
                            {isActive && (
                                <DefaultButton
                                    className={"mt-3"}
                                    onClick={() => sendGiftData()}
                                    variant="primary"
                                >
                                    {t('SAVE_KEY')}
                                </DefaultButton>
                            )}
                        </form>
                    </div>
                </Tab>
                <Tab eventKey="profile" title={t('CODE_WITH_EXCEL')}>
                    <Exeluploader productId={id}/>
                </Tab>
            </Tabs>
        </div>
    );
};

export default GiftCreate;
