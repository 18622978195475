import { _http, ERROR, SUCCESS } from "../_helper";
import { orderConst } from "../_constants/order-constant"
import { getError } from "../_helper/getError";

export const getOrderList = () => async (dispatch) => {
    dispatch({ type: orderConst.GET_ORDER_REQUEST })

    await _http("admin/orders", { method: "get" }).then((res) => {
        if (res.status === 200) {
            dispatch({ type: orderConst.GET_ORDER_SUCCESS, payload: res?.data })
        } else {
            ERROR(res.data.message)
            dispatch({ type: orderConst.GET_ORDER_FAIL, payload: res })
        }
    }).catch((error) => {
        let message;
        if (error?.response?.data?.message) message = error?.response?.data?.message;
        ERROR(message);
        dispatch({ type: orderConst.GET_ORDER_FAIL, payload: error })
    })
}

export const getMasterCardOrderList = () => async (dispatch) => {
    dispatch({ type: orderConst.GET_MASTER_REQUEST })

    await _http("admin/masterCards/orders", { method: "get" }).then((res) => {
        if (res.status === 200) {
            dispatch({ type: orderConst.GET_MASTER_SUCCESS, payload: res?.data })
        } else {
            ERROR(res.data.message)
            dispatch({ type: orderConst.GET_MASTER_FAIL, payload: res })
        }
    }).catch((error) => {
        let message;
        if (error?.response?.data?.message) message = error?.response?.data?.message;
        ERROR(message);
        dispatch({ type: orderConst.GET_MASTER_FAIL, payload: error })
    })
}


export const submitMasterAction = (data, id) => async (dispatch) => {
    dispatch({ type: orderConst.SUBMIT_MASTER_REQUEST })
    await _http(`admin/masterCards/orders/${id}`, { method: "post", data }).then((res) => {
        if (res.status === 200) {
            SUCCESS(res?.data?.message);
            window.location.href = "/master-cards"
            dispatch({ type: orderConst.SUBMIT_MASTER_SUCCESS, payload: res });
        } else {
            dispatch({ type: orderConst.SUBMIT_MASTER_FAIL, payload: res?.response?.data?.errors });
            getError(res?.response?.data?.message)
        }
    }).catch((error) => {
        getError(error);
        dispatch({ type: orderConst.SUBMIT_MASTER_FAIL, payload: error?.response?.data?.errors });
    })

}

///Details order

export const getDetailsOrder = (id) => async (dispatch) => {
    dispatch({ type: orderConst.GET_DETAILS_ORDER_REQUEST, payload: id })

    await _http(`admin/orders/${id}`, { method: "get" }).then((res) => {
        if (res.status === 200) {
            dispatch({ type: orderConst.GET_DETAILS_ORDER_SUCCESS, payload: res?.data?.data })
        } else {
            ERROR(res.data.message)
            dispatch({ type: orderConst.GET_DETAILS_ORDER_FAIL, payload: res })
        }
    }).catch((error) => {
        let message;
        if (error?.response?.data?.message) message = error?.response?.data?.data;
        ERROR(message);
        dispatch({ type: orderConst.GET_DETAILS_ORDER_FAIL, payload: error })

    });
}

function filterOrder(search) {
    return () => {
        return _http(`admin/orders?search=${search}`, { method: "get" })
    }
}
function sortOrder(sort, type) {
    return () => {
        return _http(`admin/orders?sort=${sort}&orderBy=${type}`, { method: "get" })
    }
}

//get order List
function getOrderPage(page) {
    return () => {
        return _http(`admin/orders?page=${page}`, { method: "get" });
    };
}
function filterMaster(search) {
    return () => {
        return _http(`admin/masterCards/orders?search=${search}`, { method: "get" })
    }
}
function sortMaster(sort, type) {
    return () => {
        return _http(`admin/masterCards/orders?sort=${sort}&orderBy=${type}`, { method: "get" })
    }
}

//get order List
function getMasterPage(page) {
    return () => {
        return _http(`admin/masterCards/orders?page=${page}`, { method: "get" });
    };
}

export const orderActions = {
    filterOrder, getOrderPage, sortOrder
}
export const masterOrderActions = {
    filterMaster, getMasterPage, sortMaster
}


