import React, {useEffect, useState, useContext} from "react";
import cookie from "js-cookie";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
import {Dropdown} from "react-bootstrap";
import {ThemeContext} from "../../../context/ThemeContext";
import iran from "../../../images/iran.png";
import England from "../../../images/en.svg";

export default function SwitchLang() {
    const [drop, setDrop] = useState(false);
    const {t} = useTranslation();
    const content = [
        {code: "en", name: "EN", dir: "English", img: iran, title: "England"},
        {code: "fa", name: "FA", dir: "فارسی", img: England, title: "Iran"},
    ];
    const currentLangCode = cookie.get("i18next") || "FA";
    const currentLanguage = content.find((i) => i.code === currentLangCode);
    let direction = localStorage.getItem("Layout");
    useEffect(() => {

        let html = document.querySelector("html");
        document.body.setAttribute("direction", direction || "rtl");
        html.setAttribute("dir", direction || "rtl");;
    }, [currentLanguage, t]);

    const {changeDirectionLayout} = useContext(ThemeContext);
    return (
        <div className={""}>
            <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                    variant=""
                    as="a"
                    className="nav-link i-false c-pointer"
                    // href="#"
                    role="button"
                    data-toggle="dropdown"
                >
                    <div className="header-info border p-2 rounded">
                        <i className={direction === "rtl" ? "fa fa-globe fa-2x ml-2" : "fa fa-globe fa-2x mr-2"}/>
                        {direction === "rtl" ? "FA" : direction === "ltr" ? "EN" : null}
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu align="right" className="mt-2">
                    {content.map(({code, name, dir, img, title}) => (
                        <div key={img} className={"text-center cursor-pointer position-relative d-flex"}
                             onClick={() => i18next.changeLanguage(code)}>
                            <input value={dir}
                                   onClick={(e) => changeDirectionLayout(e)}
                                   className=" ml-2 text-center mt-2 fs-18 cursor-pointer border-0 bg-transparent text-white"/>
                        </div>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}
