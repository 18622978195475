import React, {useState} from "react";

import {Button} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {GiftExcelAction} from "../../../_actions/gift-actions";

export default function Exeluploader({productId}) {
    const [file, setFile] = useState(null);

    const createExcel = useSelector((state) => state.createExcel);
    const {error} = createExcel;

    const dispatch = useDispatch();
    let format = new FormData();
    const UploadExcel = () => {
        format.append("file", file[0]);
        format.append("product_id", productId);
        console.log(file)
        dispatch(GiftExcelAction(format));
    };
    return (
        <div className="App">
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="">

                    <input className={"form-control"} type="file" onChange={(e) => setFile(e.target.files)}/>
                    <Button
                        disabled={file === null}
                        className="rounded-0 mt-4"
                        onClick={(e) => UploadExcel()}
                        variant="primary"
                    >
                        ساخت
                    </Button>
                    {error &&
                    (<p className="text-danger mt-4 ">{error?.file.map((item, i) => (<div key={i}>{item}</div>))}</p>)}
                </div>
            </form>
        </div>
    );
}
