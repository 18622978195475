import React, {useEffect} from "react";

export default function ParseJwt(token) {
    if (!token) {
        return;
    }
    const base64Url = token?.split(".");
    if (base64Url?.length > 1) {
        const base64 = base64Url[1].replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    } else {
        localStorage.removeItem("token");
        window.location.href = "/page-login"
    }
}

