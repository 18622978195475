export const authConstants = {
    REQUEST_OTP: "REQUEST_OTP",
    SUCCESS_OTP: "SUCCESS_OTP",
    VERIFY_OTP: "VERIFY_OTP",
    FAIL_OTP: "FAIL_OTP",
    LOGOUT: "LOGOUT",
};


export const EmailConstants = {
    REQUEST_EMAIL: "REQUEST_EMAIL",
    SUCCESS_EMAIL: "SUCCESS_EMAIL",
    FAIL_EMAIL: "FAIL_EMAIL",
};


export const verifyConst = {
    USER_verifyConst_REQUSET: "USER_verifyConst_REQUSET",
    USER_verifyConst_SUCCESS: "USER_verifyConst_SUCCESS",
    USER_verifyConst_FAIL: "USER_verifyConst_FAIL"
}