import {useSelector, useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {getOrderStaticAction, getUserInfoAction, userActions} from "../../../_actions/dashboard.actions"
import {Doughnut, Polar} from 'react-chartjs-2';
import {ERROR} from "../../../_helper";
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst"
import {useTranslation} from "react-i18next";

export default function GetOrderStatic({time, setTime}) {
    const [userData, setUserData] = useState([]);
    const getOrderStatic = useSelector((state) => state.getOrderStatic);
    const {orderStatic} = getOrderStatic;

    const {t} = useTranslation();

    const ChartUserInfo = useSelector((state) => state.ChartUserInfo);
    const {ChartUser} = ChartUserInfo;
    let isValid = ValidPermissions(PermissionConst.DASHBOARD_ORDERS_STATISTICS);


    let record = orderStatic?.data?.map((r) => r.records)
    const data = {
        labels: [t('PAID_KEY'), t('NOY_PAID_KEY')],
        datasets: [
            {
                label: '# of Votes',
                data: record,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    useEffect(() => {
        if (isValid) {
            dispatch(
                userActions.filterUser(time.value)
            )
                .then((r) => {
                    if (r.status === 200) {
                        setUserData(r?.data)
                    }
                })
                .catch((ex) =>
                    ERROR(ex.response?.data?.message || ex.message, {
                        error: ex,
                    })
                );
        }
    }, [time]);

    const dispatch = useDispatch();
    useEffect(() => {
        if (isValid) {
            dispatch(getOrderStaticAction())
            dispatch(getUserInfoAction())
        }
    }, []);


    let comment = userData?.data?.comment?.count
    let order = userData?.data?.order?.count
    let user = userData?.data?.user.count


    const dataUser = {
        labels: [t("USERS_KEY"), t("ORDERS_KEY"), t('COMMENT_KEY')],
        datasets: [
            {
                label: '# of Votes',
                data: [user, order, comment],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255, 206, 86, 0.5)',
                    'rgba(75, 192, 192, 0.5)',
                    'rgba(153, 102, 255, 0.5)',
                    'rgba(255, 159, 64, 0.5)',
                ],
                borderWidth: 1,

            },
        ],
    };

    return (
        <>
            <div className="mt-4 mb-5" style={{marginTop: "200px"}}>
                <h3 className={"mt-3 mb-5"}>{t('ORDERS_USERS_KEY')}</h3>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card p-5">
                            <Doughnut data={data}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card p-5">
                            <Polar data={dataUser}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}