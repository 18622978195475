export const pageConst = {
    GET_ALL_PAGE_REQUEST: "GET_ALL_PAGE_REQUEST",
    GET_ALL_PAGE_SUCCESS: "GET_ALL_PAGE_SUCCESS",
    GET_ALL_PAGE_FAIL: "GET_ALL_PAGE_FAIL",

    CREATE_PAGE_REQUEST: "CREATE_PAGE_REQUEST",
    CREATE_PAGE_SUCCESS: "CREATE_PAGE_SUCCESS",
    CREATE_PAGE_FAIL: "CREATE_PAGE_FAIL",

    EDIT_PAGE_REQUEST: "EDIT_PAGE_REQUEST",
    EDIT_PAGE_SUCCESS: "EDIT_PAGE_SUCCESS",
    EDIT_PAGE_FAIL: "EDIT_PAGE_FAIL",

    DETAILS_PAGE_REQUEST: "DETAILS_PAGE_REQUEST",
    DETAILS_PAGE_SUCCESS: "DETAILS_PAGE_SUCCESS",
    DETAILS_PAGE_FAIL: "DETAILS_PAGE_FAIL",

    DELETE_PAGE_REQUEST: "DELETE_PAGE_REQUEST",
    DELETE_PAGE_SUCCESS: "DELETE_PAGE_SUCCESS",
    DELETE_PAGE_FAIL: "DELETE_PAGE_FAIL",

}