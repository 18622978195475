import {useEffect} from 'react';
import PageTitle from "../../layouts/PageTitle";
import {useParams} from "react-router-dom"
import {productDetailsActions} from "../../../_actions/products-actions"
import {useDispatch, useSelector} from "react-redux"
import {Row, Col} from "react-bootstrap"
import ProgressBar from 'react-bootstrap/ProgressBar'
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import {useTranslation} from "react-i18next";

const ProductDetails = () => {
    const getProductDetails = useSelector((state) => state.getProductDetails)
    const {productDetails, loading} = getProductDetails
    const {id} = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    let isValid = ValidPermissions(PermissionConst.PRODUCT_SHOW)
    useEffect(() => {
        if (isValid) {
            dispatch(productDetailsActions(id))
        }
    }, []);

    console.log(productDetails, "productDetails")
    if (loading) {
        return <ProgressBar animated now={100}/>
    }
    return (
        <>
            <PageTitle activeMenu={t('DETAILS_KEY')} pageContent={t('DETAILS_KEY')} motherMenu={t("DASHBOARD_KEY")}
                       dashUrl={""} prev={t('ALL_PRODUCT_KEY    ')} prevUrl={"products"}/>
            <div className={`card p-5`}>
                <Row>
                    <Col xs={12} md={6}>
                        {productDetails?.data?.photo === null ? <span className={`bg-dark d-block h-100 w-100`}/> :
                            <img src={productDetails?.data?.photo} alt={productDetails?.data?.name_en}/>}
                    </Col>
                    <Col xs={12} md={6}>
                        <ul>
                            <li className={`mt-3`}>
                                <span className={`text-dark`}> {t('EN_NAME')} :</span>{" "}
                                {productDetails?.data?.name_en}
                            </li>
                            <li className={`mt-3`}>
                                <span className={"text-dark"}>{t('COUNTRY')} :</span>{" "}
                                {productDetails?.data?.country}
                            </li>
                            <li className={`mt-3`}>
                                <span className={"text-dark"}>{t('DATE_CREATE_KEY')} :</span>{" "}
                                {productDetails?.data?.created_at}
                            </li>
                            <li className={`mt-3`}>
                                <span className={"text-dark"}>{t('CURRENCY_NAME_KEY')} :</span>{" "}
                                {productDetails?.data?.currency_name}
                            </li>
                            <li className={`mt-3`}>
                                <span className={"text-dark"}>{t('PRICE_IN_DOLLARS')} :</span>{" "}
                                {productDetails?.data?.min_price}
                            </li>
                            <li className={`mt-3`}>
                                <span className={"text-dark"}>{t('PRICE_TOMAN_KEY')} :</span>{" "}
                                {productDetails?.data?.selling_price?.price.toLocaleString()}
                            </li>
                            <li className={`mt-3`}>
                                <span className={"text-dark"}>{t('SLUG_KEY')} :</span>{" "}
                                {productDetails?.data?.slug === null ? "توضیحات وجود ندارد" : productDetails?.data?.slug}
                            </li>
                            <li className={`mt-3`}>
                                <span className={"text-dark"}>{t('STATUS_KEY')} :</span>{" "}
                                {productDetails?.data?.status === "unpublished" ? (
                                    <span className="badge light badge-danger ">
                                                    <i className="fa fa-circle text-danger mr-1"/>
                                                    <span className={'mr-2'}>
                                                    </span>
                                        {t('UNPUBLISH_KEY')}
                                                    </span>

                                ) : (
                                    <span className="badge light badge-success ">
                                                    <i className="fa fa-circle text-success mr-1"/>
                                                    <span className={'mr-2'}>
                                                        {t('PUBLISH_KEY')}

                                                    </span>
                                                    </span>
                                )}
                            </li>
                            <li className={`mt-3`}>
                                <span className={"text-dark"}>{t('REVIEW_KEY')} :</span>{" "}
                                {productDetails?.data?.viewed}
                            </li>

                        </ul>
                        <ul className={"mt-3"}>
                            {productDetails?.data?.available_gifts?.data.map((gift, i) => (
                                <li key={i} className={"mt-2"}>
                                    <span className={"text-dark fs-14"}> {t('Code_KEY')} {++i} : </span>{" "}
                                    <span className={"fs-14 "} style={{letterSpacing: " 2px",}}>{gift?.code}</span>
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>
                <div>
                    <h3 className={"mt-4 mb-4"}>{t('ABOUT_THIS_PRODUCT_KEY')}:</h3>
                    <p dangerouslySetInnerHTML={{__html: productDetails?.data?.description}}/>
                </div>

            </div>
        </>
    );
};

export default ProductDetails;
