import {reviewConst} from "../_constants/reviews.contant"
import {_http, ERROR, SUCCESS} from "../_helper";
import {categoriesEdit, categoryConstant} from "../_constants/category.constant";
///get all reviews

export const getAllREviews = () => async (dispatch) => {
    dispatch({type: reviewConst.GET_ALL_REVIEW_REQUEST})

    await _http("admin/reviews", {method: "get"}).then((res) => {
        if (res.status === 200) {
            dispatch({type: reviewConst.GET_ALL_REVIEW_SUCCESS, payload: res?.data})
        } else {
            ERROR(res.data.message)
            dispatch({type: reviewConst.GET_ALL_REVIEW_FAIL, payload: res})
        }
    }).catch((error) => {
        let message;
        if (error?.response?.data?.message) message = error?.response?.data?.message;
        ERROR(message);
        dispatch({type: reviewConst.GET_ALL_REVIEW_FAIL, payload: error})
    })
}

//get review delete

export const deleteReviews = (id) => async (dispatch) => {
    dispatch({type: reviewConst.DELETE_REVIEW_REQUEST, payload: id})
    await _http(`admin/reviews/${id}`, {method: "Delete"}).then((res) => {
        if (res.status === 200) {
            SUCCESS("عملیات موفقیت آمیز بود")
        } else {
            ERROR(res.response.data.message)
            dispatch({type: reviewConst.DELETE_REVIEW_SUCCESS, payload: res})
        }
    }).catch((error) => {
        let message;
        if (error?.response?.data?.message) message = error?.response?.data?.message
        ERROR(message)
        dispatch({type: reviewConst.DELETE_REVIEW_FAIL, payload: error})
    })
}


//get review details
export const getReviewsDetails = (id) => async (dispatch) => {
    dispatch({type: reviewConst.GET_DETAILS_REVIEW_REQUEST, payload: id})
    await _http(`admin/reviews/${id}`, "get").then((res) => {
        if (res.status === 200) {
            dispatch({type: reviewConst.GET_DETAILS_REVIEW_SUCCESS, payload: res.data?.data})
        } else {
            ERROR(res?.response?.data?.message)
            dispatch({type: reviewConst.GET_DETAILS_REVIEW_FAIL, payload: res.response.error})
        }
    }).catch((error) => {
        let message;
        if (error?.response?.data?.message) message = error?.response?.data?.message
        ERROR(message)
        dispatch({type: reviewConst.GET_DETAILS_REVIEW_FAIL, payload: error})
    })
}


//edit reviews

export const editReviews = (data, id) => async (dispatch) => {
    dispatch({type: reviewConst.EDIT_REVIEW_REQUEST, payload: data, id})
    await _http(`admin/reviews/${id}`, {method: "post", data, id}).then((res) => {
        if (res.status === 200) {
            SUCCESS("ویرایش با موفقیت انجام شد")
            // window.location.href = "/reviews"
            dispatch({type: reviewConst.EDIT_REVIEW_SUCCESS, payload: res})
        } else {
            ERROR(res.response?.data?.errors)
            dispatch({type: reviewConst.EDIT_REVIEW_FAIL, payload: res})
        }
    }).catch((error) => {
        let message;
        if (error?.response?.data?.message)
            message = error?.response?.data?.message;
        else message = error?.message;
        ERROR(message)
        dispatch({type: reviewConst.EDIT_REVIEW_FAIL, payload: error?.response?.data?.errors})
    })
}


//get review List
function getReviewPage(page) {
    return () => {
        return _http(`admin/reviews?page=${page}`, {method: "get"});
    };
}

export const reviewActions = {
    getReviewPage,
}


