import React, { useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";

export const BaseInput = ({
  id,
  dir = "",
  label = "",
  error = "",
  type = "text",
  note,
  errorText,
  noteColor = "invalid",
  ...rest
}) => {
  const [_id] = useState(id || `basic-input-${Math.random()}`);

  return (
    <FormGroup className={!!dir ? `dir-${dir}` : ""}>
      {label && (
        <Label className="fs-12" for={_id}>
          {label}
        </Label>
      )}
      <Input type={type} id={_id} {...rest} />
      {error && (
        <div className="text-right ml-auto text_align_lang">
          <span style={{ fontSize: "0.7rem" }} className="text-danger mb-4">
            {errorText}
          </span>
        </div>
      )}
    </FormGroup>
  );
};
