import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { masterOrderActions } from "../../../_actions/order-actions"
import Pagination from "react-js-pagination";
import { ERROR } from "../../../_helper";
import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom"
import ValidPermissions from "../../../_helper/ValidPermissions";
import { PermissionConst } from "../../../_constants/PermissionConst";
import { useTranslation } from "react-i18next";

const MasterCardTable = ({ orders, loading, error }) => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [orderTotal, setOrderTotal] = useState(1);
    const [orderData, setOrderData] = useState([]);

    const [sort, setSort] = useState("");


    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handlePageChange = async (pageNumber) => {
        setPage(pageNumber);
        const page = pageNumber;
        dispatch(
            masterOrderActions.getMasterPage(page)
        )
            .then((r) => {
                if (r.status === 200) {
                    window.scroll(0, 0)
                    setOrderData(r)
                    setOrderTotal(r.data?.meta?.total);
                }
            })
            .catch((ex) =>
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                })
            );
    }
    let isValid = ValidPermissions(PermissionConst.ORDER_LIST)

    useEffect(() => {
        if (isValid) {
            dispatch(
                masterOrderActions.getMasterPage(page)
            )
                .then((r) => {
                    if (r.status === 200) {
                        setOrderData(r)
                        setOrderTotal(r.data?.meta?.total);
                    }
                })
                .catch((ex) =>
                    ERROR(ex.response?.data?.message || ex.message, {
                        error: ex,
                    })
                );
        }

    }, [page]);
    const handleSearch = (e) => {
        e.preventDefault()
        dispatch(
            masterOrderActions.filterMaster(search)
        ).then((r) => {
            if (r.status === 200) {
                setOrderData(r)
            }
        })
            .catch((ex) =>
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                })
            );
    }

    const handleSortOrder = (sort, type) => {
        dispatch(masterOrderActions.sortMaster(sort, type)).then((r) => {
            if (r.status === 200) {
                setOrderData(r)
            }
        }).catch((ex) => {
            ERROR(ex.response?.data?.message || ex.message, {
                error: ex,
            })
        })

    }
    const handelSelect = (selected) => {
        setSort(selected)
    }
    const objectArray = [
        { name: "جدیدترین ها" },
    ];
    const style = {
        chips: {
            background: "#2c254a",
            display: "flex"
        },
        searchBox: {
            border: "0.5px solid #faf8f9",
            "border-radius": "10px",
            display: "flex"
        },
        multiselectContainer: {
            color: "#000",
        },
        inputField: {
            margin: " 5px",
            textAlign: "right",
        },
        optionContainer: {
            border: " 1px solid",
        },
        option: {
            color: "#000",
            textAlign: "right",
            display: "flex"
        },
        groupHeading: {}
    };
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <div className={`d-flex justify-content-between align-items-center w-100`}>
                        {!loading && <h4 className="card-title"> {'لیست سفارشات مستر کارت'}</h4>}
                        {loading && <div className={"text-center"}><Spinner animation="grow" variant="success" />
                            <Spinner animation="grow" variant="danger" />
                            <Spinner animation="grow" variant="warning" />
                            <Spinner animation="grow" variant="info" />
                            <Spinner animation="grow" variant="light" />
                            <Spinner animation="grow" variant="dark" /></div>}
                        <div>
                        </div>
                        <form className={"d-flex"} onSubmit={handleSearch}>
                            <input className={"form-control rounded-0"} type="search" placeholder={t('SEARCH_KEY')}
                                onChange={(e) => setSearch(e.target.value)} />
                            <button type={"submit"} className={"btn btn-primary rounded-0"}>
                                <i className={"fa fa-search "} />
                            </button>
                        </form>
                    </div>
                </div>
                <div className="card-body">
                    <div className="w-100 table-responsive">
                        <div id="patientTable_basic_table" className="dataTables_wrapper">
                            <table
                                id="example5"
                                className=" dataTable no-footer w-100  table-striped  table-hover "
                                style={{ minWidth: 845 }}
                                role="grid"
                                aria-describedby="example5_info"
                            >
                                <thead>
                                    <tr role="row">
                                        <th
                                            className="sorting text-center"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Patient ID: activate to sort column ascending"
                                        >
                                            {t('Id')}

                                        </th>
                                        <th
                                            className="sorting text-center"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Patient ID: activate to sort column ascending"
                                        >
                                            {t('DATE_ORDERS')}

                                        </th>

                                        <th
                                            className="sorting text-center"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Patient Name: activate to sort column ascending"
                                        >
                                            {t('PAID_KEY')}
                                        </th>
                                        <th
                                            className="sorting text-center"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Doctor Assgined: activate to sort column ascending"
                                        >
                                            {t('STATUS_ORDERS_KEY')}
                                        </th>

                                        <th
                                            className="sorting text-center"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Disease: activate to sort column ascending"
                                        >{t('FEE_PAYABLE')}
                                        </th>
                                        <th
                                            className="sorting text-center"
                                            tabIndex={0}
                                            aria-controls="example5"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Disease: activate to sort column ascending"
                                        >
                                            {t('PORTAL_BANK_KEY')}
                                        </th>
                                        <th
                                            className="sorting text-center"
                                            tabIndex={0}
                                            aria-controls="example7"
                                            rowSpan={1}
                                            colSpan={1}
                                            aria-label="Disease: activate to sort column ascending"
                                        >
                                            {t('DETAILS_KEY')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderData?.data?.data?.map((order) => (
                                        <>
                                            <tr key={order.id} role="row" className="odd">
                                                <td className={'text-center'}>{order?.id}</td>
                                                <td className={'text-center'}>{order?.created_at}</td>

                                                <td className={'text-center'}>

                                                    {order?.is_paid === 1 ? (
                                                        <>

                                                            <span className="badge light badge-success ">
                                                                <i className="fa fa-check text-success mr-1" />
                                                                <span className={'mr-2'}>
                                                                    {t('PAID_KEY')}
                                                                </span>
                                                            </span>
                                                        </>
                                                    ) : order?.is_paid === 0 ? (
                                                        <>
                                                            <span className="badge light badge-danger ">
                                                                <i className="fa fa-times text-danger mr-1" />
                                                                <span className={'mr-2'}>
                                                                    {t('NOY_PAID_KEY')}
                                                                </span>
                                                            </span>
                                                        </>
                                                    ) : null}
                                                </td>
                                                <td className={'text-center'}>
                                                    {order?.status === "processing" ? (
                                                        <>
                                                            <span className="badge light badge-warning ">
                                                                <i className="fa fa-check text-warning mr-1" />
                                                                <span className={'mr-2'}>
                                                                    {t('PROCESSING')}
                                                                </span>
                                                            </span>
                                                        </>
                                                    ) : order?.status === "not_delivered" ? (
                                                        <>
                                                            <span className="badge light badge-warning ">
                                                                <i className="fa fa-times text-warning mr-1" />
                                                                <span className={'mr-2'}>
                                                                    {t('NOT_DELIVERED')}
                                                                </span>
                                                            </span>
                                                        </>
                                                    ) : order?.status === "sent" ? (
                                                        <>
                                                            <span className="badge light badge-success ">
                                                                <i className="fa fa-times text-success mr-1" />
                                                                <span className={'mr-2'}>
                                                                    {t('SENT')}
                                                                </span>
                                                            </span>
                                                        </>
                                                    ) : null}
                                                </td>
                                                <td className={'text-center text-warning'}> تومان {order?.payment_amount?.toLocaleString()}</td>
                                                <td className={'text-center '}>{order?.payment_driver ? order?.payment_driver : "---"}</td>

                                                <td className={'text-center'}>
                                                    <Link className={"btn btn-primary btn-xxs"}
                                                        to={`orders/${order.id}`}>{t('DETAILS_KEY')}</Link>
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                            <div
                                className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                                <div className="dataTables_info">
                                    {/*   */}
                                </div>
                                <div
                                    className="dataTables_paginate paging_simple_numbers"
                                    id="example5_paginate"
                                >
                                    <div className="d-inline-block pb-3 ltr_direction">
                                        <Pagination
                                            activePage={page}
                                            itemsCountPerPage={orders?.meta?.per_page}
                                            totalItemsCount={orderTotal}
                                            pageRangeDisplayed={10}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default MasterCardTable;
