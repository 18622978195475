import {productConstant, createProductConst} from "../_constants/product-constant"

//get all product
export const getAllProductReducer = (state = {products: []}, action) => {
    switch (action.type) {
        case productConstant.GET_ALL_PRODUCT_REQUEST :
            return {loading: true}
        case productConstant.GET_ALL_PRODUCT_SUCCESS :
            return {loading: false, products: action.payload}
        case productConstant.GET_ALL_PRODUCT_FAIL :
            return {loading: false, error: action.payload}
        default :
            return state
    }
}

//productDetails

export const productDetailsReducer = (state = {productDetails: []}, action) => {
    switch (action.type) {
        case productConstant.DETAILS_PRODUCT_REQUEST:
            return {loading: true}
        case productConstant.DETAILS_PRODUCT_SUCCESS:
            return {loading: false, productDetails: action.payload}
        case productConstant.DETAILS_PRODUCT_FAIL :
            return {loading: false, error: action.payload}
        default :
            return state

    }
}

///create category

export const createProductReducer = (state = {}, action) => {
    switch (action.type) {
        case  createProductConst.CREATE_PRODUCT_REQUEST :
            return {loading: true}
        case createProductConst.CREATE_PRODUCT_SUCCESS :
            return {loading: false, success: true}
        case createProductConst.CREATE_PRODUCT_FAIL :
            return {loading: false, error: action.payload}
        default :
            return state
    }
}


///edit category
export const editProductReducer = (state = {}, action) => {
    switch (action.type) {
        case  createProductConst.EDIT_PRODUCT_REQUEST :
            return {loading: true}
        case createProductConst.EDIT_PRODUCT_SUCCESS :
            return {loading: false, success: true}
        case createProductConst.EDIT_PRODUCT_FAIL :
            return {loading: false, error: action.payload}
        default :
            return state
    }
}