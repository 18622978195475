import {couponConstructor} from "../_constants/coupon.constant";
// get All copuns
export const getAllCouponsReducers = (state = {Coupons: []}, action) => {

    switch (action.type) {
        case couponConstructor.GET_ALL_COUPON_REQUEST :
            return {loading: true}

        case couponConstructor.GET_ALL_COUPON_SUCCESS:
            return {loading: false, Coupons: action.payload}

        case couponConstructor.GET_ALL_COUPON_FAIL:
            return {loading: false, error: action.payload}
        default :
            return state

    }
}


//details coupon
export const detailsCouponsReducers = (state = {details: []}, action) => {

    switch (action.type) {
        case couponConstructor.DETAILS_COUPON_REQUEST:
            return {loading: true}

        case couponConstructor.DETAILS_COUPON_SUCCESS :
            return {loading: false, details: action.payload}

        case couponConstructor.GET_ALL_COUPON_FAIL :
            return {loading: false, error: action.payload}

        default :
            return state

    }
}


//create coupon

export const createCouponsReducers = (state = {}, action) => {

    switch (action.type) {
        case couponConstructor.CREATE_COUPON_REQUEST :
            return {loading: true}

        case couponConstructor.CREATE_COUPON_SUCCESS:
            return {loading: false, success: true}

        case couponConstructor.CREATE_COUPON_FAIL :

            return {loading: false, error: action.payload}

        default :
            return state

    }
}
//update coupon

export const editCouponsReducers = (state = {}, action) => {

    switch (action.type) {
        case couponConstructor.EDIT_COUPON_REQUEST :
            return {loading: true}

        case couponConstructor.EDIT_COUPON_SUCCESS:
            return {loading: false, success: true}

        case couponConstructor.EDIT_COUPON_FAIL :

            return {loading: false, error: action.payload}

        default :
            return state

    }
}
