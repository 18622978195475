import {pageConst} from "../_constants/PageConst";
import {getAllProductReducer} from "./product-reducer";

//get ALl Product
export const getAllPageReducer = (state = {pages: []}, action) => {

    switch (action.type) {
        case  pageConst.GET_ALL_PAGE_REQUEST :
            return {loading: true}

        case pageConst.GET_ALL_PAGE_SUCCESS:
            return {loading: false, pages: action.payload}

        case  pageConst.GET_ALL_PAGE_FAIL :
            return {loading: false, error: action.payload}

        default :
            return state
    }
}

//Create Product
export const createPageReducer = (state = {}, action) => {

    switch (action.type) {
        case  pageConst.CREATE_PAGE_REQUEST :
            return {loading: true}

        case pageConst.CREATE_PAGE_SUCCESS:
            return {loading: false, success: true}

        case  pageConst.CREATE_PAGE_FAIL :
            return {loading: false, error: action.payload}

        default :
            return state
    }
}

//Create Product
export const updatePageReducer = (state = {}, action) => {

    switch (action.type) {
        case  pageConst.EDIT_PAGE_REQUEST :
            return {loading: true}

        case pageConst.EDIT_PAGE_SUCCESS:
            return {loading: false, success: true}

        case  pageConst.EDIT_PAGE_FAIL :
            return {loading: false, error: action.payload}

        default :
            return state
    }
}

//edit Product
export const detailPageReducer = (state = {details: []}, action) => {

    switch (action.type) {
        case  pageConst.DETAILS_PAGE_REQUEST :
            return {loading: true}

        case pageConst.DETAILS_PAGE_SUCCESS:
            return {loading: false, details: action.payload}

        case  pageConst.DETAILS_PAGE_FAIL :
            return {loading: false, error: action.payload}

        default :
            return state
    }
}

//Delete Product
export const deletePageReducer = (state = {}, action) => {
    switch (action.type) {
        case  pageConst.DELETE_PAGE_REQUEST :
            return {loading: true}

        case pageConst.DELETE_PAGE_SUCCESS:
            return {loading: false, success: true}

        case  pageConst.DELETE_PAGE_FAIL :
            return {loading: false, error: action.payload}

        default :
            return state
    }

}