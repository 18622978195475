import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {usersDetailsActions, getUserTransactionsActions, getUserCreditActions} from "../../../_actions/user.Action"
import {useParams} from "react-router-dom"
import PageTitle from "../../layouts/PageTitle";
import {DefaultButton} from "../Defaults/DefaultButton";
import {BaseInput} from "../Defaults/BaseInput";
import {NumberInput} from "../Defaults/NumberInput";
import {chargeWallet} from "../../../_actions/wallet-actions";
import UserTransaction from "./UserTransaction";
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import {useTranslation} from "react-i18next";

const UserDetails = () => {
    const [amount, setAmount] = useState("");
    const [description, setDescription] = useState("");
    const [operation, setOperation] = useState("");
    const {t} = useTranslation();
    const dir = localStorage.getItem("Layout");
    const DetailsUser = useSelector((state) => state.DetailsUser)
    const {userDetails} = DetailsUser;
    //Transaction
    const createTransaction = useSelector((state) => state.createTransaction)
    const {success, error, loading} = createTransaction;

    ///get Transaction
    const getTransaction = useSelector((state) => state.getTransaction)
    const {transaction} = getTransaction;
    ///get credit
    const getCredit = useSelector((state) => state.getCredit)
    const {credit} = getCredit;

    const handleSelect = (e) => {
        setOperation(e.target.value)
    }
    const {id} = useParams();
    const dispatch = useDispatch()

    let isValidShow = ValidPermissions(PermissionConst.USER_SHOW);
    let isValidTrans = ValidPermissions(PermissionConst.USER_SEE_USER_TRANSACTIONS);
    let isValidCredit = ValidPermissions(PermissionConst.USER_SEE_USER_ACCOUNT_CREDIT_BALANCE);


    useEffect(() => {
        if (isValidShow) {
            dispatch(usersDetailsActions(id))
        }
        if (isValidTrans) {
            dispatch(getUserTransactionsActions(id))
        }
        if (isValidCredit) {
            dispatch(getUserCreditActions(id))
        }
    }, []);


    const handleWallet = () => {
        const data = {
            amount: amount.replaceAll(",", ""),
            description: description,
            operation: operation,
            user_id: id,
        }
        dispatch(chargeWallet(data, id));
    }
    return (
        <>
            <PageTitle activeMenu={t('USERS_LIST_KEY')} prev={t('USERS_KEY')} pageContent={t('USERS_LIST_KEY')}
                       motherMenu={t('DASHBOARD_KEY')}
                       prevUrl={"users"}/>


            <div className="row">
                {ValidPermissions(PermissionConst.USER_CAN_DEPOSIT_OR_WITHDRAW_FROM_USER_ACCOUNT) ? (<>
                    <div className={`card mb-2 p-5 col-xs-12 col-sm-12 col-md-12 col-lg-6`}>
                        <h3>{t('CHARGE_WALLET_KEY')}</h3>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row">
                                <div className=" col-xs-12 col-md-6">
                                    <BaseInput
                                        className="form-control text-right rtl_direction"
                                        id="description"
                                        type="text"
                                        value={description}
                                        placeholder={t('DESCRIPTION_KEY')}
                                        onChange={(e) => setDescription(e.target.value)}
                                        label={t('DESCRIPTION_KEY')}
                                    />
                                    {error?.description && <p className={"text-danger"}>{error.description}</p>}
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <NumberInput
                                        htmlFor
                                        id="current"
                                        label={"مبلغ"}
                                        placeholder={t('AMOUNT_DESCRIPTION_KEY')}
                                        name="current"
                                        className="form-control"
                                        allowLeadingZeros={true}
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                    {error?.amount && <p className={"text-danger"}>{error.amount}</p>}

                                </div>
                                <div className="col-xs-12 col-md-6 mt-2">
                                    <select  onChange={handleSelect} name="deposit" id="deposit"
                                            className={"w-100 rounded p-2 border-1 bg-transparent rounded-2 "}
                                            style={{height: "40px", color: "#ccc"}}>
                                        <option value="">{t('CHOOSE_KEY')}</option>
                                        <option value="deposit">{t('DEPOSIT_KEY')}</option>
                                        <option value="withdraw">{t('WITHDRAW_KEY')}</option>
                                    </select>
                                    {error?.operation && <p className={"text-danger mt-4"}>{error?.operation}</p>}
                                </div>
                                <div className="col-xs-12 col-md-6 mt-2">
                                    <DefaultButton
                                        type="submit"
                                        className={`Approve_btn btn-primary  w-100`}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleWallet()}>
                                        {t('SAVE_KEY')}
                                    </DefaultButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </>) : null}
                {ValidPermissions(PermissionConst.USER_SEE_USER_ACCOUNT_CREDIT_BALANCE) ? (<>
                    <div
                        className={dir === "rtl" ? "card  p-5 col-xs-12 col-sm-12 col-md-12 col-lg-5 mr-3" : "card  p-5 col-xs-12 col-sm-12 col-md-12 col-lg-5 ml-3"}>
                        <h3 className={"fs-13"}>{t('WALLET_BALANCE_KEY')}</h3>
                        <p className={'text-center fs-26 text-success mt-4 border p-2 d-inline-block'}>{parseInt(credit?.data?.balance)?.toLocaleString()}</p>
                    </div>
                </>) : null}
            </div>


            {ValidPermissions(PermissionConst.USER_SHOW) ? (<>
                <div className="row ">
                    <div className={`card p-5 col-xs-12 col-sm-12 col-md-12 col-lg-6 `}>
                        <ul>
                            <li className={"m-3 d-flex justify-content-between align-items-center border-bottom pb-3"}>
                                <span>{t('EMAIL_KEY')} :</span>{" "}
                                {userDetails?.data?.email}
                            </li>
                            <li className={"m-3 d-flex justify-content-between align-items-center border-bottom pb-3"}>
                                <span>{t('DATE_SIGN_KEY')}:</span>{" "}
                                {userDetails?.data?.created_at}
                            </li>
                            <li className={"m-3 d-flex justify-content-between align-items-center border-bottom pb-3"}>
                                <span> {t('MOBILE_KEY')} :</span>{" "}
                                {userDetails?.data?.mobile}
                            </li>
                            <li className={"m-3 d-flex justify-content-between align-items-center "}>
                                <span> {t('ROLE_KEY')} :</span>{" "}
                                {userDetails?.data?.roles && userDetails?.data?.roles.map((rol) => (
                                    <div className={rol ? `btn btn-primary` : `btn btn-secundray`}>
                                        {rol.name}
                                    </div>
                                ))}
                                {userDetails?.data?.roles === [] ? <div>{t('Dont Role')}</div> : null}
                            </li>
                        </ul>
                    </div>
                </div>
            </>) : null}

            {ValidPermissions(PermissionConst.USER_SEE_USER_TRANSACTIONS) ? (<>
                <div className="row">
                    <UserTransaction id={id} transaction={transaction}/>
                </div>
            </>) : null}
        </>
    );
};

export default UserDetails;
