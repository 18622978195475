import {useEffect, useState} from 'react';
import PageTitle from "../../layouts/PageTitle";
import {Col, Row} from "react-bootstrap";
import {BaseInput} from "../Defaults/BaseInput";
import {createUserActions, getPermissionUsers} from "../../../_actions/user.Action"
import {useSelector, useDispatch} from "react-redux"
import {DefaultButton} from "../Defaults/DefaultButton";
import {Multiselect} from "multiselect-react-dropdown";
import {useTranslation} from "react-i18next";

const CreateUser = () => {
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [rol, setRol] = useState([]);
    const [permission, setPermission] = useState([]);
    const {t} = useTranslation();

    const createUser = useSelector((state) => state.createUser)
    const {loading, error} = createUser;
    const getPermission = useSelector((state) => state.getPermission)
    const {PermissionData} = getPermission;
    const dir = localStorage.getItem("Layout");


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPermissionUsers())

    }, [])
    let per = permission?.map((item) => item.name)
    let role = rol?.map((item) => item.rol)
    const handleCrateUSer = (e) => {
        e.preventDefault()
        const data = {
            email: email,
            mobile: mobile,
            roles_name: role,
            permissions: per,
            country_code: "IR"

        }
        dispatch(createUserActions(data))

    }
    const objectArray = [
        {rol: "admin", name: dir === "rtl" ? "ادمین" : "Admin"},
        {rol: "stuff", name: dir === "rtl" ? "اپراتور" : "Operator"},
        {rol: "ewano_admin", name: dir === "rtl" ? "ادمین ایوانو" : "Ewano Admin"},

    ];
    const handleSelect = (selected) => {
        setRol(selected)
    }
    const handlePermission = (selected) => {
        setPermission(selected)
    }
    const style = {
        chips: {
            background: "#2c254a",
            display: "flex",
            flexWrap: "wrap"
        },
        searchBox: {
            border: "0.5px solid #faf8f9",
            "border-radius": "10px",
            display: "flex",
            flexWrap: "wrap"
        },
        multiselectContainer: {
            color: "#000",
        },
        inputField: {
            margin: " 5px",
            textAlign: "right",
        },
        optionContainer: {
            border: " 1px solid",
        },
        option: {
            color: "#000",
            textAlign: "right",
            display: "flex",
            flexWrap: "wrap"
        },
        groupHeading: {}
    };

    return (
        <div>

            <PageTitle activeMenu={t('USERS_NEW_KEY')} prev={t('USERS_KEY')} pageContent={t('USERS_LIST_KEY')}
                       motherMenu={t('DASHBOARD_KEY')}
                       prevUrl={"users"}/>

            <div className="card p-5">
                <form onSubmit={handleCrateUSer}>
                    <Row>
                        <Col md={4} xs={12}>
                            <BaseInput
                                className="form-control text-right rtl_direction"
                                id="email"
                                type="text"
                                value={email}
                                placeholder={t('EMAIL_KEY')}
                                onChange={(e) => setEmail(e.target.value)}
                                label={t('EMAIL_KEY')}
                            />
                            {error?.email && (<span className={"text-danger"}>{error.email}</span>)}
                        </Col>
                        <Col md={4} xs={12}>
                            <BaseInput
                                className="form-control text-right rtl_direction"
                                id="mobile"
                                type="text"
                                value={mobile}
                                placeholder={"9120000000"}
                                onChange={(e) => setMobile(e.target.value)}
                                label={t('MOBILE_KEY')}
                            />
                            {error?.mobile && (<span className={"text-danger"}>{error.mobile}</span>)}
                        </Col>
                        <Col xs={12} md={4}>
                            <label>{t('ROLE_KEY')}</label>
                            <Multiselect
                                options={objectArray}
                                displayValue="name"
                                placeholder={t('ROLE_KEY')}
                                onSelect={handleSelect}
                                style={style}
                                selectedValues={rol}
                            />
                            {error?.rolse && (<span className={"text-danger"}>{error.rolse}</span>)}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <label>{t('PERMISSION_KEY')}</label>
                            <Multiselect
                                options={PermissionData?.data?.permissions}
                                displayValue="name_fa"
                                placeholder={t('PERMISSION_KEY')}
                                onSelect={handlePermission}
                                style={style}
                                selectedValues={permission}
                            />
                            {error?.rolse && (<span className={"text-danger"}>{error.rolse}</span>)}
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-center ">
                        <DefaultButton
                            loading={loading}
                            type="submit"
                            className={`Approve_btn btn-primary mt-4 d-block`}
                            variant="contained"
                            color="primary"
                        >
                            {t('SAVE_KEY')}
                        </DefaultButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateUser;
