import React, {useState, useEffect} from "react";
import {Table, Dropdown} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {productActions} from "../../../_actions/products-actions"
import Pagination from "react-js-pagination";
import {ERROR} from "../../../_helper";
import Spinner from 'react-bootstrap/Spinner'
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import {useTranslation} from "react-i18next";

const ProductTable = ({loading, products, error}) => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [productData, setProductData] = useState([]);
    const [productsTotal, setProductsTotal] = useState(1);

    const {t} = useTranslation();
    const dir = localStorage.getItem("Layout");
    const dispatch = useDispatch();
    console.log(search,page)
    const handlePageChange = async (pageNumber) => {
        setPage(pageNumber);
        const page = pageNumber;
        dispatch(
            productActions.getProductList({page,search})
        )
            .then((r) => {
                if (r.status === 200) {
                    window.scroll(0, 0)
                    setProductData(r)
                    setProductsTotal(r.data?.meta?.total);
                }
            })
            .catch((ex) =>
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                })
            );
    }
    let isValid = ValidPermissions(PermissionConst.PRODUCT_LIST)
    useEffect(() => {
        if (isValid) {
            dispatch(
                productActions.getProductList({page,search})
            )
                .then((r) => {
                    if (r.status === 200) {
                        setProductData(r)
                        setProductsTotal(r.data?.meta?.total);
                    }
                })
                .catch((ex) =>
                    ERROR(ex.response?.data?.message || ex.message, {
                        error: ex,
                    })
                );
        }
    }, [page]);

    const handelSearch = (e) => {
        e.preventDefault()
        dispatch(
            productActions.getProductList({page,search})
        )
            .then((r) => {
                if (r.status === 200) {
                    setProductData(r)
                    setProductsTotal(r.data?.meta?.total);
                }
            })
            .catch((ex) =>
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                })
            );
    }


    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <div className={`d-flex justify-content-between align-items-center w-100`}>
                        {!loading && <h4 className="card-title"> {t('ALL_PRODUCT_KEY')}</h4>}
                        {loading && <div className={"text-center"}><Spinner animation="grow" variant="success"/>
                            <Spinner animation="grow" variant="danger"/>
                            <Spinner animation="grow" variant="warning"/>
                            <Spinner animation="grow" variant="info"/>
                            <Spinner animation="grow" variant="light"/>
                            <Spinner animation="grow" variant="dark"/></div>}
                        <form className={"d-flex"} onSubmit={handelSearch}>
                            <input className={"form-control rounded-0"} type="search" placeholder={t('SEARCH_KEY')}
                                   onChange={(e) => setSearch(e.target.value)}/>
                            <button type={"submit"} className={"btn btn-primary rounded-0"}>
                                <i className={"fa fa-search "}/>
                            </button>
                        </form>
                    </div>
                </div>
                <div className="card-body">
                    <div className="w-100 table-responsive">
                        <div id="patientTable_basic_table" className="dataTables_wrapper">
                            <table
                                id="example5"
                                className="display dataTable no-footer w-100  table-striped  table-hover "
                                style={{minWidth: 845}}
                                role="grid"
                                aria-describedby="example5_info"
                            >
                                <thead>
                                <tr role="row">
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient ID: activate to sort column ascending"
                                    >
                                      {t('ID_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient ID: activate to sort column ascending"
                                    >
                                        {t('PHOTO_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Date Check in: activate to sort column ascending"
                                    >
                                        {t('PRICE_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Date Check in: activate to sort column ascending"
                                    >
                                        {t('PRICE_IN_DOLLARS')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient Name: activate to sort column ascending"
                                    >
                                        {t('STATUS_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Doctor Assgined: activate to sort column ascending"
                                    >
                                        {t('SLUG_KEY')}
                                    </th>

                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Disease: activate to sort column ascending"
                                    >
                                        {t('FA_NAME')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Disease: activate to sort column ascending"
                                    >
                                        {t('EN_NAME')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example6"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Disease: activate to sort column ascending"
                                    >
                                        {t('COMMISSION_PERCENTAGE')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example6"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Disease: activate to sort column ascending"
                                    >
                                        {t('GIFT_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example7"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Disease: activate to sort column ascending"
                                    >
                                        {t('ACTIONS_KEY')}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {productData?.data?.data?.map((item) => (
                                    <>
                                        <tr key={item.id} role="row" className="odd">
                                            <td className={item.biller_id!=null?"text-center":'text-danger text-center'}>{item.id}</td>
                                            <td className={'text-center'}>
                                                {item.photo === null ? <span
                                                        className="bg-dark d-block"
                                                        style={{height: "80px", width: "80px"}}/> :
                                                    <img height="100px" src={item.photo} alt={item.name_en}/>}
                                            </td>
                                            <td className={'text-center'}>
                                                {item?.selling_price?.currency_name}
                                                {" "}
                                                {item?.selling_price?.price.toLocaleString()}
                                            </td>
                                            <td className={'text-center text-success'}>
                                                {item?.min_price}
                                            </td>
                                            <td className={'text-center'}>
                                                {item.status === "unpublished" ? (
                                                    <>
                                                    <span className="badge light badge-danger ">
                                                    <i className="fa fa-circle text-danger mr-1"/>
                                                    <span className={'mr-2'}>
                                                        {t('UNPUBLISH_KEY')}
                                                    </span>
                                                    </span>
                                                    </>
                                                ) : item.status === "published" ? (
                                                    <>
                                                    <span className="badge light badge-success ">
                                                    <i className="fa fa-circle text-success mr-1"/>
                                                    <span className={'mr-2'}>
                                                        {t('PUBLISH_KEY')}
                                                    </span>
                                                    </span>
                                                    </>
                                                ) : null}
                                            </td>
                                            <td className={'text-center'}>{item.slug === null ? "ندارد" : item.slug}</td>
                                            <td className={'text-center'}>{item.name ? item.name : "----"}</td>
                                            <td className={'text-center'}>{item.name_en}</td>
                                            <td className={'text-center'}>{item.percentage_fee}</td>
                                            <td className={'text-center'}>{item.gifts?.length}</td>
                                            <td className={'text-center'}>
                                                <Dropdown className="dropdown ml-auto ">
                                                    <Dropdown.Toggle
                                                        variant=""
                                                        className="btn-link i-false"
                                                        data-toggle="dropdown"
                                                    >
                                                        <svg
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                strokeWidth={1}
                                                                fill="none"
                                                                fillRule="evenodd"
                                                            >
                                                                <rect x={0} y={0} width={24} height={24}/>
                                                                <circle fill="#000000" cx={5} cy={12} r={2}/>
                                                                <circle fill="#000000" cx={12} cy={12} r={2}/>
                                                                <circle fill="#000000" cx={19} cy={12} r={2}/>
                                                            </g>
                                                        </svg>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                        <Dropdown.Item
                                                            className={'d-flex align-items-center justify-content-center'}
                                                            href={`products/${item.id}`}
                                                        >
                                                            <i className={ dir === "rtl" ? "fa fa-info text-secondary fa-2x ml-3" : "fa fa-info text-secondary fa-2x mr-3"}/>                                                           {t('DETAILS_KEY')}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            href={`new-products/${item.id}`}
                                                            className={'d-flex align-items-center justify-content-center'}
                                                        >
                                                            <i className={dir === "rtl" ?"fa fa-edit text-warning fa-2x ml-3" : "fa fa-edit text-warning fa-2x mr-3"}/>
                                                            {t('EDIT_KEY')}
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                    </>
                                ))}
                                </tbody>
                            </table>
                            <div
                                className="mt-3">
                                <div className="dataTables_info">

                                </div>
                                <div
                                    className="dataTables_paginate paging_simple_numbers d-flex justify-content-center"
                                    id="example5_paginate"
                                >
                                    <div className="d-inline-block pb-3 ltr_direction">
                                        <Pagination
                                            activePage={page}
                                            itemsCountPerPage={products?.meta?.per_page}
                                            totalItemsCount={productsTotal}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ProductTable;
