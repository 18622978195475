///create gift
import {giftConst} from "../_constants/gift-constant"
import {_http, ERROR, SUCCESS} from "../_helper";
import {getError} from "../_helper/getError";


///create Gift
export const createGiftAction = (data, id) => async (dispatch) => {
    dispatch({type: giftConst.CREATE_GIFT_REQUEST, payload: data, id})
    await _http(`admin/gifts`, {method: "post", data, id}).then((res) => {
        if (res.status === 200) {
            SUCCESS(res?.data?.message);
            dispatch({type: giftConst.CREATE_GIFT_SUCCESS, payload: res})
        } else {
            ERROR(res?.response?.data?.message)
            dispatch({type: giftConst.CREATE_GIFT_FAIL, payload: res})
        }
    }).catch((error) => {
        getError(error);
        dispatch({type: giftConst.CREATE_GIFT_FAIL, payload: error})
    })
}


///create GiftExcel
export const GiftExcelAction = (data, id) => async (dispatch) => {
    dispatch({type: giftConst.CREATE_EXCEL_REQUEST, payload: data, id})
    await _http(`admin/gifts/importExcel`, {method: "post", data, id}).then((res) => {
        if (res.status === 200) {
            SUCCESS(res?.data?.message);
            dispatch({type: giftConst.CREATE_EXCEL_SUCCESS, payload: res})
        } else {
            ERROR(res?.response?.data?.message)
            dispatch({type: giftConst.CREATE_EXCEL_FAIL, payload: res?.response?.data?.errors})
        }
    }).catch((error) => {
        getError(error);
        dispatch({type: giftConst.CREATE_EXCEL_FAIL, payload: error?.response?.data?.errors})
    })
}


///update Gift
export const updateGiftAction = (data, id) => async (dispatch) => {
    dispatch({type: giftConst.UPDATE_GIFT_REQUEST, payload: data, id})
    await _http(`admin/gifts/${id}`, {method: "put", data, id}).then((res) => {
        if (res.status === 200) {
            SUCCESS(res.data?.message);
            dispatch({type: giftConst.UPDATE_GIFT_SUCCESS, payload: data})
        } else {
            ERROR(res?.response?.data?.message)
            dispatch({type: giftConst.UPDATE_GIFT_FAIL, payload: res})
        }
    }).catch((error) => {
        getError(error);
        dispatch({type: giftConst.UPDATE_GIFT_FAIL, payload: error})
    })
}


//details gift
export const detailsGiftAction = (id) => async (dispatch) => {
    dispatch({type: giftConst.DETAILS_GIFT_REQUEST, payload: id})
    await _http(`admin/products/${id}`, {method: "get", id}).then((res) => {
        if (res.status === 200) {
            dispatch({type: giftConst.DETAILS_GIFT_SUCCESS, payload: res?.data})
        } else {
            ERROR(res?.response?.data?.message)
            dispatch({type: giftConst.DETAILS_GIFT_FAIL, payload: res})
        }
    }).catch((error) => {
        getError(error);
        dispatch({type: giftConst.DETAILS_GIFT_FAIL, payload: error})
    })
}


//delete gift
export const deleteGiftAction = (id) => async (dispatch) => {
    await _http(`admin/gifts/${id}`, {method: "delete", id}).then((res) => {
        if (res.status === 200) {
            SUCCESS("عملیات موفقیت آمیز بود")
            dispatch({type: giftConst.DELETE_GIFT, payload: res});
        } else {
            ERROR(res?.response?.data?.message)
        }
    }).catch((error) => {
        getError(error);
    })
}


//get order List
function getGiftsList(page, id) {
    return () => {
        return _http(`admin/products/${id}?page=${page}`, {method: "get"});
    };
}

export const giftActions = {
    getGiftsList
}





