//get currency
import {currencyConst} from "../_constants/currency-constant"
import {_http} from "../_helper/httpServices"
import {ERROR, SUCCESS} from "../_helper";

export const getCurrencyData = () => async (dispatch) => {
    dispatch({type: currencyConst.DETAILS_CURRENCY_SUCCESS})
    await _http("admin/currencies", {method: "get"}).then((res) => {
        if (res.status === 200) {
            dispatch({type: currencyConst.GTE_ALL_CURRENCY_SUCCESS, payload: res?.data})
        } else {
            ERROR(res?.response?.data?.message)
            dispatch({type: currencyConst.GTE_ALL_CURRENCY_FAIL, payload: res})

        }
    }).catch((error) => {
        let message;
        if (error?.response?.data?.message) message = error?.response?.data?.message;
        ERROR(message);
        dispatch({type: currencyConst.GTE_ALL_CURRENCY_FAIL, payload: error})
    })

}
///get Details currency
export const DetailsCurrencyAction = (id) => async (dispatch) => {
    dispatch({type: currencyConst.DETAILS_CURRENCY_REQUEST})
    await _http(`admin/currencies/${id}`, {method: "get"}).then((res) => {
        if (res.status === 200) {
            dispatch({type: currencyConst.DETAILS_CURRENCY_SUCCESS, payload: res?.data?.data})
        } else {
            ERROR(res?.response?.data?.message)
            dispatch({type: currencyConst.DETAILS_CURRENCY_FAIL, payload: res})
        }
    }).catch((error) => {
        let message;
        // if (error?.response?.data?.message) message = error?.response?.data?.message;
        ERROR(message);
        dispatch({type: currencyConst.DETAILS_CURRENCY_FAIL, payload: error})
    })
}


//get Details actions


export const UpdateCurrencyAction = (data, id) => async (dispatch) => {
    dispatch({type: currencyConst.UPDATE_CURRENCY_REQUEST, payload: data, id})
    await _http(`admin/currencies/${id}`, {method: "put", data}).then((r) => {
        if (r.status === 200) {
            SUCCESS("ویرایش با موفقیت انجام شده")
            dispatch({type: currencyConst.UPDATE_CURRENCY_SUCCESS, payload: r});
            window.location.href="/Currency"
        } else {
            ERROR(r?.response?.data?.message)
            dispatch({type: currencyConst.UPDATE_CURRENCY_FAIL, payload: r})
        }
    }).catch((ex) => {
        let message;
        if (ex?.response?.data?.message) message = ex?.response?.data?.message;
        ERROR(message);
        dispatch({type: currencyConst.UPDATE_CURRENCY_FAIL, payload: ex})

    })


}