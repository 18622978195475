import {authConstants, EmailConstants} from "./../_constants/auth.constants";

//user Auth Reduser
export const userAuthReducers = (state = {userInfo: []}, action) => {
    switch (action.type) {
        case authConstants.REQUEST_OTP:
            return {loading: true};
        case authConstants.VERIFY_OTP:
            return {loading: false, userInfo: action.payload};
        case authConstants.SUCCESS_OTP :
            return {loading: false, success: true}
        case authConstants.FAIL_OTP:
            return {loading: false, error: action.payload};

        case EmailConstants.SUCCESS_EMAIL : return {loading: false, successEmail: true}
        case authConstants.LOGOUT:
            return {};
        default:
            return state;
    }
};
