import React from 'react';
import {useSelector} from "react-redux";
import CouponTable from "./CouponTable";
import PageTitle from "../../layouts/PageTitle";
import {useTranslation} from "react-i18next";

const Coupon = () => {

    const getAllCoupons = useSelector((state) => state.getAllCoupons)
    const {t} = useTranslation()
    return (
        <>
            <PageTitle activeMenu={t("COUPON_KEY")} pageContent={t("COUPON_KEY")} motherMenu={t("DASHBOARD_KEY")}
                       dashUrl={""} prevUrl={""}/>
            <div className={"p-4 card"}>
                <CouponTable getAllCoupons={getAllCoupons}/>
            </div>
        </>

    );
};

export default Coupon;
