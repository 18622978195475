import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"
import {getReviewsDetails} from "../../../_actions/review.actions";
import * as shamsi from 'shamsi-date-converter';
import PageTitle from "../../layouts/PageTitle";
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import {useTranslation} from "react-i18next";
import moment from "moment-jalaali";
import {DateConvertWithTime} from "../Defaults/dateFunction";

const DetailsReviews = () => {

    const getDetailsReviews = useSelector((state) => state.getDetailsReviews);
    const {loading, error, reviewDetails} = getDetailsReviews;
    const {id} = useParams();
    let isValidDETIALS = ValidPermissions(PermissionConst.REVIEW_SHOW)

    const dispatch = useDispatch();
    useEffect(() => {
        if (isValidDETIALS) {
            dispatch(getReviewsDetails(id))
        }
    }, []);

    const {t} = useTranslation();
    let dir = localStorage.getItem("Layout");
    let time = moment(reviewDetails?.created_at).format('YYYY-M-D HH:mm:ss')
    return (
        <>
            <PageTitle activeMenu={t("DETAILS_KEY")} pageContent={t("DETAILS_KEY")} motherMenu={t("DASHBOARD_KEY")}
                       prev={t("REVIEW_KEY")}
                       dashUrl={""} prevUrl={"reviews"}/>
            <div className={"card p-5"}>
                <div className="row">
                    <div className="col-md-6 col-xs-12">
                        <h4 className={"mb-3"}>{t('REVIEW_KEY')}</h4>
                        <ul className={""}>
                            <li className={"mt-2"}>
                                <label className={"text-dark"}>{t('Title_KEY')} : </label>
                                {" "}
                                <span>{reviewDetails?.title}</span>
                            </li>
                            <li className={"mt-2"}>
                                <label className={"text-dark"}>{t('DATE_CREATE_KEY')} : </label>
                                {" "}
                                <span>{dir === "rtl" ? DateConvertWithTime(time) : time}</span>
                            </li>
                            <li className={"mt-2"}>
                                <label className={"text-dark"}>{t('STATUS_ORDERS_KEY')} : </label>
                                {" "}
                                {reviewDetails?.status === "0" ? (
                                    <>
                                               <span className="badge light badge-danger ">
                                                <i className="fa fa-circle text-danger mr-1"/>
                                                            <span className={'mr-2'}>
                                                          {t('UNPUBLISH_KEY')}
                                                            </span>
                                                </span>
                                    </>
                                ) : reviewDetails?.status === "1" ? (
                                    <>
                                                        <span className="badge light badge-success ">
                                                          <i className="fa fa-circle text-success mr-1"/>
                                                            <span className={'mr-2'}>
                                                                {t('PUBLISH_KEY')}
                                                            </span>
                                                           </span>
                                    </>
                                ) : null}
                            </li>
                            <li className={"mt-2"}>
                                <label className={"text-dark"}>{t('COMMENT_KEY')} : </label>
                                {" "}
                                <span className={"text-justify"}>{reviewDetails?.review}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-6 col-xs-12">
                        <h4 className={"mb-3"}>{t('USER_INFO_KEY')}</h4>
                        <ul className={""}>
                            <li className={"mt-2"}>
                                <label className={"text-dark"}>{t('MOBILE_KEY')} : </label>
                                {" "}
                                <span>{reviewDetails?.user?.mobile}</span>
                            </li>
                            <li className={"mt-2"}>
                                <label className={"text-dark"}>{t('EMAIL_KEY')} : </label>
                                {" "}
                                <span>{reviewDetails?.user?.email}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DetailsReviews;
