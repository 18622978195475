import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getReviewsDetails, editReviews} from "../../../_actions/review.actions";
import {BaseInput} from "../Defaults/BaseInput";
import PageTitle from "../../layouts/PageTitle";
import {DefaultButton} from "../Defaults/DefaultButton";
import {useTranslation} from "react-i18next";

const EditReview = () => {
    const [title, setTitle] = useState("");
    const [status, setStatus] = useState("");
    const [review, setReview] = useState("");
    const getDetailsReviews = useSelector((state) => state.getDetailsReviews);
    const {reviewDetails} = getDetailsReviews;

    const editReview = useSelector((state) => state.editReview);
    const {error, loading, success} = editReview;

    const {id} = useParams();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getReviewsDetails(id))
    }, []);


    useEffect(() => {
        setTitle(reviewDetails?.title)
        setStatus(reviewDetails?.status)
        setReview(reviewDetails?.review)
    }, [reviewDetails]);

    const handleEditReview = (e) => {
        e.preventDefault();
        const data = {
            title: title,
            status: status,
            review: review,
            _method: "patch"
        }
        dispatch(editReviews(data, id));
    }
    const {t} = useTranslation();


    return (
        <>
            <PageTitle activeMenu={t('EDIT_KEY')} pageContent={t('EDIT_KEY')} motherMenu={t('REVIEW_KEY')}
                       dashUrl={""} prev={t("COMMENT_KEY")}
                       prevUrl={"reviews"}/>
            <div className={"card p-5"}>
                <form onSubmit={handleEditReview}>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <BaseInput label={t('Title_KEY')} value={title} onChange={(e) => setTitle(e.target.value)}/>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <label htmlFor="status">{t('STATUS_KEY')}</label>
                            <select
                                className="form-control text-dark "
                                id="status"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <option>{t('CHOOSE_KEY')}</option>
                                <option value="1"> {t('PUBLISH_KEY')}</option>
                                <option value="0 ">{t('UNPUBLISH_KEY')}</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <textarea className={"form-control"} name="" id="" cols="30" rows="30" value={review}
                                  onChange={(e) => setReview(e.target.value)}/>
                    </div>
                    <div className={"text-center mt-3"}>
                        <DefaultButton
                            loading={loading}
                            variant="primary"
                            type={"submit"}
                        >
                            {t('EDIT_KEY')}
                        </DefaultButton>
                    </div>
                </form>
            </div>
        </>
    );
};

export default EditReview;
