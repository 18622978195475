export const categoryConstant = {
    // create category
    NEW_CATEGORY_REQUEST: "NEW_CATEGORY_REQUEST",
    NEW_CATEGORY_SUCCESS: "NEW_CATEGORY_SUCCESS",
    NEW_CATEGORY_FAIL: "NEW_CATEGORY_FAIL",
    //getAll Category
    GET_ALL_CATEGORY_REQUEST: "GET_ALL_CATEGORY_REQUEST",
    GET_ALL_CATEGORY_SUCCESS: "GET_ALL_CATEGORY_SUCCESS",
    GET_ALL_CATEGORY_FAIL: "GET_ALL_CATEGORY_FAIL",
}

export const categoriesDetail = {
    GET_DETAILS_CATEGORY_REQUEST: "GET_DETAILS_CATEGORY_REQUEST",
    GET_DETAILS_CATEGORY_SUCCESS: "GET_DETAILS_CATEGORY_SUCCESS",
    GET_DETAILS_CATEGORY_FAIL: "GET_DETAILS_CATEGORY_FAIL",

}

export const categoriesDelete = {
    DELETE_CATEGORY_REQUEST: "DELETE_CATEGORY_REQUEST",
    DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
    DELETE_CATEGORY_FAIL: "DELETE_CATEGORY_FAIL",

}
export const categoriesEdit = {
    EDIT_CATEGORY_REQUEST: "EDIT_CATEGORY_REQUEST",
    EDIT_CATEGORY_SUCCESS: "EDIT_CATEGORY_SUCCESS",
    EDIT_CATEGORY_FAIL: "EDIT_CATEGORY_FAIL",

}