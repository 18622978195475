export const couponConstructor = {
    GET_ALL_COUPON_REQUEST: "GET_ALL_COUPON_REQUEST",
    GET_ALL_COUPON_SUCCESS: "GET_ALL_COUPON_SUCCESS",
    GET_ALL_COUPON_FAIL: "GET_ALL_COUPON_FAIL",

    CREATE_COUPON_REQUEST: "CREATE_COUPON_REQUEST",
    CREATE_COUPON_SUCCESS: "CREATE_COUPON_SUCCESS",
    CREATE_COUPON_FAIL: "CREATE_COUPON_FAIL",


    EDIT_COUPON_REQUEST: "EDIT_COUPON_REQUEST",
    EDIT_COUPON_SUCCESS: "EDIT_COUPON_SUCCESS",
    EDIT_COUPON_FAIL: "EDIT_COUPON_FAIL",


    DETAILS_COUPON_REQUEST: "DETAILS_COUPON_REQUEST",
    DETAILS_COUPON_SUCCESS: "DETAILS_COUPON_SUCCESS",
    DETAILS_COUPON_FAIL: "DETAILS_COUPON_FAIL",
}