import {couponConstructor} from "../_constants/coupon.constant"
import {_http, ERROR, SUCCESS} from "../_helper";
import {getError} from "../_helper/getError";
///All Coupons


export const getAllCouponsActions = () => async (dispatch) => {
    dispatch({type: couponConstructor.GET_ALL_COUPON_REQUEST})
    await _http("admin/coupons", {method: "get"}).then((res) => {
        if (res.status === 200) {
            dispatch({type: couponConstructor.GET_ALL_COUPON_SUCCESS, payload: res?.data})
        } else {
            dispatch({type: couponConstructor.GET_ALL_COUPON_FAIL, payload: res})
            ERROR(res?.response?.data?.message)
        }
    }).catch((error) => {
        getError(error);
        dispatch({type: couponConstructor.GET_ALL_COUPON_FAIL})
    })

}

//details
export const createCouponsAction = (data) => async (dispatch) => {
    dispatch({type: couponConstructor.GET_ALL_COUPON_REQUEST})
    await _http("admin/coupons", {method: "post", data}).then((res) => {
        if (res.status === 200) {
            SUCCESS(res?.data?.message);
            window.location.href ="/coupon"
            dispatch({type: couponConstructor.CREATE_COUPON_SUCCESS, payload: res});
        } else {
            dispatch({type: couponConstructor.CREATE_COUPON_FAIL, payload: res?.response?.data?.errors});
            ERROR(res?.response?.data?.message)
        }
    }).catch((error) => {
        getError(error);
        dispatch({type: couponConstructor.CREATE_COUPON_FAIL, payload: error?.response?.data?.errors});
    })

}


// edit

export const editCouponsAction = (data, id) => async (dispatch) => {
    dispatch({type: couponConstructor.GET_ALL_COUPON_REQUEST})
    await _http(`admin/coupons/${id}`, {method: "post", data, id}).then((res) => {
        if (res.status === 200) {
            SUCCESS(res?.data?.message);
            dispatch({type: couponConstructor.EDIT_COUPON_SUCCESS, payload: res});
        } else {
            dispatch({type: couponConstructor.EDIT_COUPON_FAIL, payload: res});
            ERROR(res?.response?.data?.message)
        }
    }).catch((error) => {
        getError(error);
        dispatch({type: couponConstructor.EDIT_COUPON_FAIL, payload: error});
    })

}

// create
export const detailsCouponsAction = (id) => async (dispatch) => {
    dispatch({type: couponConstructor.GET_ALL_COUPON_REQUEST})
    await _http(`admin/coupons/${id}`, {method: "get"}).then((res) => {
        if (res.status === 200) {
            dispatch({type: couponConstructor.DETAILS_COUPON_SUCCESS, payload: res?.data});
        } else {
            dispatch({type: couponConstructor.EDIT_COUPON_FAIL, payload: res});
            ERROR(res?.response?.data?.message)
        }
    }).catch((error) => {
        getError(error);
        dispatch({type: couponConstructor.EDIT_COUPON_FAIL, payload: error});
    })
}


//get order List
function getCouponList(page) {
    return () => {
        return _http(`admin/coupons?page=${page}`, {method: "get"});
    };
}

// function filterUsers(search) {
//     return () => {
//         return _http(`admin/users?search=${search}`, {method: "get"})
//     }
// }

export const couponActions = {
    getCouponList,
}
