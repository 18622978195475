import {dashboardConst} from "../_constants/dashboard.Constant";


export const getUserInfoReducer = (state = {ChartUser:[]}, action) => {

    switch (action.type) {
        case dashboardConst.GET_USER_INFO_REQUEST :
            return {loading: true}

        case dashboardConst.GET_USER_INFO_SUCCESS :
            return {loading: false, ChartUser: action.payload}

        case dashboardConst.GET_USER_INFO_FAIL :
            return {loading: false, error: action.payload}
        default :
            return state

    }
}


export const getOrderStaticReducer = (state = {orderStatic:[]}, action) => {

    switch (action.type) {
        case dashboardConst.GET_ORDER_INFO_REQUEST :
            return {loading: true}

        case dashboardConst.GET_ORDER_INFO_SUCCESS :
            return {loading: false, orderStatic: action.payload}

        case dashboardConst.GET_ORDER_INFO_FAIL :
            return {loading: false, error: action.payload}
        default :
            return state

    }
}