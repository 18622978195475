import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {Col, Row} from "react-bootstrap";
import {BaseInput} from "../Defaults/BaseInput";
import EditorToolbar, {formats, modules} from "../Product/EditorToolbar";
import ReactQuill from "react-quill";
import {DefaultButton} from "../Defaults/DefaultButton";
import {updatePageAction, getDetailsPageAction} from "../../../_actions/page.actions"
import {Multiselect} from "multiselect-react-dropdown";

const EditPage = () => {
    const [title, setTitle] = useState("");

    const [description, setDescription] = useState("")
    const [slug, setSlug] = useState("");
    const [metaTitle, setMetaTitle] = useState("");
    const [metaDesc, setMetaDesc] = useState("");
    const [status, setStatus] = useState([]);
    const [menuStatus, setMenuStatus] = useState([]);


    const {t} = useTranslation();

    const {id} = useParams();
    const createPage = useSelector((state) => state.createPage);
    const {error, success, loading} = createPage;

    const detailPage = useSelector((state) => state.detailPage);
    const {details} = detailPage;

    useEffect(() => {
        dispatch(getDetailsPageAction(id))
    }, []);


    const handleChange = value => {
        setDescription({value});
    };

    useEffect(() => {
        setTitle(details?.title);
        setDescription(details?.description);
        setSlug(details?.slug);
        setMetaTitle(details?.meta_title);
        setMetaDesc(details?.meta_desc);
        setStatus(details?.status);
        setMenuStatus(details?.menu_status);

    }, [details]);


    const dispatch = useDispatch();

    const editPage = () => {
        const data = {
            title: title,
            slug: slug,
            description: description,
            meta_title: metaTitle,
            meta_desc: metaDesc,
            status: status.length !== 0 ? status[0]?.id : status,
            menu_status: menuStatus.length !== 0 ? menuStatus[0]?.id : menuStatus,
            _method: "patch"
        }
        dispatch(updatePageAction(data, id))
    }


    console.log(menuStatus, "vala");


    const handleMenuStatus = (selected) => {
        setMenuStatus(selected)
    }
    const handleStatus = (selected) => {
        setStatus(selected)
    }
    const style = {
        chips: {
            display: "flex",
            justify: "space-between",
            color: "#fff"
        },
        searchBox: {
            border: "0.5px solid rgb(97 94 96)",
            borderRadius: "10px",
            display: "flex"
        },
        multiselectContainer: {
            color: "#000",
        },
        inputField: {
            margin: " 5px",
            textAlign: "right",
        },
        optionContainer: {
            border: " 1px solid",
        },
        option: {
            color: "#000",
            textAlign: "right",
            display: "flex"
        },
        groupHeading: {}
    };
    const statusSelector = [
        {id: 1, value: t('PUBLISH_KEY')},
        {id: 0, value: t('UNPUBLISH_KEY')}
    ]
    const menuStatusSelector = [
        {id: 1, value: t('PUBLISH_KEY')},
        {id: 0, value: t('UNPUBLISH_KEY')}
    ]


    return (
        <div className="p-4 card">
            <form onSubmit={(e) => e.preventDefault()}>
                <Row>
                    <Col md={6} xs={12}>
                        <BaseInput
                            className="form-control "
                            id="title"
                            type="text"
                            value={title}
                            placeholder={t('Title_KEY')}
                            onChange={(e) => setTitle(e.target.value)}
                            label={t('Title_KEY')}
                        />
                        {error?.name && (
                            <p className={"text-danger"}>{error?.name}</p>
                        )}
                    </Col>
                    <Col md={6} xs={12}>
                        <BaseInput
                            className="form-control"
                            id="slug"
                            type="text"
                            value={slug}
                            placeholder={t('SLUG_KEY')}
                            onChange={(e) => setSlug(e.target.value)}
                            label={t('SLUG_KEY')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} xs={12}>
                        <label htmlFor="">{t('STATUS_KEY')}</label>
                        <Multiselect
                            options={statusSelector}
                            displayValue="value"
                            placeholder={ status === 0 ?  t('UNPUBLISH_KEY') : t('PUBLISH_KEY')}
                            singleSelect={true}
                            keepSearchTerm={true}
                            onSelect={handleStatus}
                            style={style}
                        />
                        {error?.name && (
                            <p className={"text-danger"}>{error?.name}</p>
                        )}
                    </Col>
                    <Col md={6} xs={12}>
                        <label htmlFor="">{t('MENU_STATUS_KEY')}</label>
                        <Multiselect
                            options={menuStatusSelector}
                            displayValue="value"
                            placeholder={menuStatus === 0 ? t('UNPUBLISH_KEY') : t('PUBLISH_KEY')}
                            singleSelect={true}
                            keepSearchTerm={true}
                            onSelect={handleMenuStatus}
                            style={style}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6} xs={12} className={"mt-3"}>
                        <BaseInput
                            className="form-control "
                            id="metaTitle"
                            type="text"
                            value={metaTitle}
                            placeholder={t('META_TITLE_KEY')}
                            onChange={(e) => setMetaTitle(e.target.value)}
                            label={t('META_TITLE_KEY')}
                        />
                        {error?.name && (
                            <p className={"text-danger"}>{error?.name}</p>
                        )}
                    </Col>
                    <Col md={6} xs={12} className={"mt-3"}>
                        <BaseInput
                            className="form-control"
                            id="metaDesc"
                            type="text"
                            value={metaDesc}
                            placeholder={t('META_DESCRIPTION_KEY')}
                            onChange={(e) => setMetaDesc(e.target.value)}
                            label={t('META_DESCRIPTION_KEY')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12} xs={12}>
                        <div className=" mt-5">
                            <label className={`mt-3 text-center d-block mt-3`}>{t('DESCRIPTION_KEY')}</label>
                            <EditorToolbar/>
                            <ReactQuill
                                value={description ? description : ""}
                                theme="snow"
                                onChange={(value) => setDescription(value)}
                                modules={modules}
                                formats={formats}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className={"text-center m-auto mt-3"}>
                        <DefaultButton
                            className={"mt-4"}
                            onClick={() => editPage()}
                            loading={loading}
                            variant="primary"
                        >
                            {t('SAVE_KEY')}
                        </DefaultButton>
                    </div>
                </Row>
            </form>
        </div>
    );
};

export default EditPage;
