import { combineReducers } from "redux";

import { userAuthReducers } from "./auth.reducer";
import {
    detailsCurrencyReducer,
    editCurrencyReducer,
    getAllCurrencyReducer,
    UpdateCurrencyReducer
} from "./currency-reducer";
import { createProductReducer, getAllProductReducer, productDetailsReducer, editProductReducer } from "./product-reducer";
import {
    newCategoryReducer,
    getAllCategoryReducer,
    getAllCategoryDetails,
    editCategoryReducer
} from "./category.reducer";
import {
    EditUserReducer,
    getCreditReducer,
    getPermissionReducer,
    getUserReducer,
    usertDetailsReducer, getTransactionReducer, getSinglUserReducer
} from "./user.reducers";
import { createUserReducer } from "../_reducers/user.reducers";
import { getAllOrderReducer, getDetailsOrderReducer, getMasterCardOrderReducer, submitMasterReducers } from "./order.reducer";
import { editReviewReducer, getAllReviewReducers, getDetailsReviewReducers, } from "./reviews.reducer";
import { createTransactionReducer, getAllTransactionReducer } from "./wallet-reducer";
import { createGiftExcelReducer, detailsGiftReducer } from "./gift-reducers";
import { getOrderStaticReducer, getUserInfoReducer } from "./dashboard.reducer";
import {
    getAllCouponsReducers,
    createCouponsReducers,
    editCouponsReducers,
    detailsCouponsReducers
} from "./coupon.reducers";
import {
    getAllPageReducer,
    deletePageReducer,
    detailPageReducer,
    updatePageReducer,
    createPageReducer
} from "./page.reducer"

const rootReducer = combineReducers({
    auth: userAuthReducers,
    newCategory: newCategoryReducer,
    allCategories: getAllCategoryReducer,
    categoryDetails: getAllCategoryDetails,
    editCategory: editCategoryReducer,
    getProducts: getAllProductReducer,
    getProductDetails: productDetailsReducer,
    createProduct: createProductReducer,
    editProduct: editProductReducer,
    users: getUserReducer,
    user: getSinglUserReducer,
    DetailsUser: usertDetailsReducer,
    createUser: createUserReducer,
    EditUser: EditUserReducer,
    getCurrency: getAllCurrencyReducer,
    updateCurrency: UpdateCurrencyReducer,
    detailsCurrency: detailsCurrencyReducer,
    allOrders: getAllOrderReducer,
    masterCardOrders: getMasterCardOrderReducer,
    submitMaster: submitMasterReducers,
    orderDetails: getDetailsOrderReducer,
    getAllReviews: getAllReviewReducers,
    getDetailsReviews: getDetailsReviewReducers,
    editReview: editReviewReducer,
    getAllTransaction: getAllTransactionReducer,
    createTransaction: createTransactionReducer,
    detailsGift: detailsGiftReducer,
    ChartUserInfo: getUserInfoReducer,
    getOrderStatic: getOrderStaticReducer,
    getAllCoupons: getAllCouponsReducers,
    createCoupons: createCouponsReducers,
    editCoupons: editCouponsReducers,
    detailsCoupons: detailsCouponsReducers,
    createExcel: createGiftExcelReducer,
    getPermission: getPermissionReducer,
    getCredit: getCreditReducer,
    getTransaction: getTransactionReducer,
    getAllPage: getAllPageReducer,
    deletePage: deletePageReducer,
    detailPage: detailPageReducer,
    updatePage: updatePageReducer,
    createPage: createPageReducer
});
export default rootReducer;
