import React, {useState, useEffect} from "react";
import {Table, Dropdown} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {categoriesActions, deleteCategories, getAllCategory} from "../../../_actions/category-actions"
import Pagination from "react-js-pagination";
import {ERROR} from "../../../_helper";
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import {useTranslation} from "react-i18next";

const CategoryTable = ({categoriesData}) => {
    const [page, setPage] = useState(1);
    const [categoriesTotal, setCategoriesTotal] = useState(10);

    const dispatch = useDispatch();

    let isValid = ValidPermissions(PermissionConst.CATEGORY_LIST);


    const handlePageChange = async (pageNumber) => {
        setPage(pageNumber);
        const page = pageNumber;
        dispatch(
            categoriesActions.getCategoryList(page)
        )
            .then((r) => {
                setCategoriesTotal(r.data?.meta?.total);
            })
            .catch((ex) =>
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                })
            );
    }

    const handelDelete = (id) => {
        if (isValid) {
            dispatch(getAllCategory())
            dispatch(deleteCategories(id))
        }
    }
    const {t} = useTranslation();
    let dir = localStorage.getItem("Layout");
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">{t('CATEGORIES_KEY')}</h4>
                </div>
                <div className="card-body">
                    <div className="w-100 table-responsive">
                        <div id="patientTable_basic_table" className="dataTables_wrapper">
                            <table
                                id="example5"
                                className="display dataTable no-footer w-100  table-striped  table-hover "
                                style={{minWidth: 845}}
                                role="grid"
                                aria-describedby="example5_info"
                            >
                                <thead>
                                <tr role="row">

                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient ID: activate to sort column ascending"
                                    >
                                        {t('ID_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Date Check in: activate to sort column ascending"
                                    >
                                        {t('PHOTO_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Patient Name: activate to sort column ascending"
                                    >
                                        {t('STATUS_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Doctor Assgined: activate to sort column ascending"
                                    >
                                        {t('SLUG_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example5"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Disease: activate to sort column ascending"
                                    >
                                        {t('NAME_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example6"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Disease: activate to sort column ascending"
                                    >
                                        {t('IMPORTANCE_KEY')}
                                    </th>
                                    <th
                                        className="sorting text-center"
                                        tabIndex={0}
                                        aria-controls="example7"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Disease: activate to sort column ascending"
                                    >
                                        {t('ACTIONS_KEY')}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {categoriesData?.data?.map((item) => (
                                    <>
                                        <tr key={item.id} role="row" className="odd">

                                            <td className={'text-center'}>{item.id}</td>
                                            <td className={'text-center'}>
                                                <img height="70px" src={item.photo} alt={item.name}/>
                                            </td>
                                            <td className={'text-center'}>
                                                {item.status === 0 ? (
                                                    <>
                                                        <span className="badge light badge-danger ">
                                                <i className="fa fa-circle text-danger mr-1"/>
                                                            <span className={'mr-2'}>
                                                           {t('DEACTIVATE_KEY')}
                                                            </span>
                                                </span>
                                                    </>
                                                ) : item.status === 1 ? (
                                                    <>
                                                        <span className="badge light badge-success ">
                                                          <i className="fa fa-circle text-success mr-1"/>
                                                            <span className={'mr-2'}>
                                                                   {t('ACTIVE_KEY')}
                                                            </span>
                                                           </span>
                                                    </>
                                                ) : null}
                                            </td>
                                            <td className={'text-center'}>{item.slug}</td>
                                            <td className={'text-center'}>{item.name}</td>
                                            <td className={'text-center'}>{item.sort}</td>
                                            <td className={'text-center'}>
                                                <Dropdown className="dropdown ml-auto ">
                                                    <Dropdown.Toggle
                                                        variant=""
                                                        className="btn-link i-false"
                                                        data-toggle="dropdown"
                                                    >
                                                        <svg
                                                            width="24px"
                                                            height="24px"
                                                            viewBox="0 0 24 24"
                                                            version="1.1"
                                                        >
                                                            <g
                                                                stroke="none"
                                                                strokeWidth={1}
                                                                fill="none"
                                                                fillRule="evenodd"
                                                            >
                                                                <rect x={0} y={0} width={24} height={24}/>
                                                                <circle fill="#000000" cx={5} cy={12} r={2}/>
                                                                <circle fill="#000000" cx={12} cy={12} r={2}/>
                                                                <circle fill="#000000" cx={19} cy={12} r={2}/>
                                                            </g>
                                                        </svg>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">

                                                        {ValidPermissions(PermissionConst.CATEGORY_SHOW) ? (<>
                                                            <Dropdown.Item
                                                                className={'d-flex align-items-center justify-content-center'}
                                                                href={`categories/${item.id}`}
                                                            >
                                                                <i className={dir === "rtl" ? "fa fa-info text-light fa-2x ml-4" : dir === "ltr" ? "fa fa-info text-light fa-2x mr-4" : "fa fa-info text-light fa-2x ml-4"}/>
                                                                {t('DETAILS_KEY')}
                                                            </Dropdown.Item>
                                                        </>) : null}

                                                        {ValidPermissions(PermissionConst.CATEGORY_DESTROY) ? (<>
                                                            <Dropdown.Item
                                                                onClick={() => handelDelete(item.id)}
                                                                className={'d-flex align-items-center justify-content-center'}
                                                            >
                                                                <i className={dir === "rtl" ? "fa fa-trash text-danger fa-2x ml-4" : dir === "ltr" ? "fa fa-trash text-danger fa-2x mr-4" : "fa fa-trash text-danger fa-2x ml-4"}/>
                                                                {t('DELETE_KEY')}
                                                            </Dropdown.Item>
                                                        </>) : null}
                                                        {ValidPermissions(PermissionConst.CATEGORY_UPDATE) ? (<>
                                                            <Dropdown.Item
                                                                href={`new-category/${item.id}`}
                                                                className={'d-flex align-items-center justify-content-center'}
                                                            >
                                                                <i className={dir === "rtl" ? "fa fa-edit text-warning fa-2x ml-4" : dir === "ltr" ? "fa fa-edit text-warning fa-2x mr-4" : "fa fa-edit text-warning fa-2x ml-4"}/>
                                                                {t('EDIT_KEY')}
                                                            </Dropdown.Item>
                                                        </>) : null}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    </>
                                ))}
                                </tbody>
                            </table>
                            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                                <div className="dataTables_info">
                                    {/*نمایش {activePag.current * sort + 1} به{" "}*/}
                                    {/*{data.length > (activePag.current + 1) * sort*/}
                                    {/*    ? (activePag.current + 1) * sort*/}
                                    {/*    : data.length}{" "}*/}
                                    {/*از{data.length} تا*/}
                                </div>
                                <div
                                    className="dataTables_paginate paging_simple_numbers"
                                    id="example5_paginate"
                                >
                                    <div className="d-inline-block pb-3 ltr_direction">
                                        <Pagination
                                            activePage={page}
                                            itemsCountPerPage={categoriesData?.meta?.per_page}
                                            totalItemsCount={categoriesData?.meta?.total}
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryTable;
