import Swal from "sweetalert2";

export function SUCCESS(message, { description } = {}) {
  return Swal.fire({
    // title: message,
    html: `<h5 class="text-dark">${message}</h5> ${description || ""}`,
    icon: "success",
    showConfirmButton: false,
    allowOutsideClick: true,
    showCloseButton: true,
  });
}

export function ERROR(message, { description, error } = {}) {
  return Swal.fire({
    // title: message,
    html: `<h5 class="text-dark">${message}</h5> ${description || ""} ${
      !!error ? `` : ""
    }`,
    icon: "error",
    showConfirmButton: false,
    allowOutsideClick: true,
    showCloseButton: true,
    timer: 5000,
  });
}

export function WARNING(message, { description, error } = {}) {
  return Swal.fire({
    // title: message,
    html: `<h5 class="text-dark">${message}</h5> ${description || ""} ${
      !!error ? `` : ""
    }`,
    icon: "warning",
    showConfirmButton: false,
    allowOutsideClick: true,
    showCloseButton: true,
    timer: 5000,
  });
}

export function LOADING(show) {
  if (show) {
    Swal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: false,
      timer: 5000,
    });
    return Swal.showLoading();
  } else {
    Swal.hideLoading();
    return Swal.close();
  }
}

export function CONFIRM(
  message,
  callback,
  { description, confirmText = "بله", cancelText = "انصراف" } = {}
) {
  return Swal.fire({
    icon: "warning",
    html: `<h5 class="text-dark">${message}</h5> ${description || ""}`,
    showConfirmButton: true,
    confirmButtonText: confirmText,
    showCancelButton: true,
    cancelButtonText: cancelText,
  }).then((result) => {
    if (result.value) {
      callback(result);
    }
  });
}
