import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {getAllCouponsActions} from "../../../_actions/coupon.action"
import {Dropdown} from "react-bootstrap";
import Pagination from "react-js-pagination";
import {Link, useHistory} from "react-router-dom";
import {couponActions} from "../../../_actions/coupon.action"
import {ERROR} from "../../../_helper";
import {DateConvertWithTime, DateConvert} from "../../../_helper/dateFunction"
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst"
import {useTranslation} from "react-i18next";

const CouponTable = ({getAllCoupons}) => {
    const [page, setPage] = useState(1);
    const [couponTotal, setCouponTotal] = useState(1);
    const [CouponData, setCouponData] = useState([]);

    const {loading, error, Coupons} = getAllCoupons;
    const dispatch = useDispatch();
    const history = useHistory();

    let isValid = ValidPermissions(PermissionConst.COUPON_LIST);


    useEffect(() => {
        if (isValid) {
            dispatch(getAllCouponsActions())
        }
    }, []);

    const handlePageChange = async (pageNumber) => {
        setPage(pageNumber);
        const page = pageNumber;
        dispatch(
            couponActions.getCouponList(page)
        )
            .then((r) => {
                if (r.status === 200) {
                    window.scroll(0, 0)
                    setCouponData(r)
                    setCouponTotal(r.data?.meta?.total);
                }
            })
            .catch((ex) =>
                ERROR(ex.response?.data?.message || ex.message, {
                    error: ex,
                })
            );
    }
    useEffect(() => {
        if (isValid) {
            dispatch(
                couponActions.getCouponList(page)
            )
                .then((r) => {
                    if (r.status === 200) {
                        setCouponData(r)
                        setCouponTotal(r.data?.meta?.total);
                    }
                })
                .catch((ex) =>
                    ERROR(ex.response?.data?.message || ex.message, {
                        error: ex,
                    })
                );
        }
    }, [page]);

    const {t} = useTranslation();
    let dir = localStorage.getItem("Layout");
    return (
        <div className={"p-4 card"}>
            <div
                className="d-flex justify-content-between align-items-center ">
                <h3>{t('COUPON_KEY')}</h3>
                {ValidPermissions(PermissionConst.COUPON_STORE) ? (<>
                    <div className={"d-flex align-items-center cursor-pointer"}
                         onClick={() => history.push("/new-coupon")}>
                        <i className={dir === "rtl" ? "fa fa-plus  text-warning fa-2x  ml-2" : "fa fa-plus  text-warning fa-2x  mr-2"}/>
                        {t('NEW_KEY')}
                    </div>
                </>) : null}
            </div>
            <div className="w-100 table-responsive">
                <div id="patientTable_basic_table" className="dataTables_wrapper">
                    <table
                        id="example5"
                        className="display dataTable no-footer w-100  table-striped  table-hover "
                        style={{minWidth: 845}}
                        role="grid"
                        aria-describedby="example5_info"
                    >
                        <thead>
                        <tr role="row">
                            <th
                                className="sorting text-center"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Patient ID: activate to sort column ascending"
                            >
                                {t('ID_KEY')}

                            </th>

                            <th
                                className="sorting text-center"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Patient Name: activate to sort column ascending"
                            >
                                {t('Title_KEY')}
                            </th>
                            <th
                                className="sorting text-center"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Disease: activate to sort column ascending"
                            >
                                {t('Code_KEY')}
                            </th>
                            <th
                                className="sorting text-center"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Disease: activate to sort column ascending"
                            >
                                {t('COMMISSION_PERCENTAGE')}
                            </th>
                            <th
                                className="sorting text-center"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Disease: activate to sort column ascending"
                            >
                                {t('STATUS_KEY')}

                            </th>
                            <th
                                className="sorting text-center"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Disease: activate to sort column ascending"
                            >
                                {t('DATE_CREATE_KEY')}
                            </th>
                            <th
                                className="sorting text-center"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Disease: activate to sort column ascending"
                            >
                                {t('START_DATE_KEY')}
                            </th>
                            <th
                                className="sorting text-center"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Disease: activate to sort column ascending"
                            >
                                {t('END_DATE_KEY')}
                            </th>
                            <th
                                className="sorting text-center"
                                tabIndex={0}
                                aria-controls="example7"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Disease: activate to sort column ascending"
                            >
                                {t('TRANSACTION_KEY')}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {CouponData?.data?.data.map((coupon, i) => (
                            <tr key={i} role="row" className="odd">
                                <td className={'text-center'}>{coupon?.id}</td>
                                <td className={'text-center'}>{coupon?.title}</td>
                                <td className={'text-center'}>{coupon?.code}</td>
                                <td className={'text-center'}>{coupon?.percentage}</td>
                                <td className={'text-center'}>
                                    {coupon?.status === "active" ? (
                                        <>
                                                        <span className="badge light badge-success ">
                                                          <i className="fa fa-circle text-success mr-1"/>
                                                            <span className={'mr-2'}>
                                                             فعال
                                                            </span>
                                                           </span>
                                        </>
                                    ) : coupon?.status === "deactivate" ? (
                                        <>
                                                    <span className="badge light badge-warning ">
                                                    <i className="fa fa-circle text-danger mr-1"/>
                                                    <span className={'mr-2'}>
                                                   غیر فعال
                                                    </span>
                                                    </span>
                                        </>
                                    ) : null}

                                </td>
                                <td className={'text-center text-success'}>{coupon?.created_at}</td>
                                <td className={'text-center text-warning'}>{DateConvertWithTime(coupon?.start_date)}</td>
                                <td className={'text-center text-danger'}>{DateConvertWithTime(coupon?.end_date)}</td>
                                <td className={'text-center'}>
                                    <Dropdown className="dropdown ml-auto ">
                                        <Dropdown.Toggle
                                            variant=""
                                            className="btn-link i-false"
                                            data-toggle="dropdown"
                                        >
                                            <svg
                                                width="24px"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                                version="1.1"
                                            >
                                                <g
                                                    stroke="none"
                                                    strokeWidth={1}
                                                    fill="none"
                                                    fillRule="evenodd"
                                                >
                                                    <rect x={0} y={0} width={24} height={24}/>
                                                    <circle fill="#000000" cx={5} cy={12} r={2}/>
                                                    <circle fill="#000000" cx={12} cy={12} r={2}/>
                                                    <circle fill="#000000" cx={19} cy={12} r={2}/>
                                                </g>
                                            </svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">

                                            <Dropdown.Item
                                                className={'d-flex align-items-center justify-content-center'}
                                            >
                                                <Link to={`edit-coupon/${coupon.id}`}>
                                                    <i className="fa fa-edit text-warning fa-2x ml-4 "/>
                                                    ویرایش
                                                </Link>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div
                        className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                        <div className="dataTables_info">
                            {/*   */}
                        </div>
                        <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="example5_paginate"
                        >
                            <div className="d-inline-block pb-3 ltr_direction">
                                <Pagination
                                    activePage={page}
                                    itemsCountPerPage={CouponData?.data?.meta?.per_page}
                                    totalItemsCount={couponTotal}
                                    pageRangeDisplayed={20}
                                    onChange={handlePageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CouponTable;
