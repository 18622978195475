import React from 'react';
import {useSelector} from "react-redux"
import TransctionTable from "./TransctionTable"
import PageTitle from "../../layouts/PageTitle";
import {useTranslation} from "react-i18next";

const Transactions = () => {
    const getAllTransaction = useSelector((state) => state.getAllTransaction);
    const {error, transition, loading} = getAllTransaction;

    const {t} = useTranslation();
    return (
        <div>
            <PageTitle activeMenu={t('TRANSACTION_KEY')} pageContent={t('TRANSACTION_KEY')}
                     dashUrl={""}  motherMenu={t('DASHBOARD_KEY')}
                       prevUrl={"dashboard"}/>
            <TransctionTable error={error} loading={loading} transition={transition}/>
        </div>
    );
};

export default Transactions;
