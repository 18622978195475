export const orderConst = {
    GET_ORDER_REQUEST: "GET_ORDER_REQUEST",
    GET_ORDER_SUCCESS: "GET_ORDER_SUCCESS",
    GET_ORDER_FAIL: "GET_ORDER_FAIL",

    GET_MASTER_REQUEST: "GET_MASTER_REQUEST",
    GET_MASTER_SUCCESS: "GET_MASTER_SUCCESS",
    GET_MASTER_FAIL: "GET_MASTER_FAIL",

    SUBMIT_MASTER_SUCCESS: "SUBMIT_MASTER_SUCCESS",
    SUBMIT_MASTER_FAIL: "SUBMIT_MASTER_FAIL",
    SUBMIT_MASTER_REQUEST: "SUBMIT_MASTER_REQUEST",

    GET_DETAILS_ORDER_REQUEST: "GET_DETAILS_ORDER_REQUEST",
    GET_DETAILS_ORDER_SUCCESS: "GET_DETAILS_ORDER_SUCCESS",
    GET_DETAILS_ORDER_FAIL: "GET_DETAILS_ORDER_FAIL",
}