import {dashboardConst} from "../_constants/dashboard.Constant";
import {getError} from "../_helper/getError";
import {_http, ERROR} from "../_helper";

export const getUserInfoAction = () => async (dispatch) => {
    dispatch({type: dashboardConst.GET_USER_INFO_REQUEST})
    await _http(`admin/dashboardStatistics?date_type=weekly`, {method: "get"}).then((res) => {
        if (res.status === 200) {
            dispatch({type: dashboardConst.GET_USER_INFO_SUCCESS, payload: res?.data})
        } else {
            dispatch({type: dashboardConst.GET_USER_INFO_FAIL, payload: res})
            ERROR(res?.response?.data?.message)
        }
    }).catch((error) => {
        getError(error);
        dispatch({type: dashboardConst.GET_USER_INFO_FAIL, payload: error})
        ERROR(error)

    })

}

function filterUser(time) {
    return () => {
        return _http(`admin/dashboardStatistics?date_type=${time}`, {method: "get"})
    }
}


export const getOrderStatusAction = () => async (dispatch) => {
    dispatch({type: dashboardConst.GET_USER_INFO_REQUEST})

    await _http("").then((res) => {
        if (res.status === 200) {
        }
    }).catch((error) => {
        getError(error);
        ERROR(error)

    })

}

function filterOrderStatus(time) {
    return () => {
        return _http(`admin/ordersStatistics?date_type=${time}`, {method: "get"})
    }
}

export const getOrderStaticAction = () => async (dispatch) => {
    dispatch({type: dashboardConst.GET_ORDER_INFO_REQUEST})

    await _http("admin/ordersStatusStatistics", {method: "get"}).then((res) => {
        if (res.status === 200) {
            dispatch({type: dashboardConst.GET_ORDER_INFO_SUCCESS, payload: res?.data})
        } else {
            dispatch({type: dashboardConst.GET_ORDER_INFO_FAIL, payload: res})
            ERROR(res?.response?.data?.message);
        }

    }).catch((error) => {
        getError(error);
        dispatch({type: dashboardConst.GET_ORDER_INFO_FAIL, payload: error})
        ERROR(error)
    })

}


export const userActions = {
    filterUser,filterOrderStatus
}