import {pageConst} from "../_constants/PageConst"
import {_http, ERROR, SUCCESS} from "../_helper";
import {getError} from "../_helper/getError";


///get All Pages
export const getAllPageAction = () => async (dispatch) => {
    dispatch({type: pageConst.GET_ALL_PAGE_REQUEST})

    await _http("admin/pages", {method: "get"}).then((res) => {
        if (res.status === 200) {
            dispatch({type: pageConst.GET_ALL_PAGE_SUCCESS, payload: res?.data})
        } else {
            dispatch({type: pageConst.GET_ALL_PAGE_FAIL, payload: res})

        }
    }).catch((error) => {
        getError(error);
        dispatch({type: pageConst.GET_ALL_PAGE_FAIL, payload: error})
    })
}


///get  Pages Details
export const getDetailsPageAction = (id) => async (dispatch) => {
    dispatch({type: pageConst.DETAILS_PAGE_REQUEST, payload: id})

    await _http(`admin/pages/${id}`, {method: "get"}).then((res) => {
        if (res.status === 200) {
            dispatch({type: pageConst.DETAILS_PAGE_SUCCESS, payload: res?.data?.data})
        } else {
            dispatch({type: pageConst.DETAILS_PAGE_FAIL, payload: res})

        }
    }).catch((error) => {
        getError(error);
        dispatch({type: pageConst.DETAILS_PAGE_FAIL, payload: error})
    })
}


///  Pages update
export const updatePageAction = (data, id) => async (dispatch) => {
    dispatch({type: pageConst.EDIT_PAGE_REQUEST, payload: data, id});
    await _http(`admin/pages/${id}`, {method: "post", data, id}).then((res) => {
        if (res.status === 200) {
            SUCCESS(res?.data?.message);
            dispatch({type: pageConst.EDIT_PAGE_SUCCESS, payload: res?.data});
        } else {
            ERROR(res?.response?.data?.message);
            dispatch({type: pageConst.EDIT_PAGE_FAIL, payload: res?.response?.data?.errors});
        }
    }).catch((error) => {
        getError(error);
        dispatch({type: pageConst.EDIT_PAGE_FAIL, payload: error?.response?.data?.errors});
    })
}


///  Create update
export const createPageAction = (data) => async (dispatch) => {
    dispatch({type: pageConst.CREATE_PAGE_REQUEST, payload: data})

    await _http(`admin/pages`, {method: "post", data}).then((res) => {
        if (res.status === 200) {
            SUCCESS(res?.data?.message);
            dispatch({type: pageConst.CREATE_PAGE_SUCCESS, payload: res?.data})
        } else {
            dispatch({type: pageConst.CREATE_PAGE_FAIL, payload: res})
            ERROR(res?.response?.data?.message);
        }
    }).catch((error) => {
        getError(error);
        dispatch({type: pageConst.CREATE_PAGE_FAIL, payload: error})
    })
}


///get  Pages Details
export const deletePageAction = (id) => async (dispatch) => {
    dispatch({type: pageConst.DELETE_PAGE_REQUEST, payload: id});

    await _http(`admin/pages/${id}`, {method: "delete"}).then((res) => {
        if (res.status === 200) {
            SUCCESS("عملیات مورد نظر موفقیت آمیز بود")
            dispatch({type: pageConst.DELETE_PAGE_SUCCESS, payload: res?.data})
        } else {
            dispatch({type: pageConst.DELETE_PAGE_FAIL, payload: res})

        }
    }).catch((error) => {
        getError(error);
        dispatch({type: pageConst.DELETE_PAGE_FAIL, payload: error})
    })
}


function getPageList(page) {
    return () => {
        return _http(`admin/pages?page=${page}`, {method: "get"});
    };
}


export const PageAction = {
    getPageList,
}


