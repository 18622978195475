//phone
// export const validPhone = new RegExp("^(\\+98|0)?9\\d{9}$");

export const isValidPhone = (input) => {
  if (input.charAt(0) + input.charAt(1) != "09") {
    return false;
  } else {
    return true;
  }
};

export const isValidEmail = (email) => {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(String(email).toLowerCase())) {
    return false;
  } else {
    return true;
  }
};

export const validPassword = (pass) => {
  let password =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  if (!password.test(pass)) {
    return false;
  } else {
    return true;
  }
};

export const isVerifyPass = (pass) => {
  let password = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
  if (!password.test(pass)) {
    return false;
  } else {
    return true;
  }
};
