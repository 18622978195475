import React from "react";
import {Link} from "react-router-dom";

const PageTitle = ({motherMenu, activeMenu, pageContent, prev, prevUrl, dashUrl}) => {
    let path = window.location.pathname.split("/");
    return (
        <div className="row page-titles mx-0">
            <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to={`/${dashUrl}`}>{motherMenu}</Link>
                    </li>
                    {prev && (

                        <li className="breadcrumb-item">
                            <Link to={`/${prevUrl}`}>{prev}</Link>
                        </li>
                    )}
                    <li className="breadcrumb-item active c">
                        <Link>{activeMenu}</Link>
                    </li>
                </ol>
            </div>
            <div className="col-sm-6 p-md-0">
                <div className="welcome-text">
                    {/* <h4>Hi, welcome back!</h4> */}
                    {/* <p className="mb-0">
            {pageContent ? pageContent : "Your business dashboard template"}
          </p> */}
                </div>
            </div>
        </div>
    );
};

export default PageTitle;


