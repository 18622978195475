import {reviewConst} from "../_constants/reviews.contant"


//gte all reducer


export const getAllReviewReducers = (state = {review: []}, action) => {
    switch (action.type) {
        case reviewConst.GET_ALL_REVIEW_REQUEST :
            return {loading: true}
        case reviewConst.GET_ALL_REVIEW_SUCCESS :
            return {loading: false, review: action.payload}
        case reviewConst.GET_ALL_REVIEW_FAIL :
            return {loading: false, error: action.payload}
        default:
            return state
    }
}


export const getDetailsReviewReducers = (state = {reviewDetails: []}, action) => {
    switch (action.type) {
        case reviewConst.GET_DETAILS_REVIEW_REQUEST :
            return {loading: true}
        case reviewConst.GET_DETAILS_REVIEW_SUCCESS :
            return {loading: false, reviewDetails: action.payload}
        case reviewConst.GET_DETAILS_REVIEW_FAIL :
            return {loading: false, error: action.payload}
        default:
            return state
    }
}


export const editReviewReducer = (state = {}, action) => {
    switch (action.type) {
        case reviewConst.EDIT_REVIEW_REQUEST :
            return {loading: true}
        case reviewConst.EDIT_REVIEW_SUCCESS :
            return {loading: false, success: true}
        case reviewConst.EDIT_REVIEW_FAIL :
            return {loading: false, error: action.payload}
        default :
            return state;
    }
}