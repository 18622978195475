import {userConstant, EditConstant, permissionConstant, userWallet,singleUserConstant} from "../_constants/user.constant";
import {productConstant} from "../_constants/product-constant";

///user

export const getUserReducer = (state = {userData: []}, action) => {
    switch (action.type) {
        case userConstant.GET_ALL_USERS_REQUEST :
            return {loading: true}
        case userConstant.GET_ALL_USERS_SUCCESS:
            return {loading: false, userData: action.payload}

        case userConstant.GET_ALL_USERS_FAIL :
            return {loading: false, error: action.payload}
        default :
            return state
    }
}
export const getSinglUserReducer = (state = {singlUserData: []}, action) => {
    switch (action.type) {
        case singleUserConstant.GET_USER_REQUEST :
            return {loading: true}
        case singleUserConstant.GET_USER_SUCCESS:
            return {loading: false, singlUserData: action.payload}

        case singleUserConstant.GET_USER_FAIL :
            return {loading: false, error: action.payload}
        default :
            return state
    }
}
///user
export const getPermissionReducer = (state = {PermissionData: []}, action) => {
    switch (action.type) {
        case permissionConstant.PERMISSION_USER_REQUEST :
            return {loading: true}
        case permissionConstant.PERMISSION_USER_SUCCESS:
            return {loading: false, PermissionData: action.payload}
        case permissionConstant.PERMISSION_USER_FAIL:
            return {loading: false, error: action.payload}
        default :
            return state
    }
}

///user transaction

///user
export const getTransactionReducer = (state = {transaction: []}, action) => {
    switch (action.type) {
        case userWallet.GET_USER_TRANSACTION_REQUEST :
            return {loading: true}
        case userWallet.GET_USER_TRANSACTION_SUCCESS:
            return {loading: false, transaction: action.payload}
        case userWallet.GET_USER_TRANSACTION_FAIL:
            return {loading: false, error: action.payload}
        default :
            return state
    }
}


//user Credit
///user
export const getCreditReducer = (state = {credit: []}, action) => {
    switch (action.type) {
        case userWallet.GET_USER_CREDIT_REQUEST :
            return {loading: true}
        case userWallet.GET_USER_CREDIT_SUCCESS:
            return {loading: false, credit: action.payload}
        case userWallet.GET_USER_CREDIT_FAIL:
            return {loading: false, error: action.payload}
        default :
            return state
    }
}

//details


export const usertDetailsReducer = (state = {userDetails: []}, action) => {
    switch (action.type) {
        case userConstant.GET_DETAILS_USERS_REQUEST:
            return {loading: true}
        case userConstant.GET_DETAILS_USERS_SUCCESS:
            return {loading: false, userDetails: action.payload}
        case userConstant.GET_DETAILS_USERS_FAIL :
            return {loading: false, error: action.payload}
        default :
            return state

    }
}

//create
export const createUserReducer = (state = {}, action) => {
    switch (action.type) {
        case userConstant.CREATE_USER_REQUEST :
            return {loading: true}
        case userConstant.CREATE_USER_SUCCESS :
            return {loading: false, success: true}
        case  userConstant.CREATE_USER_FAIL  :
            return {loading: false, error: action.payload}
        default :
            return state
    }
}

//Edit


export const EditUserReducer = (state = {}, action) => {
    switch (action.type) {
        case EditConstant.EDIT_USER_REQUEST :
            return {loading: true}
        case EditConstant.EDIT_USER_SUCCESS :
            return {loading: false, success: true}
        case EditConstant.EDIT_USER_FAIL :
            return {loading: false, error: action.payload}
        default :
            return state
    }
}



