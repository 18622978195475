import React from 'react';
import UserTable from "./UserTable";
import PageTitle from "../../layouts/PageTitle";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const UserList = () => {
    const users = useSelector((state) => state.users)
    const {t} = useTranslation();
    return (
        <div>
            <PageTitle activeMenu={t('USERS_LIST_KEY')} pageContent={t('USERS_LIST_KEY')}
                       motherMenu={t('DASHBOARD_KEY')}
                       prevUrl={"dashboard"}/>
            <UserTable users={users}/>
        </div>
    );
};

export default UserList;
