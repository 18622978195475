import {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {getCurrencyData} from "../../../_actions/currency-actions"
import CurrencyTable from "./CurrencyTable";
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import i18n from "i18next";

const Currency = () => {
    const getCurrency = useSelector((state) => state.getCurrency)
    const {currencyData, error, loading} = getCurrency;
    let isValid = ValidPermissions(PermissionConst.CURRENCY_LIST)
    const dispatch = useDispatch();
    useEffect(() => {
        if (isValid && i18n?.language) {
            dispatch(getCurrencyData())
        }
    }, [i18n?.language]);
    return (
        <div className={"card p-5"}>
            <CurrencyTable error={error} loading={loading} currencyData={currencyData?.data}/>
        </div>
    );
};
export default Currency;
