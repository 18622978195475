export const giftConst = {
    CREATE_GIFT_REQUEST: "CREATE_GIFT_REQUEST",
    CREATE_GIFT_SUCCESS: "CREATE_GIFT_SUCCESS",
    CREATE_GIFT_FAIL: "CREATE_GIFT_FAIL",
    //details
    DETAILS_GIFT_REQUEST: "DETAILS_GIFT_REQUEST",
    DETAILS_GIFT_SUCCESS: "DETAILS_GIFT_SUCCESS",
    DETAILS_GIFT_FAIL: "DETAILS_GIFT_FAIL",

    //update
    UPDATE_GIFT_REQUEST: "UPDATE_GIFT_REQUEST",
    UPDATE_GIFT_SUCCESS: "UPDATE_GIFT_SUCCESS",
    UPDATE_GIFT_FAIL: "UPDATE_GIFT_FAIL",

    CREATE_EXCEL_REQUEST: "CREATE_EXCEL_REQUEST",
    CREATE_EXCEL_SUCCESS: "CREATE_EXCEL_SUCCESS",
    CREATE_EXCEL_FAIL: "CREATE_EXCEL_FAIL",

    //delete
    DELETE_GIFT: "DELETE_GIFT",
}