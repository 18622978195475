import {categoryConstant, categoriesDetail,categoriesEdit} from "../_constants/category.constant"

///new category

export const newCategoryReducer = (state = {}, action) => {
    switch (action.type) {
        case categoryConstant.NEW_CATEGORY_REQUEST :
            return {loading: true}
        case categoryConstant.NEW_CATEGORY_SUCCESS :
            return {loading: false, success: true}
        case categoryConstant.NEW_CATEGORY_FAIL :
            return {loading: false, error: action.payload}
        default :
            return state
    }
}

///new Edit

export const editCategoryReducer = (state = {}, action) => {
    switch (action.type) {
        case categoriesEdit.EDIT_CATEGORY_REQUEST :
            return {loading: true}
        case categoriesEdit.EDIT_CATEGORY_SUCCESS:
            return {loading: false, success: true}
        case categoriesEdit.EDIT_CATEGORY_FAIL :
            return {loading: false, error: action.payload}
        default :
            return state
    }
}

//get all category

export const getAllCategoryReducer = (state = {categories: []}, action) => {
    switch (action.type) {
        case categoryConstant.GET_ALL_CATEGORY_REQUEST  :
            return {loading: true}

        case categoryConstant.GET_ALL_CATEGORY_SUCCESS :
            return {loading: false, categories: action.payload}

        case categoryConstant.GET_ALL_CATEGORY_FAIL:
            return {loading: false, error: action.payload}
        default :
            return state
    }
}

///get All Categories
export const getAllCategoryDetails = (state = {loading: true, category: {}}, action) => {
    switch (action.type) {
        case  categoriesDetail.GET_DETAILS_CATEGORY_REQUEST :
            return {loading: true}

        case categoriesDetail.GET_DETAILS_CATEGORY_SUCCESS :
            return {loading: false, category: action.payload}

        case categoriesDetail.GET_DETAILS_CATEGORY_FAIL :
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

