import {useEffect} from 'react';
import PageTitle from "../../layouts/PageTitle";
import {useSelector, useDispatch} from "react-redux"
import ProductTable from "./ProductTable";
import {getAllProducts} from "../../../_actions/products-actions"
import ValidPermissions from "../../../_helper/ValidPermissions";
import {PermissionConst} from "../../../_constants/PermissionConst";
import {useTranslation} from "react-i18next";

const ProductList = () => {
    const getProducts = useSelector((state) => state.getProducts)
    const {products, error, loading} = getProducts;
    const dispatch = useDispatch();
    let isValid = ValidPermissions(PermissionConst.PRODUCT_LIST)
    useEffect(() => {
        if (isValid) {
            dispatch(getAllProducts())
        }
    }, []);

    const {t} = useTranslation();
    return (
        <>
            <PageTitle activeMenu={t('ALL_PRODUCT_KEY')} pageContent={t('ALL_PRODUCT_KEY')}
                       motherMenu={t('DASHBOARD_KEY')}
                       prevUrl={"dashboard"}/>
            <div className={'card'}>
                <ProductTable products={products} error={error} loading={loading}/>
            </div>
        </>
    );
};
export default ProductList;
